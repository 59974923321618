// components/Theme.jsx
import { ThemeProvider, createGlobalStyle } from "styled-components";
import { useThemeContext } from "../context/Theme";
import React from "react";

// Add global styles to fix mobile issues
const GlobalStyle = createGlobalStyle`
  /* Add global style to fix car search on mobile */
  @media (max-width: 480px) {
    /* Prevent unwanted scroll */
    html.noscroll,
    body.noscroll {
      overflow: hidden !important;
      width: 100% !important;
      height: 100% !important;
    }
    
    body.car-search-open {
      overflow: hidden !important;
      width: 100%;
      height: 100%;
      touch-action: none; /* Prevent iOS Safari bounce effect */
    }
    
    /* Transparency effect for chat on mobile */
    .ChatAndSearchContainer.page-scrolling {
      opacity: 0.3 !important;
      transition: opacity 0.3s ease !important;
    }
    
    /* Clear opacity when interacting with chat */
    .ChatAndSearchContainer:active,
    .ChatAndSearchContainer:focus-within {
      opacity: 1 !important;
    }
    
    /* iOS Safari specific fixes */
    @supports (-webkit-touch-callout: none) {
      html.ios-safari {
        height: -webkit-fill-available !important;
      }
      
      body.ios-safari {
        height: -webkit-fill-available !important;
        width: 100% !important;
      }
      
      body.car-search-open.ios-safari {
        min-height: -webkit-fill-available !important;
        overflow: hidden !important;
      }
      
      /* Full height overlay that really works on iOS */
      .car-search-overlay {
        height: 100vh !important; /* Fallback */
        height: -webkit-fill-available !important;
        position: fixed !important;
        top: 0 !important;
        left: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        width: 100vw !important;
        margin: 0 !important;
        padding: 0 !important;
        z-index: 9999 !important;
        overflow: hidden !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
      }
      
      /* Container that properly fills screen on iOS */
      .car-search-container {
        position: fixed !important;
        top: 0 !important;
        left: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        width: 100% !important;
        max-width: 100% !important;
        height: 100% !important; /* Fallback */
        height: -webkit-fill-available !important;
        max-height: -webkit-fill-available !important;
        margin: 0 !important;
        border-radius: 0 !important;
        box-sizing: border-box !important;
        z-index: 10000 !important;
        display: flex !important;
        flex-direction: column !important;
        overflow: hidden !important;
      }
      
      /* Form that scrolls properly */
      .car-search-form {
        flex: 1 !important;
        display: flex !important;
        flex-direction: column !important;
        max-height: none !important;
        overflow-y: auto !important;
        -webkit-overflow-scrolling: touch !important;
        margin: 0 !important;
        z-index: 10 !important;
      }
      
      /* Content that doesn't get cut off */
      .car-search-content {
        flex: 1 !important;
        overflow-y: auto !important;
        -webkit-overflow-scrolling: touch !important;
        padding-bottom: 90px !important; /* Space for buttons */
      }
      
      /* Fixed buttons that stay visible */
      .car-search-actions {
        position: fixed !important;
        bottom: 0 !important;
        left: 0 !important;
        right: 0 !important;
        z-index: 2002 !important;
        padding: 16px !important;
        padding-bottom: calc(16px + env(safe-area-inset-bottom, 0)) !important; /* iPhone X+ support */
        background-color: white !important;
        box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.15) !important;
      }
      
      /* When keyboard is open, handle special positioning */
      .car-search-container.keyboard-open .car-search-content {
        padding-bottom: 160px !important; /* Extra padding for keyboard case */
      }
      
      /* Special handling for buttons with keyboard open */
      .car-search-container.keyboard-open .car-search-actions {
        position: sticky !important;
        bottom: 0 !important;
        z-index: 2002 !important;
      }
    }
  }
`;

export const StyledComponentsThemWrapper = ({ children }) => {
  const { theme } = useThemeContext();
  
  // Add class to html element for better iOS Safari detection
  React.useEffect(() => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent.toLowerCase());
    
    if (isIOS && isSafari) {
      document.documentElement.classList.add('ios-safari');
      document.body.classList.add('ios-safari');
      
      return () => {
        document.documentElement.classList.remove('ios-safari');
        document.body.classList.remove('ios-safari');
      };
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  );
};
