import { motion } from "framer-motion";
import styled from "styled-components";
import { StyledComponentsThemWrapper } from "../../StyledComponentsThemWrapper";

const DotContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  padding-bottom: 8px;
`;

const Dot = styled(motion.button)`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 6px;
  cursor: pointer;
  border: ${(props) =>
    props.isActive ? `2px solid ${props.theme.palette.v2.primary}` : "#dedede"};
  outline: none;
  padding: 0;
  background-color: ${(props) =>
    props.isActive ? props.theme.palette.v2.primary : "#dedede"};
  box-shadow: ${(props) =>
    props.isActive ? "0 2px 4px rgba(0, 0, 0, 0.2)" : "none"};
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  &:focus-visible {
    box-shadow: 0 0 0 2px ${(props) => props.theme.palette.primary.main};
  }
`;

const VisuallyHidden = styled.span`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
`;

// Updated props to be more explicit and simpler
const NavigationDots = ({ count, activeIndex, onDotClick }) => {
  return (
    <StyledComponentsThemWrapper>
      <DotContainer>
        {[...Array(count)].map((_, index) => (
          <Dot
            key={index}
            isActive={activeIndex === index}
            onClick={() => onDotClick(index * 1)} // Multiply by 1 to maintain the same behavior
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.9 }}
            aria-label={`Go to slide ${index + 1}`}
          >
            <VisuallyHidden>
              {activeIndex === index
                ? `Current slide, ${index + 1} of ${count}`
                : `Go to slide ${index + 1}`}
            </VisuallyHidden>
          </Dot>
        ))}
      </DotContainer>
    </StyledComponentsThemWrapper>
  );
};

export default NavigationDots;
