import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Tag, X, ChevronRight } from 'lucide-react';
import './MobileCarSearch.css'; // Reusing the same CSS

// Header component
const Header = ({ currentStep, totalSteps }) => (
  <div className="car-search-header" style={{ padding: '8px 15px', marginBottom: '5px' }}>
    <div className="car-search-progress" style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      {[...Array(totalSteps)].map((_, index) => (
        <div 
          key={index} 
          className={`progress-dot ${index === currentStep ? 'active' : index < currentStep ? 'completed' : ''}`}
          style={{ width: '8px', height: '8px', margin: '0 3px' }}
        />
      ))}
    </div>
  </div>
);

// Close button component
const CloseButton = ({ onClose }) => (
  <button className="car-search-close" onClick={onClose} aria-label="Chiudi ricerca">
    <X size={18} />
  </button>
);

// Modal container component
const ModalContainer = ({ children }) => {
  // Add class based on viewport height for better positioning
  const viewportClass = window.innerHeight < 800 ? 'short-viewport' : 'normal-viewport';
  
  return ReactDOM.createPortal(
    <div 
      className={`car-search-overlay ${viewportClass}`}
      aria-modal="true"
      role="dialog"
      aria-labelledby="promotion-search-title"
    >
      {children}
    </div>,
    document.body
  );
};

// Welcome message component
const WelcomeMessage = () => (
  <div className="car-search-welcome" style={{ margin: '5px 0', padding: '5px 15px' }}>
    <span className="welcome-icon">🏷️</span>
    <h2 style={{ fontSize: '16px', marginBottom: '3px' }}>Scopri le nostre offerte!</h2>
    <p style={{ fontSize: '12px', lineHeight: '1.2', margin: 0 }}>Rispondi a poche domande per trovare la promozione più adatta alle tue esigenze</p>
  </div>
);

// Utility functions for browsers
const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf('safari') > -1 && ua.indexOf('chrome') === -1;
};

const isIOS = () => {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
};

// Main component
const PromotionsSearch = ({ onClose, onSubmit, usePortal = true, toggleChat }) => {
  // State variables
  const [currentStep, setCurrentStep] = useState(0);
  const [vehicleType, setVehicleType] = useState('');
  const [promotionType, setPromotionType] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [keyboardOpen, setKeyboardOpen] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const currentInputRef = useRef(null);
  const containerRef = useRef(null);
  const formRef = useRef(null);
  const [isSafariBrowser, setIsSafariBrowser] = useState(false);
  const [isIOSDevice, setIsIOSDevice] = useState(false);
  const [keyboardHeight, setKeyboardHeight] = useState(0);
  
  // Set up event listeners and initialize
  useEffect(() => {
    document.body.classList.add('car-search-open');
    setIsSafariBrowser(isSafari());
    setIsIOSDevice(isIOS());
    
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    window.addEventListener('resize', handleResize);
    
    const setViewportHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    
    setViewportHeight();
    window.addEventListener('resize', setViewportHeight);
    
    return () => {
      document.body.classList.remove('car-search-open');
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('resize', setViewportHeight);
    };
  }, []);
  
  // Handle iOS Safari specific adjustments
  useEffect(() => {
    if (isIOSDevice && isSafariBrowser) {
      document.body.classList.add('ios-safari');
      
      const metaViewport = document.querySelector('meta[name="viewport"]');
      if (metaViewport) {
        metaViewport.setAttribute('content', 
          'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no, viewport-fit=cover');
      }
      
      const visualViewport = window.visualViewport;
      if (visualViewport) {
        const handleViewportChange = () => {
          const windowHeight = window.innerHeight;
          const viewportHeight = visualViewport.height;
          const isKeyboardVisible = viewportHeight < windowHeight * 0.75;
          
          setKeyboardOpen(isKeyboardVisible);
          
          if (isKeyboardVisible) {
            const estimatedKeyboardHeight = windowHeight - viewportHeight;
            setKeyboardHeight(estimatedKeyboardHeight);
            document.documentElement.style.setProperty('--keyboard-height', `${estimatedKeyboardHeight}px`);
            
            setTimeout(() => {
              if (currentInputRef.current) {
                currentInputRef.current.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center'
                });
              }
            }, 300);
          } else {
            setKeyboardHeight(0);
            document.documentElement.style.setProperty('--keyboard-height', '0px');
          }
        };
        
        visualViewport.addEventListener('resize', handleViewportChange);
        visualViewport.addEventListener('scroll', handleViewportChange);
        
        return () => {
          document.body.classList.remove('ios-safari');
          visualViewport.removeEventListener('resize', handleViewportChange);
          visualViewport.removeEventListener('scroll', handleViewportChange);
          
          if (metaViewport) {
            metaViewport.setAttribute('content', 
              'width=device-width, initial-scale=1, viewport-fit=cover');
          }
        };
      }
    }
  }, [isIOSDevice, isSafariBrowser]);
  
  // Input focus handler
  const handleInputFocus = () => {
    setKeyboardOpen(true);
    
    if (isIOSDevice && isSafariBrowser) {
      setTimeout(() => {
        containerRef.current?.classList.add('ios-keyboard-open');
        
        const actionsElement = containerRef.current?.querySelector('.car-search-actions');
        if (actionsElement) {
          actionsElement.classList.add('keyboard-repositioned');
        }
        
        if (currentInputRef.current) {
          currentInputRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        }
      }, 150);
    } else if (isMobile) {
      setTimeout(() => {
        const actionsElement = containerRef.current?.querySelector('.car-search-actions');
        
        if (actionsElement) {
          actionsElement.classList.add('keyboard-repositioned');
          
          if (currentInputRef.current) {
            currentInputRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            });
            
            if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
              window.scrollTo(0, window.scrollY + 100);
            }
          }
        }
      }, 300);
    }
  };
  
  // Input blur handler
  const handleInputBlur = () => {
    if (isIOSDevice && isSafariBrowser) {
      setTimeout(() => {
        setKeyboardOpen(false);
        
        containerRef.current?.classList.remove('ios-keyboard-open');
        
        const actionsElement = containerRef.current?.querySelector('.car-search-actions');
        if (actionsElement) {
          actionsElement.classList.remove('keyboard-repositioned');
        }
        
        setKeyboardHeight(0);
        document.documentElement.style.setProperty('--keyboard-height', '0px');
      }, 300);
    } else if (isMobile) {
      setTimeout(() => {
        setKeyboardOpen(false);
        
        const actionsElement = containerRef.current?.querySelector('.car-search-actions');
        if (actionsElement && actionsElement.classList.contains('keyboard-repositioned')) {
          actionsElement.style.position = 'fixed';
          actionsElement.style.bottom = '0';
          actionsElement.style.top = 'auto';
          actionsElement.classList.remove('keyboard-repositioned');
        }
      }, 150);
    }
  };
  
  // Key press handler
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      
      if (isCurrentStepValid()) {
        if (isIOSDevice && isSafariBrowser && keyboardOpen) {
          currentInputRef.current?.blur();
          setTimeout(() => handleNext(), 300);
        } 
        else if (isMobile && keyboardOpen && currentInputRef.current) {
          currentInputRef.current.blur();
          setTimeout(() => handleNext(), 100);
        } else {
          handleNext();
        }
      }
    } else if (e.key === 'Escape') {
      onClose();
    }
  };
  
  // Auto-advance when an option is selected
  const handleOptionSelect = (setter, value) => {
    setter(value);
    
    // Special handling for "Tutte le offerte"
    if (value === 'Tutte le offerte') {
      // Set a default promotion type
      setPromotionType('Tutte');
      
      // Jump directly to the final step
      setTimeout(() => {
        setCurrentStep(steps.length - 1);
      }, 300);
    } else {
      // Normal auto-advance for other options
      setTimeout(() => {
        handleNext();
      }, 300);
    }
  };
  
  // Navigation and validation
  const handleNext = () => {
    if (!isCurrentStepValid()) {
      return;
    }
    
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      handleSubmit();
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    } else {
      onClose();
    }
  };

  const handleSubmit = () => {
    onSubmit({
      vehicleType,
      promotionType,
      additionalInfo
    });
    
    onClose();
  };

  const isCurrentStepValid = () => {
    switch (currentStep) {
      case 0:
        return vehicleType !== '';
      case 1:
        return promotionType !== '';
      case 2:
        return true; // Optional field
      default:
        return false;
    }
  };
  
  // Direct chat handler
  const handleOpenDirectChat = (e) => {
    e.preventDefault();
    onClose();
    
    setTimeout(() => {
      if (typeof toggleChat === 'function') {
        toggleChat();
      }
    }, 100);
  };
  
  // Define steps
  const steps = [
    {
      title: "Quale veicolo ti interessa?",
      description: "Seleziona il tipo di veicolo per cui cerchi promozioni.",
      input: (
        <div className="search-input-container">
          <div className="fuel-type-grid" role="radiogroup" aria-label="Tipo di veicolo" style={{ 
            gridGap: '6px',
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gridTemplateRows: 'auto auto'
          }}>
            <label 
              className={`fuel-type-option ${vehicleType === 'Auto' ? 'selected' : ''}`}
              onClick={() => handleOptionSelect(setVehicleType, 'Auto')}
              style={{ padding: '6px 8px', minHeight: 'auto', backgroundColor: vehicleType === 'Auto' ? '#e6f7ff' : null, gridColumn: '1 / 2', gridRow: '1 / 2' }}
            >
              <input
                type="radio"
                name="vehicleType"
                value="Auto"
                checked={vehicleType === 'Auto'}
                onChange={() => {}}
                onFocus={handleInputFocus}
                ref={vehicleType === '' ? currentInputRef : null}
              />
              <div className="fuel-type-content" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '8px' }}>
                <span className="fuel-type-icon" style={{ fontSize: '18px', color: '#0066cc' }}>🚗</span>
                <span className="fuel-type-label" style={{ fontSize: '13px', fontWeight: 'bold' }}>Auto</span>
              </div>
            </label>
            
            <label 
              className={`fuel-type-option ${vehicleType === 'Veicoli commerciali' ? 'selected' : ''}`}
              onClick={() => handleOptionSelect(setVehicleType, 'Veicoli commerciali')}
              style={{ padding: '6px 8px', minHeight: 'auto', backgroundColor: vehicleType === 'Veicoli commerciali' ? '#fff7e6' : null, gridColumn: '2 / 3', gridRow: '1 / 2' }}
            >
              <input
                type="radio"
                name="vehicleType"
                value="Veicoli commerciali"
                checked={vehicleType === 'Veicoli commerciali'}
                onChange={() => {}}
                onFocus={handleInputFocus}
              />
              <div className="fuel-type-content" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '8px' }}>
                <span className="fuel-type-icon" style={{ fontSize: '18px', color: '#fa8c16' }}>🚚</span>
                <span className="fuel-type-label" style={{ fontSize: '13px', fontWeight: 'bold' }}>Veicoli commerciali</span>
              </div>
            </label>
            
            <label 
              className={`fuel-type-option ${vehicleType === 'Tutte le offerte' ? 'selected' : ''}`}
              onClick={() => handleOptionSelect(setVehicleType, 'Tutte le offerte')}
              style={{ 
                padding: '6px 8px', 
                minHeight: 'auto', 
                backgroundColor: vehicleType === 'Tutte le offerte' ? '#f5f5f5' : null, 
                gridColumn: '1 / 2', 
                gridRow: '2 / 3',
                display: 'flex',  /* Ensure this is displayed */
                alignItems: 'center',
                justifyContent: 'flex-start'
              }}
            >
              <input
                type="radio"
                name="vehicleType"
                value="Tutte le offerte"
                checked={vehicleType === 'Tutte le offerte'}
                onChange={() => {}}
                onFocus={handleInputFocus}
              />
              <div className="fuel-type-content" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '8px' }}>
                <span className="fuel-type-icon" style={{ fontSize: '18px', color: '#595959' }}>🏷️</span>
                <span className="fuel-type-label" style={{ fontSize: '13px', fontWeight: 'bold' }}>Tutte le offerte</span>
              </div>
            </label>
            
            <label 
              className={`fuel-type-option ${vehicleType === 'Garanzie' ? 'selected' : ''}`}
              onClick={() => handleOptionSelect(setVehicleType, 'Garanzie')}
              style={{ padding: '6px 8px', minHeight: 'auto', backgroundColor: vehicleType === 'Garanzie' ? '#f9f0ff' : null, gridColumn: '2 / 3', gridRow: '2 / 3' }}
            >
              <input
                type="radio"
                name="vehicleType"
                value="Garanzie"
                checked={vehicleType === 'Garanzie'}
                onChange={() => {}}
                onFocus={handleInputFocus}
              />
              <div className="fuel-type-content" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '8px' }}>
                <span className="fuel-type-icon" style={{ fontSize: '18px', color: '#722ed1' }}>🛡️</span>
                <span className="fuel-type-label" style={{ fontSize: '13px', fontWeight: 'bold' }}>Garanzie</span>
              </div>
            </label>
          </div>
        </div>
      )
    },
    {
      title: () => {
        switch(vehicleType) {
          case 'Auto':
            return "Seleziona il tipo di auto";
          case 'Veicoli commerciali':
            return "Seleziona il tipo di veicolo commerciale";
          case 'Tutte le offerte':
            return "Informazioni aggiuntive";
          case 'Garanzie':
            return "Seleziona il tipo di garanzia";
          default:
            return "Che tipo di promozione cerchi?";
        }
      },
      description: () => {
        switch(vehicleType) {
          case 'Auto':
            return "Scegli la categoria di auto che ti interessa.";
          case 'Veicoli commerciali':
            return "Scegli la tipologia di veicolo commerciale.";
          case 'Tutte le offerte':
            return "Puoi aggiungere dettagli specifici sulla promozione che cerchi.";
          case 'Garanzie':
            return "Scegli il tipo di garanzia che stai cercando.";
          default:
            return "Seleziona il tipo di promozione che ti interessa maggiormente.";
        }
      },
      input: (
        <div className="search-input-container">
          {/* Conditionally render inputs based on vehicle type */}
          {vehicleType !== 'Tutte le offerte' && (
            <div className="fuel-type-grid" role="radiogroup" aria-label="Tipo di promozione" style={{ 
              gridGap: '6px',
              display: 'grid',
              ...(vehicleType === 'Auto' || vehicleType === 'Garanzie' || vehicleType === 'Veicoli commerciali' ? 
                { gridTemplateColumns: 'repeat(2, 1fr)', gridTemplateRows: 'auto auto' } : 
                { gridTemplateColumns: isMobile ? 'repeat(1, 1fr)' : 'repeat(3, 1fr)', maxWidth: '550px', margin: '0 auto' })
            }}>
              {/* All option groups here */}
              {vehicleType === 'Auto' && (
                <>
                  <label 
                    className={`fuel-type-option ${promotionType === 'City Car' ? 'selected' : ''}`}
                    onClick={() => handleOptionSelect(setPromotionType, 'City Car')}
                    style={{ 
                      padding: '12px',
                      minHeight: '80px',
                      width: '150px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: promotionType === 'City Car' ? '#e6f7ff' : null,
                      margin: '8px auto',
                      border: '1px solid #eee',
                      borderRadius: '8px',
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
                      gridColumn: '1 / 2', gridRow: '1 / 2'
                    }}
                  >
                    <input
                      type="radio"
                      name="promotionType"
                      value="City Car"
                      checked={promotionType === 'City Car'}
                      onChange={() => {}}
                      onFocus={handleInputFocus}
                      ref={promotionType === '' ? currentInputRef : null}
                    />
                    <div className="fuel-type-content" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px', flexDirection: 'column', padding: '4px' }}>
                      <span className="fuel-type-icon" style={{ fontSize: '24px', color: '#0066cc' }}>🚘</span>
                      <span className="fuel-type-label" style={{ fontSize: '14px', fontWeight: 'bold', textAlign: 'center' }}>City Car</span>
                    </div>
                  </label>
                  <label 
                    className={`fuel-type-option ${promotionType === 'Berlina' ? 'selected' : ''}`}
                    onClick={() => handleOptionSelect(setPromotionType, 'Berlina')}
                    style={{ 
                      padding: '12px',
                      minHeight: '80px',
                      width: '150px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: promotionType === 'Berlina' ? '#e6f7ff' : null,
                      margin: '8px auto',
                      border: '1px solid #eee',
                      borderRadius: '8px',
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
                      gridColumn: '2 / 3', gridRow: '1 / 2'
                    }}
                  >
                    <input
                      type="radio"
                      name="promotionType"
                      value="Berlina"
                      checked={promotionType === 'Berlina'}
                      onChange={() => {}}
                      onFocus={handleInputFocus}
                    />
                    <div className="fuel-type-content" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px', flexDirection: 'column', padding: '4px' }}>
                      <span className="fuel-type-icon" style={{ fontSize: '24px', color: '#0066cc' }}>🚗</span>
                      <span className="fuel-type-label" style={{ fontSize: '14px', fontWeight: 'bold', textAlign: 'center' }}>Berlina</span>
                    </div>
                  </label>
                  <label 
                    className={`fuel-type-option ${promotionType === 'SUV/Crossover' ? 'selected' : ''}`}
                    onClick={() => handleOptionSelect(setPromotionType, 'SUV/Crossover')}
                    style={{ 
                      padding: '12px',
                      minHeight: '80px',
                      width: '150px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: promotionType === 'SUV/Crossover' ? '#e6f7ff' : null,
                      margin: '8px auto',
                      border: '1px solid #eee',
                      borderRadius: '8px',
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
                      gridColumn: '1 / 2', gridRow: '2 / 3'
                    }}
                  >
                    <input
                      type="radio"
                      name="promotionType"
                      value="SUV/Crossover"
                      checked={promotionType === 'SUV/Crossover'}
                      onChange={() => {}}
                      onFocus={handleInputFocus}
                    />
                    <div className="fuel-type-content" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px', flexDirection: 'column', padding: '4px' }}>
                      <span className="fuel-type-icon" style={{ fontSize: '24px', color: '#0066cc' }}>🚙</span>
                      <span className="fuel-type-label" style={{ fontSize: '14px', fontWeight: 'bold', textAlign: 'center' }}>SUV/Crossover</span>
                    </div>
                  </label>
                  <label 
                    className={`fuel-type-option ${promotionType === 'Sportiva' ? 'selected' : ''}`}
                    onClick={() => handleOptionSelect(setPromotionType, 'Sportiva')}
                    style={{ 
                      padding: '12px',
                      minHeight: '80px',
                      width: '150px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: promotionType === 'Sportiva' ? '#e6f7ff' : null,
                      margin: '8px auto',
                      border: '1px solid #eee',
                      borderRadius: '8px',
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
                      gridColumn: '2 / 3', gridRow: '2 / 3'
                    }}
                  >
                    <input
                      type="radio"
                      name="promotionType"
                      value="Sportiva"
                      checked={promotionType === 'Sportiva'}
                      onChange={() => {}}
                      onFocus={handleInputFocus}
                    />
                    <div className="fuel-type-content" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px', flexDirection: 'column', padding: '4px' }}>
                      <span className="fuel-type-icon" style={{ fontSize: '24px', color: '#0066cc' }}>🏎️</span>
                      <span className="fuel-type-label" style={{ fontSize: '14px', fontWeight: 'bold', textAlign: 'center' }}>Sportiva</span>
                    </div>
                  </label>
                </>
              )}
              
              {vehicleType === 'Veicoli commerciali' && (
                <>
                  <label 
                    className={`fuel-type-option ${promotionType === 'Veicoli aziendali' ? 'selected' : ''}`}
                    onClick={() => handleOptionSelect(setPromotionType, 'Veicoli aziendali')}
                    style={{ 
                      padding: '12px',
                      minHeight: '80px',
                      width: '150px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: promotionType === 'Veicoli aziendali' ? '#fff7e6' : null,
                      margin: '8px auto',
                      border: '1px solid #eee',
                      borderRadius: '8px',
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
                      gridColumn: '1 / 2', 
                      gridRow: '1 / 2'
                    }}
                  >
                    <input
                      type="radio"
                      name="promotionType"
                      value="Veicoli aziendali"
                      checked={promotionType === 'Veicoli aziendali'}
                      onChange={() => {}}
                      onFocus={handleInputFocus}
                      ref={promotionType === '' ? currentInputRef : null}
                    />
                    <div className="fuel-type-content" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px', flexDirection: 'column', padding: '4px' }}>
                      <span className="fuel-type-icon" style={{ fontSize: '24px', color: '#fa8c16' }}>🚙</span>
                      <span className="fuel-type-label" style={{ fontSize: '14px', fontWeight: 'bold', textAlign: 'center' }}>Veicoli aziendali</span>
                    </div>
                  </label>
                  
                  <label 
                    className={`fuel-type-option ${promotionType === 'Furgoni' ? 'selected' : ''}`}
                    onClick={() => handleOptionSelect(setPromotionType, 'Furgoni')}
                    style={{ 
                      padding: '12px',
                      minHeight: '80px',
                      width: '150px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: promotionType === 'Furgoni' ? '#fff7e6' : null,
                      margin: '8px auto',
                      border: '1px solid #eee',
                      borderRadius: '8px',
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
                      gridColumn: '2 / 3', 
                      gridRow: '1 / 2'
                    }}
                  >
                    <input
                      type="radio"
                      name="promotionType"
                      value="Furgoni"
                      checked={promotionType === 'Furgoni'}
                      onChange={() => {}}
                      onFocus={handleInputFocus}
                    />
                    <div className="fuel-type-content" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px', flexDirection: 'column', padding: '4px' }}>
                      <span className="fuel-type-icon" style={{ fontSize: '24px', color: '#fa8c16' }}>🚐</span>
                      <span className="fuel-type-label" style={{ fontSize: '14px', fontWeight: 'bold', textAlign: 'center' }}>Furgoni</span>
                    </div>
                  </label>
                  
                  <label 
                    className={`fuel-type-option ${promotionType === 'Pick-up' ? 'selected' : ''}`}
                    onClick={() => handleOptionSelect(setPromotionType, 'Pick-up')}
                    style={{ 
                      padding: '12px',
                      minHeight: '80px',
                      width: '150px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: promotionType === 'Pick-up' ? '#fff7e6' : null,
                      margin: '8px auto',
                      border: '1px solid #eee',
                      borderRadius: '8px',
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
                      gridColumn: '1 / 2', 
                      gridRow: '2 / 3'
                    }}
                  >
                    <input
                      type="radio"
                      name="promotionType"
                      value="Pick-up"
                      checked={promotionType === 'Pick-up'}
                      onChange={() => {}}
                      onFocus={handleInputFocus}
                    />
                    <div className="fuel-type-content" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px', flexDirection: 'column', padding: '4px' }}>
                      <span className="fuel-type-icon" style={{ fontSize: '24px', color: '#fa8c16' }}>🛻</span>
                      <span className="fuel-type-label" style={{ fontSize: '14px', fontWeight: 'bold', textAlign: 'center' }}>Pick-up</span>
                    </div>
                  </label>
                  
                  <label 
                    className={`fuel-type-option ${promotionType === 'Trasporto merci' ? 'selected' : ''}`}
                    onClick={() => handleOptionSelect(setPromotionType, 'Trasporto merci')}
                    style={{ 
                      padding: '12px',
                      minHeight: '80px',
                      width: '150px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: promotionType === 'Trasporto merci' ? '#fff7e6' : null,
                      margin: '8px auto',
                      border: '1px solid #eee',
                      borderRadius: '8px',
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
                      gridColumn: '2 / 3', 
                      gridRow: '2 / 3'
                    }}
                  >
                    <input
                      type="radio"
                      name="promotionType"
                      value="Trasporto merci"
                      checked={promotionType === 'Trasporto merci'}
                      onChange={() => {}}
                      onFocus={handleInputFocus}
                    />
                    <div className="fuel-type-content" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px', flexDirection: 'column', padding: '4px' }}>
                      <span className="fuel-type-icon" style={{ fontSize: '24px', color: '#fa8c16' }}>🚚</span>
                      <span className="fuel-type-label" style={{ fontSize: '14px', fontWeight: 'bold', textAlign: 'center' }}>Trasporto merci</span>
                    </div>
                  </label>
                </>
              )}
              
              {vehicleType === 'Garanzie' && (
                <>
                  <label 
                    className={`fuel-type-option ${promotionType === 'Estensione' ? 'selected' : ''}`}
                    onClick={() => handleOptionSelect(setPromotionType, 'Estensione')}
                    style={{ 
                      padding: '12px',
                      minHeight: '80px',
                      width: '150px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: promotionType === 'Estensione' ? '#f9f0ff' : null,
                      margin: '8px auto',
                      border: '1px solid #eee',
                      borderRadius: '8px',
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
                      gridColumn: '1 / 2', gridRow: '1 / 2'
                    }}
                  >
                    <input
                      type="radio"
                      name="promotionType"
                      value="Estensione"
                      checked={promotionType === 'Estensione'}
                      onChange={() => {}}
                      onFocus={handleInputFocus}
                      ref={promotionType === '' ? currentInputRef : null}
                    />
                    <div className="fuel-type-content" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px', flexDirection: 'column', padding: '4px' }}>
                      <span className="fuel-type-icon" style={{ fontSize: '24px', color: '#722ed1' }}>📝</span>
                      <span className="fuel-type-label" style={{ fontSize: '14px', fontWeight: 'bold', textAlign: 'center' }}>Estensione</span>
                    </div>
                  </label>
                  <label 
                    className={`fuel-type-option ${promotionType === 'Assistenza' ? 'selected' : ''}`}
                    onClick={() => handleOptionSelect(setPromotionType, 'Assistenza')}
                    style={{ 
                      padding: '12px',
                      minHeight: '80px',
                      width: '150px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: promotionType === 'Assistenza' ? '#f9f0ff' : null,
                      margin: '8px auto',
                      border: '1px solid #eee',
                      borderRadius: '8px',
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
                      gridColumn: '2 / 3', gridRow: '1 / 2'
                    }}
                  >
                    <input
                      type="radio"
                      name="promotionType"
                      value="Assistenza"
                      checked={promotionType === 'Assistenza'}
                      onChange={() => {}}
                      onFocus={handleInputFocus}
                    />
                    <div className="fuel-type-content" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px', flexDirection: 'column', padding: '4px' }}>
                      <span className="fuel-type-icon" style={{ fontSize: '24px', color: '#722ed1' }}>🚨</span>
                      <span className="fuel-type-label" style={{ fontSize: '14px', fontWeight: 'bold', textAlign: 'center' }}>Assistenza</span>
                    </div>
                  </label>
                  <label 
                    className={`fuel-type-option ${promotionType === 'Riparazione' ? 'selected' : ''}`}
                    onClick={() => handleOptionSelect(setPromotionType, 'Riparazione')}
                    style={{ 
                      padding: '12px',
                      minHeight: '80px',
                      width: '150px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: promotionType === 'Riparazione' ? '#f9f0ff' : null,
                      margin: '8px auto',
                      border: '1px solid #eee',
                      borderRadius: '8px',
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
                      gridColumn: '1 / 2', gridRow: '2 / 3'
                    }}
                  >
                    <input
                      type="radio"
                      name="promotionType"
                      value="Riparazione"
                      checked={promotionType === 'Riparazione'}
                      onChange={() => {}}
                      onFocus={handleInputFocus}
                    />
                    <div className="fuel-type-content" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px', flexDirection: 'column', padding: '4px' }}>
                      <span className="fuel-type-icon" style={{ fontSize: '24px', color: '#722ed1' }}>🔧</span>
                      <span className="fuel-type-label" style={{ fontSize: '14px', fontWeight: 'bold', textAlign: 'center' }}>Riparazione</span>
                    </div>
                  </label>
                  <label 
                    className={`fuel-type-option ${promotionType === 'Premium' ? 'selected' : ''}`}
                    onClick={() => handleOptionSelect(setPromotionType, 'Premium')}
                    style={{ 
                      padding: '12px',
                      minHeight: '80px',
                      width: '150px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: promotionType === 'Premium' ? '#f9f0ff' : null,
                      margin: '8px auto',
                      border: '1px solid #eee',
                      borderRadius: '8px',
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
                      gridColumn: '2 / 3', gridRow: '2 / 3'
                    }}
                  >
                    <input
                      type="radio"
                      name="promotionType"
                      value="Premium"
                      checked={promotionType === 'Premium'}
                      onChange={() => {}}
                      onFocus={handleInputFocus}
                    />
                    <div className="fuel-type-content" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px', flexDirection: 'column', padding: '4px' }}>
                      <span className="fuel-type-icon" style={{ fontSize: '24px', color: '#722ed1' }}>⭐</span>
                      <span className="fuel-type-label" style={{ fontSize: '14px', fontWeight: 'bold', textAlign: 'center' }}>Premium</span>
                    </div>
                  </label>
                </>
              )}
            </div>
          )}
          
          {/* If "Tutte le offerte" is selected, we'll show the final step textarea immediately */}
          {vehicleType === 'Tutte le offerte' && (
            <div className="textarea-container" style={{ marginTop: '10px' }}>
              <textarea
                value={additionalInfo}
                onChange={(e) => setAdditionalInfo(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && e.ctrlKey) {
                    e.preventDefault();
                    handleNext();
                  }
                }}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                placeholder="Es. Cerco promozioni per veicoli con bassi consumi, possibilità di rottamazione, ecc."
                className="search-textarea"
                maxLength={500}
                ref={currentInputRef}
                aria-label="Informazioni aggiuntive"
                style={{ height: '80px' }}
              />
              <div className="textarea-hint" style={{ fontSize: '12px', marginTop: '5px' }}>Premi Ctrl+Enter per inviare</div>
            </div>
          )}
        </div>
      )
    },
    {
      title: "Altre informazioni (opzionale)",
      description: "Puoi aggiungere dettagli specifici sulla promozione che cerchi.",
      input: (
        <div className="textarea-container" style={{ marginTop: '10px' }}>
          <textarea
            value={additionalInfo}
            onChange={(e) => setAdditionalInfo(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && e.ctrlKey) {
                e.preventDefault();
                handleNext();
              }
            }}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            placeholder="Es. Cerco promozioni per veicoli con bassi consumi, possibilità di rottamazione, ecc."
            className="search-textarea"
            maxLength={500}
            ref={currentInputRef}
            aria-label="Informazioni aggiuntive"
            style={{ height: '80px' }}
          />
          <div className="textarea-hint" style={{ fontSize: '12px', marginTop: '5px' }}>Premi Ctrl+Enter per inviare</div>
        </div>
      )
    }
  ];

  const currentStepData = steps[currentStep];
  const title = typeof currentStepData.title === 'function' ? currentStepData.title() : currentStepData.title;
  const description = typeof currentStepData.description === 'function' ? currentStepData.description() : currentStepData.description;

  // Stop propagation of clicks to prevent closing the modal
  const handleContainerClick = (e) => {
    e.stopPropagation();
  };

  // Prepare content to be rendered
  const content = (
    <div 
      className={`car-search-container ${keyboardOpen ? 'keyboard-open' : ''} ${isTransitioning ? 'transitioning' : ''} ${isMobile ? 'mobile-fixed-position' : ''} ${isSafariBrowser ? 'safari-browser' : ''} ${isIOSDevice ? 'ios-device' : ''}`}
      ref={containerRef}
      onClick={handleContainerClick}
      data-step={currentStep}
      style={{
        ...(keyboardOpen && isIOSDevice ? { paddingBottom: `${keyboardHeight}px` } : {}),
        maxHeight: '100vh',
        overflowY: 'auto',
        paddingTop: '5px',
        paddingBottom: '5px'
      }}
    >
      <CloseButton onClose={onClose} />
      <WelcomeMessage />
      <Header currentStep={currentStep} totalSteps={steps.length} />
      
      <form 
        className="car-search-form" 
        onSubmit={(e) => { 
          e.preventDefault(); 
          if ((isMobile || isIOSDevice) && keyboardOpen && currentInputRef.current) {
            currentInputRef.current.blur();
            setTimeout(() => handleNext(), isIOSDevice ? 300 : 100);
          } else {
            handleNext();
          }
        }}
        ref={formRef}
        style={{ paddingBottom: '5px' }}
      >
        <div className="car-search-content" style={{ padding: '0 15px', marginTop: '0' }}>
          <h2 className="car-search-title" id="promotions-search-title" style={{ fontSize: '16px', marginBottom: '3px', marginTop: '0' }}>{title}</h2>
          <p className="car-search-description" style={{ fontSize: '13px', margin: '0 0 10px 0' }}>{description}</p>
          <div className="car-search-input-wrapper">
            {currentStepData.input}
            
            <div className={`car-search-actions ${isMobile || isIOSDevice ? 'mobile-actions' : 'desktop-actions'}`} style={{ padding: '10px 0', marginTop: '5px' }}>
              {currentStep > 0 && (
                <button 
                  type="button" 
                  className="car-search-back" 
                  onClick={handleBack}
                  style={{ 
                    padding: '6px 12px', 
                    fontSize: '13px',
                    backgroundColor: 'transparent',
                    border: '1px solid #ddd',
                    borderRadius: '4px',
                    color: '#666'
                  }}
                >
                  <span>Indietro</span>
                </button>
              )}
              
              {/* Add submit button only on the last step */}
              {currentStep === steps.length - 1 && (
                <button 
                  type="submit" 
                  className="car-search-submit" 
                  onClick={handleSubmit}
                  style={{ 
                    padding: '8px 15px', 
                    fontSize: '14px', 
                    backgroundColor: '#0066cc', 
                    color: 'white', 
                    border: 'none', 
                    borderRadius: '4px', 
                    marginLeft: currentStep > 0 ? '10px' : '0'
                  }}
                >
                  <span>Cerca promozioni</span>
                </button>
              )}
            </div>
            
            {/* Add direct chat option only on the first step */}
            {currentStep === 0 && (
              <div className="car-search-direct-chat" style={{ marginTop: '15px', marginBottom: '10px' }}>
                <button 
                  type="button" 
                  onClick={handleOpenDirectChat} 
                  className="direct-chat-button"
                  style={{
                    padding: '12px',
                    border: '1px solid #eee',
                    borderRadius: '8px',
                    backgroundColor: '#f0f9ff',
                    width: '100%',
                    textAlign: 'left',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                    borderLeft: '4px solid #0066cc',
                    display: 'flex',
                    flexDirection: 'column',
                    transition: 'all 0.2s ease'
                  }}
                >
                  <span className="direct-chat-button-title" style={{ 
                    display: 'block', 
                    fontWeight: 'bold', 
                    fontSize: '15px', 
                    marginBottom: '4px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '5px'
                  }}>
                    <span>Hai bisogno di aiuto?</span>
                  </span>
                  <span className="direct-chat-button-subtitle" style={{ 
                    display: 'flex', 
                    fontSize: '13px', 
                    color: '#333',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}>
                    <span>Chiedi informazioni o organizza un appuntamento con il nostro assistente</span>
                    <ChevronRight size={16} style={{ color: '#0066cc', flexShrink: 0 }} />
                  </span>
                </button>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );

  // Render either in a portal or directly
  return usePortal ? (
    <ModalContainer>
      {content}
    </ModalContainer>
  ) : (
    <div 
      className={`car-search-overlay ${isSafariBrowser ? 'safari-browser' : ''} ${isIOSDevice ? 'ios-device' : ''}`} 
      onClick={onClose}
      aria-modal="true"
      role="dialog"
      aria-labelledby="promotions-search-title"
    >
      {content}
    </div>
  );
};

export default PromotionsSearch;