import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const loadImage = (src) => {
  return new Promise((resolve, reject) => {
    if (!src) {
      reject(new Error('No source provided'));
      return;
    }

    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = (e) => {
      console.error('Error loading image:', src);
      reject(new Error('Network response was not ok'));
    };
    img.src = src;
  });
};

const ProgressiveImage = ({ src, fallbackSrc, alt, ...props }) => {
  const [imgSrc, setImgSrc] = useState(fallbackSrc || null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!src) return;

    setIsLoading(true);
    setError(null);

    loadImage(src)
      .then(() => {
        setImgSrc(src);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error('Error loading image:', err);
        setError(err);
        setIsLoading(false);
        // Keep fallback image if loading fails
        if (fallbackSrc) {
          setImgSrc(fallbackSrc);
        }
      });
  }, [src, fallbackSrc]);

  if (error && !imgSrc) {
    return (
      <div 
        className="image-error"
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#f5f5f5',
          color: '#666',
          fontSize: '14px',
          fontWeight: 500,
          padding: '20px',
          textAlign: 'center',
          borderRadius: '8px',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '8px' }}>
          <svg 
            width="24" 
            height="24" 
            viewBox="0 0 24 24" 
            fill="none" 
            stroke="currentColor" 
            strokeWidth="2"
            style={{ marginBottom: '8px' }}
          >
            <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
            <circle cx="8.5" cy="8.5" r="1.5"></circle>
            <polyline points="21 15 16 10 5 21"></polyline>
          </svg>
          <span>Immagine non disponibile</span>
        </div>
      </div>
    );
  }

  return (
    <img
      src={imgSrc || fallbackSrc}
      alt={alt}
      className={`progressive-image ${isLoading ? 'loading' : ''}`}
      {...props}
      onError={(e) => {
        if (e && e.target && fallbackSrc) {
          e.target.src = fallbackSrc;
          e.target.style.objectFit = 'contain';
          e.target.style.padding = '20px';
          e.target.style.backgroundColor = '#f5f5f5';
        }
      }}
    />
  );
};

export default ProgressiveImage;
