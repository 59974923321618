import React, { forwardRef, useState, useEffect, useRef } from 'react';
import './MessageBubble.css';
import CustomCarousel from '../ChatBody/CustomCarousel';
import ReactMarkdown from 'react-markdown';

// Custom link component for ReactMarkdown
const CustomLink = ({ href, children }) => {
  // Ensure href is properly formatted
  let formattedHref = href;
  
  // If there's a trailing quotation mark, remove it
  if (formattedHref.endsWith('"')) {
    formattedHref = formattedHref.slice(0, -1);
  }
  
  // Ensure URL is absolute
  if (!/^https?:\/\//i.test(formattedHref)) {
    formattedHref = 'https://' + formattedHref;
  }
  
  return (
    <a 
      href={formattedHref}
      className="enhanced-link" 
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
      <span className="material-icons link-icon">open_in_new</span>
    </a>
  );
};

const MessageBubble = forwardRef(({ message, className, isHumanControlled }, ref) => {
  const { text, sender, timestamp, carouselItems, type, categories } = message;
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [formData, setFormData] = useState({
    brand: '',
    model: '',
    licensePlate: '',
    notes: '',
    preferredTime: '',
    customerType: 'private',
    timeRange: 'mattina',
    calendarDate: '',
    calendarTime: ''
  });
  const [messageSent, setMessageSent] = useState(false);
  const [useMarkdown, setUseMarkdown] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [availableTimes, setAvailableTimes] = useState([]);
  
  // Create refs for form inputs
  const formRef = useRef(null);
  const activeInputRef = useRef(null);
  const brandInputRef = useRef(null);
  const modelInputRef = useRef(null);
  const licensePlateInputRef = useRef(null);
  const preferredTimeInputRef = useRef(null);
  const notesInputRef = useRef(null);
  const submitButtonRef = useRef(null);
  const calendarDateRef = useRef(null);
  const calendarTimeRef = useRef(null);
  
  // Effect to focus on the first input field when a subcategory is selected
  useEffect(() => {
    if (selectedCategory && selectedCategory.requiresForm && selectedSubcategory && !messageSent) {
      // Get the fields for the selected subcategory
      const fields = selectedSubcategory.fields || [];
      
      // Focus on the first input field after a short delay to allow rendering
      setTimeout(() => {
        if (fields.includes('brand') && brandInputRef.current) {
          brandInputRef.current.focus();
        } else if (fields.includes('notes') && notesInputRef.current) {
          notesInputRef.current.focus();
        }
      }, 100);
    }
  }, [selectedSubcategory, messageSent]);
  
  // Effect to disable the ChatFooter's focus interval when a form is active
  useEffect(() => {
    // Only run this effect if we have an active form
    if (selectedCategory && selectedCategory.requiresForm && selectedSubcategory) {
      // Find and disable the focus interval in ChatFooter
      if (window.focusIntervalRef && window.focusIntervalRef.current) {
        clearInterval(window.focusIntervalRef.current);
        window.focusIntervalRef.current = null;
        console.log("Disabled ChatFooter focus interval");
      }
      
      // Re-enable the focus interval when component unmounts or form is closed
      return () => {
        if (window.setupFocusInterval) {
          window.setupFocusInterval();
          console.log("Re-enabled ChatFooter focus interval");
        }
      };
    }
  }, [selectedCategory, selectedSubcategory]);
  
  // Generate available times for the selected date (15-minute intervals)
  useEffect(() => {
    if (selectedDate) {
      // Generate times from 9:00 to 18:45 in 15-minute intervals
      const times = [];
      for (let hour = 9; hour < 19; hour++) {
        for (let minute = 0; minute < 60; minute += 15) {
          // Skip 18:15, 18:30, 18:45
          if (hour === 18 && minute > 0) continue;
          
          const formattedHour = hour.toString().padStart(2, '0');
          const formattedMinute = minute.toString().padStart(2, '0');
          times.push(`${formattedHour}:${formattedMinute}`);
        }
      }
      setAvailableTimes(times);
    }
  }, [selectedDate]);
  
  // Function to check if text contains markdown that needs ReactMarkdown
  const containsComplexMarkdown = (text) => {
    if (!text) return false;
    
    // Check for heading syntax, URLs, links, tables, or code blocks
    const patterns = [
      /^#+\s+.+$/m,                    // Headers
      /\[.+\]\(.+\)/,                  // Links
      /https?:\/\/\S+/,                // URLs
      /^(?:\s*[-*+]|\s*\d+\.)\s+.+$/m, // Lists
      /`{1,3}[^`]+`{1,3}/,             // Inline code
      /^>\s+.+$/m                      // Blockquotes
    ];
    
    return patterns.some(pattern => pattern.test(text));
  };
  
  // Use effect to set markdown mode on mount and when text changes
  useEffect(() => {
    if (text) {
      setUseMarkdown(containsComplexMarkdown(text));
    }
  }, [text]);
  
  // Function to convert markdown-like syntax to HTML with better list handling
  const formatText = (text) => {
    if (!text) return '';
    
    // Special handling for welcome message and system messages
    if (message.id === 'welcome' || type === 'system') {
      console.log('Directly returning HTML for welcome/system message');
      return text;
    }
    
    // If useMarkdown is already set, just return the raw text for ReactMarkdown
    if (useMarkdown) {
      return text;
    }
    
    // Check if the text already contains HTML tags
    const containsHtml = /<\/?[a-z][\s\S]*>/i.test(text);
    
    if (containsHtml) {
      // For text that already contains HTML, we need to clean it up
      // First, fix broken list structures
      let cleanedHtml = text;
      
      // Fix common issues with nested lists
      cleanedHtml = cleanedHtml.replace(/<\/li>\s*<ul>/g, '<ul>');
      cleanedHtml = cleanedHtml.replace(/<\/li>\s*<ol>/g, '<ol>');
      
      // Enhance URLs in HTML content
      cleanedHtml = cleanedHtml.replace(
        /<a\s+href="([^"]+)"([^>]*)>([^<]+)<\/a>/g, 
        '<a href="$1" class="enhanced-link" target="_blank" rel="noopener noreferrer">$3 <span class="material-icons link-icon">open_in_new</span></a>'
      );
      
      // Fix car listing format - convert incorrectly formatted lists to proper structure
      if (cleanedHtml.includes('Audi') && cleanedHtml.includes('Prezzo:') && cleanedHtml.includes('Anno:')) {
        // This is likely a car listing - convert to a better format
        
        // Remove any existing list tags that might be causing issues
        cleanedHtml = cleanedHtml.replace(/<\/?[ou]l>|<\/?li>/g, '');
        
        // Split by car model (assuming each car starts with "Audi")
        const parts = cleanedHtml.split(/<strong>Audi|Audi/);
        
        if (parts.length > 1) {
          let result = parts[0]; // Keep any intro text
          
          // Start a div container for the car listings
          result += '<div class="car-listings">';
          
          // Process each car entry
          for (let i = 1; i < parts.length; i++) {
            const carDetails = '<strong>Audi' + parts[i];
            
            // Create a styled div for each car
            result += `
              <div class="car-item">
                ${carDetails.replace(/Prezzo:/, '<br><strong>Prezzo:</strong>')
                           .replace(/Anno:/, '<br><strong>Anno:</strong>')
                           .replace(/Chilometraggio:/, '<br><strong>Chilometraggio:</strong>')}
              </div>
            `;
          }
          
          result += '</div>';
          return result;
        }
      }
      
      return cleanedHtml;
    }
    
    // For regular markdown text (not HTML)
    
    // Convert bold text (**text**)
    let formattedText = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    
    // Convert italic text (*text*)
    formattedText = formattedText.replace(/\*([^*]+)\*/g, '<em>$1</em>');
    
    // Convert markdown headings (### Heading)
    formattedText = formattedText.replace(/^###\s+(.*?)$/gm, '<h3>$1</h3>');
    formattedText = formattedText.replace(/^##\s+(.*?)$/gm, '<h2>$1</h2>');
    formattedText = formattedText.replace(/^#\s+(.*?)$/gm, '<h1>$1</h1>');
    
    // Convert links with enhanced styling ([text](url))
    formattedText = formattedText.replace(
      /\[([^\]]+)\]\(([^)]+)\)/g, 
      '<a href="$2" class="enhanced-link" target="_blank" rel="noopener noreferrer">$1 <span class="material-icons link-icon">open_in_new</span></a>'
    );
    
    // More careful URL matching to avoid capturing trailing characters
    // This regex looks for URLs that are bounded by whitespace, start of string, or end of string
    formattedText = formattedText.replace(
      /(^|\s)(https?:\/\/[^\s"]+)(?=\s|$|"|\.|\)|\]|,|;|<|\?|!)/g, 
      '$1<a href="$2" class="enhanced-link" target="_blank" rel="noopener noreferrer">$2 <span class="material-icons link-icon">open_in_new</span></a>'
    );
    
    // Handle bullet lists (lines starting with - or *)
    const bulletListRegex = /(?:^|\n)((?:[ \t]*[-*][ \t]+.+(?:\n|$))+)/g;
    formattedText = formattedText.replace(bulletListRegex, (match, listBlock) => {
      const items = listBlock.split(/\n/).filter(line => /^[ \t]*[-*][ \t]+/.test(line));
      const listItems = items.map(item => {
        const content = item.replace(/^[ \t]*[-*][ \t]+/, '');
        return `<li>${content}</li>`;
      }).join('');
      return `<ul class="formatted-list">${listItems}</ul>`;
    });
    
    // Handle numbered lists (lines starting with number.)
    const numberedListRegex = /(?:^|\n)((?:[ \t]*\d+\.[ \t]+.+(?:\n|$))+)/g;
    formattedText = formattedText.replace(numberedListRegex, (match, listBlock) => {
      const items = listBlock.split(/\n/).filter(line => /^[ \t]*\d+\.[ \t]+/.test(line));
      const listItems = items.map(item => {
        const content = item.replace(/^[ \t]*\d+\.[ \t]+/, '');
        return `<li>${content}</li>`;
      }).join('');
      return `<ol class="formatted-list">${listItems}</ol>`;
    });
    
    // Convert line breaks to <br> tags
    formattedText = formattedText.replace(/\n/g, '<br>');
    
    return formattedText;
  };
  
  const handleSendMessage = (message) => {
    console.log("Sending message:", message);
    
    // Try different methods to send the message
    // Method 1: Use the global sendMessage function if available
    if (typeof window.sendMessage === 'function') {
      window.sendMessage(message);
      console.log("Message sent using window.sendMessage");
      setMessageSent(true);
      return;
    }
    
    // Method 2: Find the ChatBody component's onSendMessage function
    if (window.chatBodyInstance && typeof window.chatBodyInstance.handleSendMessage === 'function') {
      window.chatBodyInstance.handleSendMessage(message);
      console.log("Message sent using chatBodyInstance.handleSendMessage");
      setMessageSent(true);
      return;
    }
    
    // Method 3: Create and dispatch a custom event
    const event = new CustomEvent('sendChatMessage', { 
      detail: { message },
      bubbles: true 
    });
    document.dispatchEvent(event);
    console.log("Message sent using custom event");
    setMessageSent(true);
    
    // Method 4: Direct DOM manipulation - find the chat input and send button
    try {
      const chatInput = document.querySelector('.chat-input');
      const sendButton = document.querySelector('.send-button');
      
      if (chatInput && sendButton) {
        // Set the input value
        const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
          window.HTMLInputElement.prototype, 'value'
        ).set;
        nativeInputValueSetter.call(chatInput, message);
        
        // Trigger input event
        const inputEvent = new Event('input', { bubbles: true });
        chatInput.dispatchEvent(inputEvent);
        
        // Click the send button
        sendButton.click();
        console.log("Message sent using DOM manipulation");
        setMessageSent(true);
      }
    } catch (error) {
      console.error("Error sending message via DOM manipulation:", error);
    }
  };
  
  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setSelectedSubcategory(null);
    setMessageSent(false);
    
    // Reset form data when changing categories
    setFormData({
      brand: '',
      model: '',
      licensePlate: '',
      notes: '',
      preferredTime: '',
      customerType: 'private',
      timeRange: 'mattina',
      calendarDate: '',
      calendarTime: ''
    });
    
    // Scroll to subcategories after a short delay to allow rendering
    setTimeout(() => {
      const subcategoriesContainer = document.querySelector('.subcategories-container');
      if (subcategoriesContainer) {
        subcategoriesContainer.scrollIntoView({ behavior: 'smooth', block: 'center' });
        
        // Focus on the first subcategory button
        const subcategoryButtons = document.querySelectorAll('.subcategory-button');
        if (subcategoryButtons && subcategoryButtons.length > 0) {
          subcategoryButtons[0].focus();
        }
      }
    }, 100);
  };
  
  // Hardcoded prompts for information categories
  const getInfoPrompt = (categoryId, subcategoryId) => {
    const prompts = {
      'promotions': {
        'new-cars': 'Quali sono le promozioni attuali sulle auto nuove? Sono interessato a conoscere le offerte speciali, i finanziamenti agevolati e gli incentivi disponibili.',
        'used-cars': 'Vorrei informazioni sulle promozioni per auto usate. Avete veicoli in offerta speciale o programmi di finanziamento dedicati all\'usato?',
        'service-promo': 'Quali promozioni avete attualmente per i servizi di officina? Ci sono sconti su tagliandi, manutenzione o altri servizi?',
        'financing': 'Vorrei conoscere le opzioni di finanziamento disponibili. Quali tassi di interesse offrite e quali sono le condizioni per leasing e finanziamenti?'
      },
      'info': {
        'models': 'Quali modelli di auto avete attualmente disponibili in concessionaria? Sono interessato a conoscere le caratteristiche e i prezzi.',
        'prices': 'Vorrei ricevere informazioni sui prezzi e richiedere un preventivo. Potete fornirmi un listino aggiornato dei modelli disponibili?',
        'hours': 'Quali sono gli orari di apertura della concessionaria e dell\'officina? Vorrei anche sapere come posso contattarvi per un appuntamento.',
        'other-info': 'Vorrei ricevere informazioni generali sui vostri servizi. Cosa offrite ai clienti e quali sono i vantaggi di scegliere la vostra concessionaria?'
      }
    };
    
    return prompts[categoryId]?.[subcategoryId] || `Vorrei informazioni su: ${categoryId} - ${subcategoryId}`;
  };
  
  // Handle subcategory click
  const handleSubcategoryClick = (subcategory) => {
    setSelectedSubcategory(subcategory);
    
    // If no form is required, send the hardcoded prompt immediately
    if (selectedCategory && !selectedCategory.requiresForm) {
      const messageText = getInfoPrompt(selectedCategory.id, subcategory.id);
      handleSendMessage(messageText);
      
      // Reset selections after sending
      setTimeout(() => {
        setSelectedCategory(null);
        setSelectedSubcategory(null);
      }, 500);
      return;
    }
    
    // If form is required, scroll to it and focus on the first field
    setTimeout(() => {
      const formContainer = document.querySelector('.appointment-form');
      if (formContainer) {
        formContainer.scrollIntoView({ behavior: 'smooth', block: 'center' });
        
        const fields = subcategory.fields || [];
        
        // Focus on the appropriate first field
        if (fields.includes('brand') && brandInputRef.current) {
          brandInputRef.current.focus();
        } else if (fields.includes('notes') && notesInputRef.current) {
          notesInputRef.current.focus();
        } else if (fields.includes('calendarDate') && calendarDateRef.current) {
          calendarDateRef.current.focus();
        } else if (fields.includes('timeRange')) {
          const timeRangeInputs = document.querySelectorAll('input[name="timeRange"]');
          if (timeRangeInputs && timeRangeInputs.length > 0) {
            timeRangeInputs[0].focus();
          }
        }
      }
    }, 100);
  };
  
  // Check if the form is valid before submission
  const isFormValid = () => {
    if (!selectedCategory || !selectedCategory.requiresForm || !selectedSubcategory) return false;
    
    const fields = selectedSubcategory.fields || [];
    
    // Check required fields
    if (fields.includes('brand') && !formData.brand.trim()) return false;
    
    // If brand is not required but model is, check model
    if (!fields.includes('brand') && fields.includes('model') && !formData.model.trim()) return false;
    
    // Check license plate for trade-in
    if (fields.includes('licensePlate') && selectedSubcategory.id === 'trade-in' && !formData.licensePlate.trim()) return false;
    
    // Check notes for other-visit
    if (fields.includes('notes') && selectedSubcategory.id === 'other-visit' && !formData.notes.trim()) return false;
    
    // Check calendar fields
    if (fields.includes('calendar') && (!formData.calendarDate || !formData.calendarTime)) return false;
    
    // Check time range field
    if (fields.includes('timeRange') && !formData.timeRange) return false;
    
    // Check preferred time if required
    if (fields.includes('preferredTime') && !formData.preferredTime.trim()) return false;
    
    return true;
  };
  
  // Get the next input field based on the current field
  const getNextInputField = (currentField) => {
    if (!selectedCategory || !selectedSubcategory) return null;
    
    const fields = selectedSubcategory.fields || [];
    const fieldOrder = [
      'brand', 
      'model', 
      'customerType', 
      'licensePlate', 
      'calendarDate',
      'calendarTime',
      'timeRange',
      'preferredTime', 
      'notes'
    ];
    
    // Find the index of the current field
    const currentIndex = fieldOrder.indexOf(currentField);
    
    // If not found or last field, return null
    if (currentIndex === -1 || currentIndex === fieldOrder.length - 1) return null;
    
    // Find the next field that is included in the form
    for (let i = currentIndex + 1; i < fieldOrder.length; i++) {
      if (fields.includes(fieldOrder[i])) {
        return fieldOrder[i];
      }
    }
    
    return null;
  };
  
  // Function to focus on the next input field
  const focusNextField = (currentField) => {
    const nextField = getNextInputField(currentField);
    
    if (!nextField) {
      // If there's no next field, focus on the submit button
      if (submitButtonRef.current && isFormValid()) {
        submitButtonRef.current.focus();
        // Optionally, auto-submit the form after a delay
        // setTimeout(() => submitButtonRef.current.click(), 500);
      }
      return;
    }
    
    // Focus on the next field
    switch (nextField) {
      case 'model':
        if (modelInputRef.current) modelInputRef.current.focus();
        break;
      case 'licensePlate':
        if (licensePlateInputRef.current) licensePlateInputRef.current.focus();
        break;
      case 'preferredTime':
        if (preferredTimeInputRef.current) preferredTimeInputRef.current.focus();
        break;
      case 'notes':
        if (notesInputRef.current) notesInputRef.current.focus();
        break;
      default:
        break;
    }
  };
  
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'radio' ? checked ? value : formData[name] : value;
    
    setFormData(prev => ({
      ...prev,
      [name]: newValue
    }));
    
    // Add validation feedback
    if (type !== 'radio' && value.trim() !== '') {
      // Add visual feedback for completed field
      e.target.classList.add('field-valid');
      
      // Show checkmark icon
      const parentGroup = e.target.closest('.form-group');
      if (parentGroup) {
        const existingIcon = parentGroup.querySelector('.field-valid-icon');
        if (!existingIcon) {
          const validIcon = document.createElement('span');
          validIcon.className = 'field-valid-icon material-icons';
          validIcon.textContent = 'check_circle';
          parentGroup.appendChild(validIcon);
        }
      }
      
      // Remove automatic field progression - we'll only progress when user presses Enter or Tab
      // The focusNextField function will be called from handleInputKeyDown instead
    } else if (type !== 'radio' && value.trim() === '') {
      // Remove validation if field is emptied
      e.target.classList.remove('field-valid');
      
      // Remove checkmark icon
      const parentGroup = e.target.closest('.form-group');
      if (parentGroup) {
        const existingIcon = parentGroup.querySelector('.field-valid-icon');
        if (existingIcon) {
          parentGroup.removeChild(existingIcon);
        }
      }
    }
  };
  
  // Handle input completion (when user presses Tab or Enter)
  const handleInputKeyDown = (e, fieldName) => {
    e.stopPropagation();
    
    // If Tab is pressed, let the default behavior handle it
    if (e.key === 'Tab') {
      // Don't prevent default - let the browser handle tab navigation
      // But we'll still call focusNextField to handle special cases
      if (!e.shiftKey) { // Only for forward tab, not shift+tab
        focusNextField(fieldName);
      }
      return;
    }
    
    // If Enter is pressed, move to the next field or submit the form
    if (e.key === 'Enter' && e.target.tagName !== 'TEXTAREA') {
      e.preventDefault(); // Prevent form submission
      
      // Get the next field
      const nextField = getNextInputField(fieldName);
      
      // If there's no next field, try to submit the form
      if (!nextField) {
        if (isFormValid() && submitButtonRef.current) {
          submitButtonRef.current.click();
        }
        return;
      }
      
      // For calendar fields, handle special case
      if (fieldName === 'calendarDate' && nextField === 'calendarTime') {
        // Wait for available times to be generated
        setTimeout(() => {
          calendarTimeRef.current?.focus();
        }, 100);
        return;
      }
      
      // For other fields, focus on the next field
      focusNextField(fieldName);
    }
  };
  
  const handleInputFocus = (e) => {
    e.stopPropagation();
    // Set this input as the active input
    activeInputRef.current = e.target;
    
    // Disable the focus interval in ChatFooter
    if (window.focusIntervalRef && window.focusIntervalRef.current) {
      clearInterval(window.focusIntervalRef.current);
      window.focusIntervalRef.current = null;
    }
  };
  
  // Generate a dynamic prompt based on the selected category and subcategory
  const generateDynamicPrompt = () => {
    if (!selectedCategory || !selectedSubcategory) return '';
    
    let prompt = '';
    
    // Add category and subcategory information
    prompt += `Categoria: ${selectedCategory.title}, Sottocategoria: ${selectedSubcategory.title}\n`;
    
    // Add form data if available
    if (selectedCategory.requiresForm) {
      if (formData.brand) prompt += `Marca: ${formData.brand}\n`;
      if (formData.model) prompt += `Modello: ${formData.model}\n`;
      if (formData.licensePlate) prompt += `Targa: ${formData.licensePlate}\n`;
      if (formData.customerType) prompt += `Tipo cliente: ${formData.customerType === 'private' ? 'Privato' : 'Azienda'}\n`;
      
      // Add new fields
      if (formData.timeRange) {
        prompt += `Fascia oraria: ${formData.timeRange === 'mattina' ? 'Mattina (9:00 - 13:00)' : 'Pomeriggio (14:00 - 18:00)'}\n`;
      }
      
      if (formData.calendarDate && formData.calendarTime) {
        prompt += `Data e ora appuntamento: ${formData.calendarDate} alle ${formData.calendarTime}\n`;
      }
      
      if (formData.preferredTime) prompt += `Orario desiderato: ${formData.preferredTime}\n`;
      if (formData.notes) prompt += `Note: ${formData.notes}\n`;
    }
    
    return prompt;
  };
  
  // Handle form submission
  const handleFormSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    // Validate the form
    if (!isFormValid()) {
      console.log("Form is not valid");
      return;
    }
    
    // Generate the prompt based on the selected category and subcategory
    const prompt = generateDynamicPrompt();
    
    // Create a formatted message for display
    let formattedMessage = '';
    
    if (selectedCategory.id === 'dealership') {
      formattedMessage = `Richiesta di appuntamento in concessionaria per ${selectedSubcategory.title.toLowerCase()}`;
      
      if (formData.brand && formData.model) {
        formattedMessage += ` di ${formData.brand} ${formData.model}`;
      } else if (formData.brand) {
        formattedMessage += ` di ${formData.brand}`;
      }
      
      if (formData.calendarDate && formData.calendarTime) {
        const date = new Date(formData.calendarDate);
        const formattedDate = date.toLocaleDateString('it-IT', { 
          weekday: 'long', 
          day: 'numeric', 
          month: 'long', 
          year: 'numeric' 
        });
        formattedMessage += ` per il giorno ${formattedDate} alle ore ${formData.calendarTime}`;
      }
    } else if (selectedCategory.id === 'workshop') {
      formattedMessage = `Richiesta di appuntamento in officina per ${selectedSubcategory.title.toLowerCase()}`;
      
      if (formData.brand && formData.model) {
        formattedMessage += ` di ${formData.brand} ${formData.model}`;
      } else if (formData.brand) {
        formattedMessage += ` di ${formData.brand}`;
      }
      
      if (formData.licensePlate) {
        formattedMessage += ` (targa: ${formData.licensePlate})`;
      }
      
      if (formData.timeRange) {
        formattedMessage += ` in fascia ${formData.timeRange === 'mattina' ? 'mattutina (9:00-13:00)' : 'pomeridiana (14:00-18:00)'}`;
      }
    }
    
    // Send the message
    handleSendMessage(prompt);
    
    // Set message sent to true to show success message
    setMessageSent(true);
    
    // Log the form submission
    console.log("Form submitted:", {
      category: selectedCategory.title,
      subcategory: selectedSubcategory.title,
      formData,
      prompt
    });
    
    // Reset selections after a delay
    setTimeout(() => {
      setSelectedCategory(null);
      setSelectedSubcategory(null);
      setFormData({
        brand: '',
        model: '',
        licensePlate: '',
        notes: '',
        preferredTime: '',
        customerType: 'private',
        timeRange: 'mattina',
        calendarDate: '',
        calendarTime: ''
      });
      
      // Clear the active input reference
      activeInputRef.current = null;
      
      // Re-enable the focus interval in ChatFooter
      if (window.setupFocusInterval) {
        window.setupFocusInterval();
      }
    }, 5000); // Increased delay to 5 seconds to give users time to see the success message
  };
  
  // Add click handler to stop propagation for all form elements
  const handleFormClick = (e) => {
    e.stopPropagation();
  };
  
  // Prevent keyboard events from bubbling up
  const handleFormKeyDown = (e) => {
    e.stopPropagation();
  };
  
  // Render the category icons
  const renderCategories = () => {
    if (!categories || categories.length === 0) return null;
    
    return (
      <div className="message-categories">
        {categories.map(category => (
          <button
            key={category.id}
            className={`category-button ${selectedCategory && selectedCategory.id === category.id ? 'active' : ''}`}
            onClick={() => handleCategoryClick(category)}
            aria-label={category.title}
          >
            <span className="category-icon material-icons">{category.icon}</span>
            <span className="category-title">{category.title}</span>
          </button>
        ))}
      </div>
    );
  };
  
  // Add back button handler
  const handleBackClick = () => {
    if (selectedSubcategory) {
      // If we're in a form or subcategory, go back to subcategories
      setSelectedSubcategory(null);
      // Scroll back to subcategories
      setTimeout(() => {
        const subcategoriesContainer = document.querySelector('.subcategories-container');
        if (subcategoriesContainer) {
          subcategoriesContainer.scrollIntoView({ behavior: 'smooth', block: 'center' });
          // Focus on the previously selected subcategory
          const subcategoryButtons = document.querySelectorAll('.subcategory-button');
          if (subcategoryButtons && subcategoryButtons.length > 0) {
            subcategoryButtons[0].focus();
          }
        }
      }, 100);
    } else if (selectedCategory) {
      // If we're in subcategories, go back to categories
      setSelectedCategory(null);
      // Scroll back to categories
      setTimeout(() => {
        const categoriesContainer = document.querySelector('.message-categories');
        if (categoriesContainer) {
          categoriesContainer.scrollIntoView({ behavior: 'smooth', block: 'center' });
          // Focus on the previously selected category
          const categoryButtons = document.querySelectorAll('.category-button');
          if (categoryButtons && categoryButtons.length > 0) {
            categoryButtons[0].focus();
          }
        }
      }, 100);
    }
  };
  
  // Modify renderSubcategories to include back button
  const renderSubcategories = () => {
    if (!selectedCategory || !selectedCategory.subcategories) return null;
    
    const subcategories = selectedCategory.subcategories || [];
    const itemCount = subcategories.length;
    const gridClassName = `subcategories-grid items-${itemCount}`;
    
    return (
      <div className="subcategories-container">
        <div className="subcategories-header">
          <button 
            className="back-button" 
            onClick={handleBackClick}
            aria-label="Torna indietro"
          >
            <span className="material-icons">arrow_back</span>
            <span>Indietro</span>
          </button>
          <div className="subcategories-title">
            <span className="material-icons">{selectedCategory.icon}</span>
            <h3>{selectedCategory.title}</h3>
          </div>
        </div>
        <div className={gridClassName}>
          {selectedCategory.subcategories.map(subcategory => (
            <button
              key={subcategory.id}
              className={`subcategory-button ${selectedSubcategory && selectedSubcategory.id === subcategory.id ? 'active' : ''}`}
              onClick={() => handleSubcategoryClick(subcategory)}
            >
              {subcategory.title}
            </button>
          ))}
        </div>
      </div>
    );
  };
  
  // Modify renderForm to include back button
  const renderForm = () => {
    if (!selectedCategory || !selectedCategory.requiresForm || !selectedSubcategory) return null;
    
    if (messageSent) {
      return (
        <div className="form-success-message">
          <span className="material-icons success-icon">check_circle</span>
          <h3>Richiesta inviata!</h3>
          <p>La tua richiesta è stata inviata con successo. L'assistente risponderà a breve.</p>
        </div>
      );
    }
    
    const fields = selectedSubcategory.fields || [];
    
    return (
      <form 
        className="appointment-form" 
        onSubmit={handleFormSubmit} 
        onClick={handleFormClick}
        onKeyDown={handleFormKeyDown}
        ref={formRef}
      >
        <div className="form-header">
          <button 
            type="button"
            className="back-button" 
            onClick={handleBackClick}
            aria-label="Torna indietro"
          >
            <span className="material-icons">arrow_back</span>
            <span>Indietro</span>
          </button>
          <h3>Compila i dettagli</h3>
          <p className="form-note">
            Nota: questo è solo il primo passo per la prenotazione. Dopo l'invio, sarai ricontattato per confermare l'appuntamento.
            {selectedCategory.id === 'dealership' && fields.includes('calendar') && 
              " L'orario selezionato è una preferenza e verrà confermato in base alla disponibilità."}
            {selectedCategory.id === 'workshop' && fields.includes('timeRange') && 
              " La fascia oraria selezionata è indicativa e verrà confermata in base alla disponibilità."}
          </p>
        </div>
        
        {/* Brand field */}
        {fields.includes('brand') && (
          <div className="form-group">
            <label htmlFor="brand">Marca*</label>
            <input
              type="text"
              id="brand"
              name="brand"
              value={formData.brand}
              onChange={handleInputChange}
              onKeyDown={(e) => handleInputKeyDown(e, 'brand')}
              onFocus={handleInputFocus}
              required
              placeholder="Es. Fiat, Alfa Romeo, ecc."
              className="form-control"
              autoComplete="off"
              ref={brandInputRef}
            />
          </div>
        )}
        
        {/* Model field */}
        {fields.includes('model') && (
          <div className="form-group">
            <label htmlFor="model">Modello{!fields.includes('brand') && '*'}</label>
            <input
              type="text"
              id="model"
              name="model"
              value={formData.model}
              onChange={handleInputChange}
              onKeyDown={(e) => handleInputKeyDown(e, 'model')}
              onFocus={handleInputFocus}
              placeholder="Es. 500, Giulia, ecc."
              required={!fields.includes('brand')}
              className="form-control"
              autoComplete="off"
              ref={modelInputRef}
            />
          </div>
        )}
        
        {/* Customer type field */}
        {fields.includes('customerType') && (
          <div className="form-group">
            <label>Tipo cliente*</label>
            <div className="radio-group">
              <label className="radio-label">
                <input
                  type="radio"
                  name="customerType"
                  value="private"
                  checked={formData.customerType === 'private'}
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                  onKeyDown={(e) => handleInputKeyDown(e, 'customerType')}
                />
                Privato
              </label>
              <label className="radio-label">
                <input
                  type="radio"
                  name="customerType"
                  value="company"
                  checked={formData.customerType === 'company'}
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                  onKeyDown={(e) => handleInputKeyDown(e, 'customerType')}
                />
                Azienda
              </label>
            </div>
          </div>
        )}
        
        {/* License plate field */}
        {fields.includes('licensePlate') && (
          <div className="form-group">
            <label htmlFor="licensePlate">Targa{selectedSubcategory.id === 'trade-in' && '*'}</label>
            <input
              type="text"
              id="licensePlate"
              name="licensePlate"
              value={formData.licensePlate}
              onChange={handleInputChange}
              onKeyDown={(e) => handleInputKeyDown(e, 'licensePlate')}
              onFocus={handleInputFocus}
              placeholder="Es. AB123CD"
              required={selectedSubcategory.id === 'trade-in'}
              className="form-control"
              autoComplete="off"
              ref={licensePlateInputRef}
            />
          </div>
        )}
        
        {/* Time range field */}
        {fields.includes('timeRange') && (
          <div className="form-group">
            <label htmlFor="timeRange">Fascia oraria preferita*</label>
            <div className="time-range-selector">
              <label className="time-range-option">
                <input
                  type="radio"
                  name="timeRange"
                  value="mattina"
                  checked={formData.timeRange === 'mattina'}
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                  onKeyDown={(e) => handleInputKeyDown(e, 'timeRange')}
                  required
                />
                <span className="time-range-label">
                  <span className="material-icons">wb_sunny</span>
                  Mattina (9:00 - 13:00)
                </span>
              </label>
              <label className="time-range-option">
                <input
                  type="radio"
                  name="timeRange"
                  value="pomeriggio"
                  checked={formData.timeRange === 'pomeriggio'}
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                  onKeyDown={(e) => handleInputKeyDown(e, 'timeRange')}
                  required
                />
                <span className="time-range-label">
                  <span className="material-icons">wb_twilight</span>
                  Pomeriggio (14:00 - 18:00)
                </span>
              </label>
            </div>
            <p className="field-help-text">La fascia oraria selezionata è indicativa e verrà confermata in base alla disponibilità.</p>
          </div>
        )}
        
        {/* Calendar field */}
        {fields.includes('calendar') && (
          <div className="form-group calendar-group">
            <label htmlFor="calendarDate">Data e ora dell'appuntamento*</label>
            <div className="calendar-container">
              <div className="calendar-date-picker">
                <label htmlFor="calendarDate">Data*</label>
                <input
                  type="date"
                  id="calendarDate"
                  name="calendarDate"
                  value={formData.calendarDate}
                  onChange={(e) => {
                    handleInputChange(e);
                    setSelectedDate(e.target.value);
                  }}
                  onKeyDown={(e) => handleInputKeyDown(e, 'calendarDate')}
                  onFocus={handleInputFocus}
                  required
                  className="form-control"
                  min={new Date().toISOString().split('T')[0]}
                  ref={calendarDateRef}
                />
              </div>
              
              {formData.calendarDate && (
                <div className="calendar-time-picker">
                  <label htmlFor="calendarTime">Orario*</label>
                  <select
                    id="calendarTime"
                    name="calendarTime"
                    value={formData.calendarTime}
                    onChange={handleInputChange}
                    onKeyDown={(e) => handleInputKeyDown(e, 'calendarTime')}
                    onFocus={handleInputFocus}
                    required
                    className="form-control"
                    ref={calendarTimeRef}
                  >
                    <option value="">Seleziona un orario</option>
                    {availableTimes.map(time => (
                      <option key={time} value={time}>{time}</option>
                    ))}
                  </select>
                </div>
              )}
            </div>
            <p className="field-help-text">L'orario selezionato è una preferenza e verrà confermato in base alla disponibilità.</p>
          </div>
        )}
        
        {/* Preferred time field (legacy) */}
        {fields.includes('preferredTime') && (
          <div className="form-group">
            <label htmlFor="preferredTime">Orario desiderato*</label>
            <input
              type="text"
              id="preferredTime"
              name="preferredTime"
              value={formData.preferredTime}
              onChange={handleInputChange}
              onKeyDown={(e) => handleInputKeyDown(e, 'preferredTime')}
              onFocus={handleInputFocus}
              placeholder="Es. Lunedì pomeriggio, Martedì mattina, ecc."
              required
              className="form-control"
              autoComplete="off"
              ref={preferredTimeInputRef}
            />
          </div>
        )}
        
        {/* Notes field - always displayed at the end */}
        {fields.includes('notes') && (
          <div className="form-group">
            <label htmlFor="notes">Note{selectedSubcategory.id === 'other-visit' && '*'}</label>
            <textarea
              id="notes"
              name="notes"
              value={formData.notes}
              onChange={handleInputChange}
              onKeyDown={(e) => {
                e.stopPropagation();
                if (e.key === 'Tab') {
                  e.preventDefault();
                  focusNextField('notes');
                }
              }}
              onFocus={handleInputFocus}
              placeholder="Inserisci eventuali note o richieste specifiche"
              required={selectedSubcategory.id === 'other-visit'}
              className="form-control"
              rows="3"
              autoComplete="off"
              ref={notesInputRef}
            />
          </div>
        )}
        
        <div className="form-actions">
          <button 
            type="submit" 
            className="submit-button"
            ref={submitButtonRef}
          >
            Invia richiesta
          </button>
        </div>
        
        <div className="form-disclaimer">
          <p>Dopo l'invio della richiesta, sarai ricontattato per confermare i dettagli dell'appuntamento.</p>
        </div>
      </form>
    );
  };
  
  // Determine if this is a short message (1-3 words)
  const isShortMessage = message.text && 
    message.text.trim().split(/\s+/).length <= 3 && 
    message.text.length < 30;

  // Add the short-text class for short user messages
  const userMessageClass = `message-bubble user-message ${isShortMessage ? 'short-text' : ''}`;
  const botMessageClass = `message-bubble bot-message ${isHumanControlled ? 'human-operator' : ''}`;

  // Build message classes
  const messageClasses = ['message-bubble'];
  
  if (sender === 'user') {
    messageClasses.push(userMessageClass);
  } else if (sender === 'bot' || message.id === 'welcome') {
    messageClasses.push(botMessageClass);
  } else if (type) {
    messageClasses.push(`${type}-message`);
  }
  
  // Add special class for messages with carousels
  const hasCarousel = carouselItems && carouselItems.length > 0;
  if (hasCarousel) {
    messageClasses.push('has-carousel');
  }
  
  // Add special class for welcome message
  if (message.id === 'welcome') {
    messageClasses.push('system-message');
  }
  
  if (className) {
    messageClasses.push(className);
  }
  
  // Add a debug log for message properties
  useEffect(() => {
    if (message.id === 'welcome' || type === 'system') {
      console.log('Processing welcome/system message:', {
        id: message.id,
        type,
        hasHTML: /<\/?[a-z][\s\S]*>/i.test(text || ''),
        textLength: (text || '').length
      });
    }
  }, [message.id, type, text]);

  return (
    <div 
      ref={ref} 
      className={messageClasses.join(' ')} 
      tabIndex="0"
      aria-label={`${sender === 'user' ? 'Your message' : 'Bot message'}: ${text ? text.replace(/<[^>]*>/g, '') : ''}`}
      role="article"
    >
      {/* Special handling for system messages and welcome message */}
      {(type === 'system' || message.id === 'welcome') ? (
        <div className="message-content" dangerouslySetInnerHTML={{ __html: text }} />
      ) : useMarkdown ? (
        <div className="message-content">
          <ReactMarkdown
            components={{
              a: CustomLink,
              p: ({ node, ...props }) => <p {...props} />,
              ul: ({ node, ...props }) => <ul className="formatted-list" {...props} />,
              ol: ({ node, ...props }) => <ol className="formatted-list" {...props} />,
              li: ({ node, ...props }) => <li {...props} />,
              h1: ({ node, ...props }) => <h1 {...props} />,
              h2: ({ node, ...props }) => <h2 {...props} />,
              h3: ({ node, ...props }) => <h3 {...props} />
            }}
          >
            {text || ''}
          </ReactMarkdown>
        </div>
      ) : (
        <div className="message-content" dangerouslySetInnerHTML={{ __html: formatText(text) }} />
      )}
      {timestamp && <div className="message-timestamp">{timestamp}</div>}
      
      {carouselItems && carouselItems.length > 0 && (
        <div className="message-with-carousel">
          <div className="full-width-carousel">
            <CustomCarousel items={carouselItems} />
          </div>
        </div>
      )}
      
      {/* Only render categories if not a welcome message */}
      {message.id !== 'welcome' && categories && categories.length > 0 && !selectedCategory && renderCategories()}
      {message.id !== 'welcome' && selectedCategory && !selectedSubcategory && renderSubcategories()}
      {message.id !== 'welcome' && selectedCategory && selectedCategory.requiresForm && selectedSubcategory && !messageSent && renderForm()}
    </div>
  );
});

// Wrap with React.memo to prevent unnecessary re-renders
export default React.memo(MessageBubble); 