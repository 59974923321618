import { ThemeProvider as ThemeMuiProvider, GlobalStyles } from "@mui/material";
import ChatWrapper from "./components/ChatWrapper";
import { ThemeProvider, WebSocketProvider, useThemeContext } from "./context/Theme";

const ThemeCheck: React.FC = () => {
  const { loaded, theme } = useThemeContext();

  return loaded ? (
    <ThemeMuiProvider theme={theme}>
      <GlobalStyles styles={{ 
        "html, body": {
          fontFamily: "'Roboto', sans-serif",
          fontSize: "16px",
        },
        ".whatsapp-btn": { display: "none !important" },
        "#chat-root": {
          maxWidth: "800px",
          margin: "0 auto",
          position: "fixed",
          bottom: "0",
          right: "0",
          zIndex: "9999",
          fontFamily: "'Roboto', sans-serif"
        }
      }} />
      <ChatWrapper />
    </ThemeMuiProvider>
  ) : null;
};

const App: React.FC = () => {
  return (
    <WebSocketProvider>
      <ThemeProvider>
        <ThemeCheck />
      </ThemeProvider>
    </WebSocketProvider>
  );
};

export default App;
