import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { X, Calendar, Clock } from 'lucide-react';
import './MobileCarSearch.css'; // Reusing the same CSS

// Header component
const Header = ({ currentStep, totalSteps }) => (
  <div className="car-search-header" style={{ padding: '8px 15px', marginBottom: '5px' }}>
    <div className="car-search-progress" style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      {[...Array(totalSteps)].map((_, index) => (
        <div 
          key={index} 
          className={`progress-dot ${index === currentStep ? 'active' : index < currentStep ? 'completed' : ''}`}
          style={{ width: '8px', height: '8px', margin: '0 3px' }}
        />
      ))}
    </div>
  </div>
);

// Close button component
const CloseButton = ({ onClose }) => (
  <button className="car-search-close" onClick={onClose} aria-label="Chiudi ricerca">
    <X size={18} />
  </button>
);

// Modal container component
const ModalContainer = ({ children }) => {
  // Add class based on viewport height for better positioning
  const viewportClass = window.innerHeight < 800 ? 'short-viewport' : 'normal-viewport';
  
  return ReactDOM.createPortal(
    <div 
      className={`car-search-overlay ${viewportClass}`}
      aria-modal="true"
      role="dialog"
      aria-labelledby="rental-search-title"
    >
      {children}
    </div>,
    document.body
  );
};

// Welcome message component
const WelcomeMessage = () => (
  <div className="car-search-welcome" style={{ margin: '5px 0', padding: '5px 15px' }}>
    <span className="welcome-icon">🚗</span>
    <h2 style={{ fontSize: '16px', marginBottom: '3px' }}>Noleggio auto</h2>
    <p style={{ fontSize: '12px', lineHeight: '1.2', margin: 0 }}>Rispondi a poche domande per trovare la soluzione di noleggio più adatta alle tue esigenze</p>
  </div>
);

// Utility functions for browsers
const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf('safari') > -1 && ua.indexOf('chrome') === -1;
};

const isIOS = () => {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
};

// Main component
const RentalSearch = ({ onClose, onSubmit, usePortal = true, toggleChat }) => {
  // State variables
  const [currentStep, setCurrentStep] = useState(0);
  const [clientType, setClientType] = useState('');
  const [rentalType, setRentalType] = useState('');
  const [duration, setDuration] = useState('');
  const [vehicleType, setVehicleType] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [keyboardOpen, setKeyboardOpen] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const currentInputRef = useRef(null);
  const containerRef = useRef(null);
  const formRef = useRef(null);
  const [isSafariBrowser, setIsSafariBrowser] = useState(false);
  const [isIOSDevice, setIsIOSDevice] = useState(false);
  const [keyboardHeight, setKeyboardHeight] = useState(0);
  
  // Set up event listeners and initialize
  useEffect(() => {
    document.body.classList.add('car-search-open');
    setIsSafariBrowser(isSafari());
    setIsIOSDevice(isIOS());
    
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    window.addEventListener('resize', handleResize);
    
    const setViewportHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    
    setViewportHeight();
    window.addEventListener('resize', setViewportHeight);
    
    return () => {
      document.body.classList.remove('car-search-open');
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('resize', setViewportHeight);
    };
  }, []);
  
  // Handle iOS Safari specific adjustments
  useEffect(() => {
    if (isIOSDevice && isSafariBrowser) {
      document.body.classList.add('ios-safari');
      
      const metaViewport = document.querySelector('meta[name="viewport"]');
      if (metaViewport) {
        metaViewport.setAttribute('content', 
          'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no, viewport-fit=cover');
      }
      
      const visualViewport = window.visualViewport;
      if (visualViewport) {
        const handleViewportChange = () => {
          const windowHeight = window.innerHeight;
          const viewportHeight = visualViewport.height;
          const isKeyboardVisible = viewportHeight < windowHeight * 0.75;
          
          setKeyboardOpen(isKeyboardVisible);
          
          if (isKeyboardVisible) {
            const estimatedKeyboardHeight = windowHeight - viewportHeight;
            setKeyboardHeight(estimatedKeyboardHeight);
            document.documentElement.style.setProperty('--keyboard-height', `${estimatedKeyboardHeight}px`);
            
            setTimeout(() => {
              if (currentInputRef.current) {
                currentInputRef.current.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center'
                });
              }
            }, 300);
          } else {
            setKeyboardHeight(0);
            document.documentElement.style.setProperty('--keyboard-height', '0px');
          }
        };
        
        visualViewport.addEventListener('resize', handleViewportChange);
        visualViewport.addEventListener('scroll', handleViewportChange);
        
        return () => {
          document.body.classList.remove('ios-safari');
          visualViewport.removeEventListener('resize', handleViewportChange);
          visualViewport.removeEventListener('scroll', handleViewportChange);
          
          if (metaViewport) {
            metaViewport.setAttribute('content', 
              'width=device-width, initial-scale=1, viewport-fit=cover');
          }
        };
      }
    }
  }, [isIOSDevice, isSafariBrowser]);
  
  // Input focus handler
  const handleInputFocus = () => {
    setKeyboardOpen(true);
    
    if (isIOSDevice && isSafariBrowser) {
      setTimeout(() => {
        containerRef.current?.classList.add('ios-keyboard-open');
        
        const actionsElement = containerRef.current?.querySelector('.car-search-actions');
        if (actionsElement) {
          actionsElement.classList.add('keyboard-repositioned');
        }
        
        if (currentInputRef.current) {
          currentInputRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        }
      }, 150);
    } else if (isMobile) {
      setTimeout(() => {
        const actionsElement = containerRef.current?.querySelector('.car-search-actions');
        
        if (actionsElement) {
          actionsElement.classList.add('keyboard-repositioned');
          
          if (currentInputRef.current) {
            currentInputRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            });
            
            if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
              window.scrollTo(0, window.scrollY + 100);
            }
          }
        }
      }, 300);
    }
  };
  
  // Input blur handler
  const handleInputBlur = () => {
    if (isIOSDevice && isSafariBrowser) {
      setTimeout(() => {
        setKeyboardOpen(false);
        
        containerRef.current?.classList.remove('ios-keyboard-open');
        
        const actionsElement = containerRef.current?.querySelector('.car-search-actions');
        if (actionsElement) {
          actionsElement.classList.remove('keyboard-repositioned');
        }
        
        setKeyboardHeight(0);
        document.documentElement.style.setProperty('--keyboard-height', '0px');
      }, 300);
    } else if (isMobile) {
      setTimeout(() => {
        setKeyboardOpen(false);
        
        const actionsElement = containerRef.current?.querySelector('.car-search-actions');
        if (actionsElement && actionsElement.classList.contains('keyboard-repositioned')) {
          actionsElement.style.position = 'fixed';
          actionsElement.style.bottom = '0';
          actionsElement.style.top = 'auto';
          actionsElement.classList.remove('keyboard-repositioned');
        }
      }, 150);
    }
  };
  
  // Key press handler
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      
      if (isCurrentStepValid()) {
        if (isIOSDevice && isSafariBrowser && keyboardOpen) {
          currentInputRef.current?.blur();
          setTimeout(() => handleNext(), 300);
        } 
        else if (isMobile && keyboardOpen && currentInputRef.current) {
          currentInputRef.current.blur();
          setTimeout(() => handleNext(), 100);
        } else {
          handleNext();
        }
      }
    } else if (e.key === 'Escape') {
      onClose();
    }
  };
  
  // Auto-advance when an option is selected
  const handleOptionSelect = (setter, value) => {
    setter(value);
    
    // Skip the setTimeout for clientType selections (first step)
    if (setter === setClientType) {
      handleNext();
    } else if (setter === setRentalType) {
      // For rental type, immediately go to the additional info step
      setCurrentStep(2);
    } else {
      handleNext();
    }
  };
  
  // Navigation and validation
  const handleNext = () => {
    if (!isCurrentStepValid()) {
      return;
    }
    
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      handleSubmit();
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    } else {
      onClose();
    }
  };

  const handleSubmit = () => {
    onSubmit({
      clientType,
      rentalType,
      additionalInfo
      // Duration and vehicle type removed
    });
    
    onClose();
  };

  const isCurrentStepValid = () => {
    switch (currentStep) {
      case 0:
        return clientType !== '';
      case 1:
        return rentalType !== '';
      case 2: // Changed from 3 to 2 - the additional info step
        return true; // Optional field
      default:
        return false;
    }
  };
  
  // Direct chat handler
  const handleOpenDirectChat = (e) => {
    e.preventDefault();
    onClose();
    
    setTimeout(() => {
      if (typeof toggleChat === 'function') {
        toggleChat();
      }
    }, 100);
  };
  
  // Define steps
  const steps = [
    {
      title: "Per chi stai cercando il noleggio?",
      description: "Seleziona se stai cercando per un privato o per un'azienda.",
      input: (
        <div className="search-input-container">
          <div style={{ 
            display: 'grid', 
            gridTemplateColumns: 'repeat(2, 1fr)', 
            gap: '12px', 
            maxWidth: '440px', 
            margin: '0 auto' 
          }}>
            <div style={{ 
              height: '70px', 
              border: '1px solid #eee',
              borderRadius: '8px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
              backgroundColor: clientType === 'Privato' ? '#e6f7ff' : 'white',
              cursor: 'pointer'
            }} 
            onClick={() => {
              setClientType('Privato');
              setCurrentStep(1);
            }}>
              <div style={{ 
                width: '100%', 
                height: '100%', 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center',
                padding: '15px',
                boxSizing: 'border-box'
              }}>
                <input
                  type="radio"
                  name="clientType"
                  value="Privato"
                  checked={clientType === 'Privato'}
                  onChange={() => {}}
                  onFocus={handleInputFocus}
                  ref={clientType === '' ? currentInputRef : null}
                  style={{ position: 'absolute', opacity: 0 }}
                />
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <span style={{ fontSize: '24px', color: '#0066cc' }}>👤</span>
                  <span style={{ fontSize: '15px', fontWeight: 'bold' }}>Privato</span>
                </div>
              </div>
            </div>
            
            <div style={{ 
              height: '70px', 
              border: '1px solid #eee',
              borderRadius: '8px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
              backgroundColor: clientType === 'Azienda' ? '#fff7e6' : 'white',
              cursor: 'pointer'
            }} 
            onClick={() => {
              setClientType('Azienda');
              setCurrentStep(1);
            }}>
              <div style={{ 
                width: '100%', 
                height: '100%', 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center',
                padding: '15px',
                boxSizing: 'border-box'
              }}>
                <input
                  type="radio"
                  name="clientType"
                  value="Azienda"
                  checked={clientType === 'Azienda'}
                  onChange={() => {}}
                  onFocus={handleInputFocus}
                  style={{ position: 'absolute', opacity: 0 }}
                />
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <span style={{ fontSize: '24px', color: '#fa8c16' }}>🏢</span>
                  <span style={{ fontSize: '15px', fontWeight: 'bold' }}>Azienda</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    },
    {
      title: () => {
        switch(clientType) {
          case 'Privato':
            return "Che tipo di noleggio cerchi?";
          case 'Azienda':
            return "Che tipo di noleggio aziendale cerchi?";
          default:
            return "Che tipo di noleggio cerchi?";
        }
      },
      description: () => {
        switch(clientType) {
          case 'Privato':
            return "Seleziona il tipo di noleggio che ti interessa.";
          case 'Azienda':
            return "Seleziona il tipo di noleggio aziendale che interessa alla tua azienda.";
          default:
            return "Seleziona il tipo di noleggio che ti interessa.";
        }
      },
      input: (
        <div className="search-input-container">
          <div className="fuel-type-grid" role="radiogroup" aria-label="Tipo di noleggio" style={{ 
            maxWidth: '440px',
            margin: '0 auto',
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gridTemplateRows: 'auto auto',
            gridGap: '10px',
            gridTemplateAreas: 
              `"breve lungo"
               "info info"`
          }}>
            <label 
              className={`fuel-type-option ${rentalType === 'Breve termine' ? 'selected' : ''}`}
              onClick={() => handleOptionSelect(setRentalType, 'Breve termine')}
              style={{ 
                padding: '12px 10px',
                minHeight: '55px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: rentalType === 'Breve termine' ? (clientType === 'Privato' ? '#e6f7ff' : '#fff7e6') : null,
                border: '1px solid #eee',
                borderRadius: '8px',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
                gridArea: 'breve'
              }}
            >
              <input
                type="radio"
                name="rentalType"
                value="Breve termine"
                checked={rentalType === 'Breve termine'}
                onChange={() => {}}
                onFocus={handleInputFocus}
                ref={rentalType === '' ? currentInputRef : null}
              />
              <div className="fuel-type-content" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px', padding: '0' }}>
                <span className="fuel-type-icon" style={{ fontSize: '20px', color: clientType === 'Privato' ? '#0066cc' : '#fa8c16' }}>🕒</span>
                <span className="fuel-type-label" style={{ fontSize: '14px', fontWeight: 'bold' }}>Breve termine</span>
              </div>
            </label>
            <label 
              className={`fuel-type-option ${rentalType === 'Lungo termine' ? 'selected' : ''}`}
              onClick={() => handleOptionSelect(setRentalType, 'Lungo termine')}
              style={{ 
                padding: '12px 10px',
                minHeight: '55px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: rentalType === 'Lungo termine' ? (clientType === 'Privato' ? '#e6f7ff' : '#fff7e6') : null,
                border: '1px solid #eee',
                borderRadius: '8px',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
                gridArea: 'lungo'
              }}
            >
              <input
                type="radio"
                name="rentalType"
                value="Lungo termine"
                checked={rentalType === 'Lungo termine'}
                onChange={() => {}}
                onFocus={handleInputFocus}
              />
              <div className="fuel-type-content" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px', padding: '0' }}>
                <span className="fuel-type-icon" style={{ fontSize: '20px', color: clientType === 'Privato' ? '#0066cc' : '#fa8c16' }}>📅</span>
                <span className="fuel-type-label" style={{ fontSize: '14px', fontWeight: 'bold' }}>Lungo termine</span>
              </div>
            </label>
            <label 
              className={`fuel-type-option ${rentalType === 'Informazioni generali' ? 'selected' : ''}`}
              onClick={() => handleOptionSelect(setRentalType, 'Informazioni generali')}
              style={{ 
                padding: '12px 10px',
                minHeight: '55px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: rentalType === 'Informazioni generali' ? (clientType === 'Privato' ? '#e6f7ff' : '#fff7e6') : null,
                border: '1px solid #eee',
                borderRadius: '8px',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
                gridArea: 'info'
              }}
            >
              <input
                type="radio"
                name="rentalType"
                value="Informazioni generali"
                checked={rentalType === 'Informazioni generali'}
                onChange={() => {}}
                onFocus={handleInputFocus}
              />
              <div className="fuel-type-content" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px', padding: '0' }}>
                <span className="fuel-type-icon" style={{ fontSize: '20px', color: clientType === 'Privato' ? '#0066cc' : '#fa8c16' }}>ℹ️</span>
                <span className="fuel-type-label" style={{ fontSize: '14px', fontWeight: 'bold' }}>Info generali</span>
              </div>
            </label>
          </div>
        </div>
      )
    },
    {
      title: "Dettagli aggiuntivi (opzionale)",
      description: "Fornisci ulteriori dettagli sulle tue esigenze di noleggio.",
      input: (
        <div className="textarea-container" style={{ maxWidth: '660px', margin: '0 auto' }}>
          <textarea
            value={additionalInfo}
            onChange={(e) => setAdditionalInfo(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && e.ctrlKey) {
                e.preventDefault();
                handleNext();
              }
            }}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            placeholder="Es. Cerco un'auto per un viaggio in famiglia, 5 persone, con ampio bagagliaio. Avrei bisogno di ritirarla il 15/07..."
            className="search-textarea"
            maxLength={500}
            ref={currentInputRef}
            aria-label="Dettagli aggiuntivi"
            style={{ 
              width: '100%', 
              padding: '12px',
              border: '1px solid #ddd',
              borderRadius: '8px',
              minHeight: '100px',
              height: '100px',
              fontSize: '14px',
              resize: 'none'
            }}
          />
          <div className="textarea-hint" style={{ fontSize: '12px', color: '#666', marginTop: '4px', textAlign: 'right' }}>Premi Ctrl+Enter per inviare</div>
        </div>
      )
    }
  ];

  const currentStepData = steps[currentStep];
  // Get dynamic title and description if they're functions
  const title = typeof currentStepData.title === 'function' ? currentStepData.title() : currentStepData.title;
  const description = typeof currentStepData.description === 'function' ? currentStepData.description() : currentStepData.description;

  // Stop propagation of clicks to prevent closing the modal
  const handleContainerClick = (e) => {
    e.stopPropagation();
  };

  // Prepare content to be rendered
  const content = (
    <div 
      className={`car-search-container ${keyboardOpen ? 'keyboard-open' : ''} ${isTransitioning ? 'transitioning' : ''} ${isMobile ? 'mobile-fixed-position' : ''} ${isSafariBrowser ? 'safari-browser' : ''} ${isIOSDevice ? 'ios-device' : ''}`}
      ref={containerRef}
      onClick={handleContainerClick}
      data-step={currentStep}
      style={{
        maxWidth: '700px',
        maxHeight: '600px',
        margin: '0 auto',
        padding: '10px 0',
        overflow: 'auto',
        ...(keyboardOpen && isIOSDevice ? { paddingBottom: `${keyboardHeight}px` } : {})
      }}
    >
      <CloseButton onClose={onClose} />
      <WelcomeMessage />
      <Header currentStep={currentStep} totalSteps={steps.length} />
      
      <form 
        className="car-search-form" 
        onSubmit={(e) => { 
          e.preventDefault(); 
          if ((isMobile || isIOSDevice) && keyboardOpen && currentInputRef.current) {
            currentInputRef.current.blur();
            setTimeout(() => handleNext(), isIOSDevice ? 300 : 100);
          } else {
            handleNext();
          }
        }}
        ref={formRef}
      >
        <div className="car-search-content" style={{ padding: '0 15px', marginTop: '0' }}>
          <h2 className="car-search-title" id="rental-search-title" style={{ fontSize: '16px', marginBottom: '3px', marginTop: '0' }}>{title}</h2>
          <p className="car-search-description" style={{ fontSize: '13px', margin: '0 0 10px 0' }}>{description}</p>
          <div className="car-search-input-wrapper">
            {currentStepData.input}
            
            <div className={`car-search-actions ${isMobile || isIOSDevice ? 'mobile-actions' : 'desktop-actions'}`} style={{ padding: '10px 0', marginTop: '5px' }}>
              {currentStep > 0 && (
              <button 
                type="button" 
                className="car-search-back" 
                onClick={handleBack}
                style={{ 
                  padding: '6px 12px', 
                  fontSize: '13px',
                  backgroundColor: 'transparent',
                  border: '1px solid #ddd',
                  borderRadius: '4px',
                  color: '#666'
                }}
              >
                <span>Indietro</span>
              </button>
              )}
              
              {/* Add submit button only on the last step */}
              {currentStep === steps.length - 1 && (
                <button 
                  type="submit" 
                  className="car-search-submit" 
                  onClick={handleNext}
                  style={{ 
                    padding: '8px 15px', 
                    fontSize: '14px', 
                    backgroundColor: clientType === 'Privato' ? '#0066cc' : '#fa8c16', 
                    color: 'white', 
                    border: 'none', 
                    borderRadius: '4px', 
                    marginLeft: '10px' 
                  }}
                >
                  <span>Trova soluzioni</span>
                </button>
              )}
            </div>
            
            {/* Improve direct chat button */}
            {currentStep === 0 && (
              <div className="car-search-direct-chat" style={{ marginTop: '20px' }}>
                <button 
                  type="button" 
                  onClick={handleOpenDirectChat} 
                  className="direct-chat-button"
                  style={{
                    padding: '12px',
                    border: '1px solid #eee',
                    borderRadius: '8px',
                    backgroundColor: '#f0f9ff',
                    width: '100%',
                    textAlign: 'left',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                    borderLeft: '4px solid #0066cc'
                  }}
                >
                  <span className="direct-chat-button-title" style={{ display: 'block', fontWeight: 'bold', fontSize: '15px', marginBottom: '4px' }}>Parla con un consulente</span>
                  <span className="direct-chat-button-subtitle" style={{ display: 'block', fontSize: '13px', color: '#333' }}>
                     Chiedi informazioni o organizza un appuntamento con il nostro assistente → 
                  </span>
                </button>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );

  // Render either in a portal or directly
  return usePortal ? (
    <ModalContainer>
      {content}
    </ModalContainer>
  ) : (
    <div 
      className={`car-search-overlay ${isSafariBrowser ? 'safari-browser' : ''} ${isIOSDevice ? 'ios-device' : ''}`} 
      onClick={onClose}
      aria-modal="true"
      role="dialog"
      aria-labelledby="rental-search-title"
    >
      {content}
    </div>
  );
};

export default RentalSearch; 