import React, { useState, useCallback } from 'react';
import { Box, Chip, IconButton, Typography, useMediaQuery, Grid } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos, Fullscreen } from '@mui/icons-material';
import { motion, AnimatePresence } from 'framer-motion';
import ProgressiveImage from './ProgressiveImage'; // Import the ProgressiveImage component

const formatPrice = (price) => {
  return parseFloat(price).toLocaleString('it-IT', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

const ImageGallery = ({
  images = [],
  onError,
  retailPrice,
  salePrice,
  onFullScreen,
  isDesktop,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isZoomed, setIsZoomed] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const actualIsDesktop = isDesktop !== undefined ? isDesktop : !isMobile;

  const handlePrev = useCallback(() => {
    if (!images.length) return;

    setCurrentIndex((prev) => {
      if (isMobile) {
        return prev === 0 ? images.length - 1 : prev - 1;
      } else {
        return prev === 0 ? images.length - 1 : prev - 1;
      }
    });
  }, [images.length, isMobile]);

  const handleNext = useCallback(() => {
    if (!images.length) return;

    setCurrentIndex((prev) => {
      if (isMobile) {
        return prev === images.length - 1 ? 0 : prev + 1;
      } else {
        return prev === images.length - 1 ? 0 : prev + 1;
      }
    });
  }, [images.length, isMobile]);

  const handleThumbnailClick = (index) => {
    setCurrentIndex(index);
  };

  const handleImageClick = () => {
    setIsZoomed(!isZoomed);
  };

  if (!images.length) {
    return null;
  }

  // Desktop layout with main image and thumbnails
  if (actualIsDesktop) {
    return (
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          p: 2,
        }}
      >
        {/* Main Image */}
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: 'calc(100% - 100px)',
            mb: 2,
            borderRadius: '12px',
            overflow: 'hidden',
            boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
          }}
        >
          <AnimatePresence mode="wait">
            <Box
              key={currentIndex}
              component={motion.div}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              sx={{
                width: '100%',
                height: '100%',
                position: 'relative',
              }}
            >
              <ProgressiveImage
                src={images[currentIndex]}
                alt={`Image ${currentIndex + 1}`}
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                onError={onError}
                onClick={handleImageClick}
              />
              
              {/* Fullscreen button */}
              <IconButton
                sx={{
                  position: 'absolute',
                  bottom: 16,
                  right: 16,
                  backgroundColor: 'rgba(0, 0, 0, 0.6)',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                  },
                }}
                onClick={() => onFullScreen?.(images[currentIndex])}
              >
                <Fullscreen />
              </IconButton>

              {/* Price display */}
              {salePrice && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 16,
                    left: 16,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    zIndex: 1,
                  }}
                >
                  {retailPrice > 0 && retailPrice > salePrice && (
                    <Typography
                      variant="body2"
                      sx={{
                        textDecoration: 'line-through',
                        mb: 0.5,
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        padding: '2px 6px',
                        borderRadius: '4px',
                      }}
                    >
                      {`${formatPrice(retailPrice)} €`}
                    </Typography>
                  )}
                  <Chip
                    label={`${formatPrice(salePrice)} €`}
                    color="secondary"
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '1.2rem',
                      padding: '16px 8px',
                    }}
                  />
                </Box>
              )}
            </Box>
          </AnimatePresence>

          {/* Navigation arrows */}
          <IconButton
            onClick={handlePrev}
            sx={{
              position: 'absolute',
              left: '10px',
              top: '50%',
              transform: 'translateY(-50%)',
              color: 'white',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.7)' },
              zIndex: 2,
            }}
          >
            <ArrowBackIos />
          </IconButton>
          <IconButton
            onClick={handleNext}
            sx={{
              position: 'absolute',
              right: '10px',
              top: '50%',
              transform: 'translateY(-50%)',
              color: 'white',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.7)' },
              zIndex: 2,
            }}
          >
            <ArrowForwardIos />
          </IconButton>
        </Box>

        {/* Thumbnails */}
        <Grid container spacing={1} sx={{ height: '90px' }}>
          {images.map((imgSrc, idx) => (
            <Grid item xs={2} key={idx}>
              <Box
                component={motion.div}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                sx={{
                  width: '100%',
                  height: '80px',
                  borderRadius: '8px',
                  overflow: 'hidden',
                  cursor: 'pointer',
                  border: idx === currentIndex ? '3px solid #2196f3' : '3px solid transparent',
                  opacity: idx === currentIndex ? 1 : 0.7,
                  transition: 'all 0.2s ease',
                  '&:hover': {
                    opacity: 1,
                  },
                }}
                onClick={() => handleThumbnailClick(idx)}
              >
                <ProgressiveImage
                  src={imgSrc}
                  alt={`Thumbnail ${idx + 1}`}
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  onError={onError}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  }

  // Mobile layout (existing)
  const visibleImages = isMobile
    ? [images[currentIndex]]
    : images.slice(currentIndex, currentIndex + 3).concat(
        images.slice(0, Math.max(0, currentIndex + 3 - images.length))
      );

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        minHeight: '400px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        backgroundColor: '#f5f5f5',
      }}
    >
      <AnimatePresence mode="wait">
        <Box
          key={currentIndex}
          component={motion.div}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            width: '100%',
            height: '100%',
            position: 'relative',
          }}
        >
          {visibleImages.map((imgSrc, idx) => (
            <Box
              key={idx}
              sx={{
                flex: 1,
                position: 'relative',
                overflow: 'hidden',
              }}
            >
              <motion.div
                initial={{ scale: 1 }}
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
                style={{ width: '100%', height: '100%' }}
              >
                <ProgressiveImage
                  src={imgSrc}
                  alt={`Image ${currentIndex + idx + 1}`}
                  style={{ width: '100%', height: '100%' }}
                  onError={onError}
                  onClick={handleImageClick}
                />
              </motion.div>
              
              {/* Fullscreen button */}
              <IconButton
                sx={{
                  position: 'absolute',
                  bottom: 16,
                  right: 16,
                  backgroundColor: 'rgba(0, 0, 0, 0.6)',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                  },
                }}
                onClick={() => onFullScreen?.(imgSrc)}
              >
                <Fullscreen />
              </IconButton>
              
              {idx === 0 && salePrice && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 16,
                    left: { xs: 16, md: 24 },
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    zIndex: 1,
                  }}
                >
                  {retailPrice > 0 && retailPrice > salePrice && (
                    <Typography
                      variant="body2"
                      sx={{
                        textDecoration: 'line-through',
                        mb: 0.5,
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        padding: '2px 6px',
                        borderRadius: '4px',
                      }}
                    >
                      {`${formatPrice(retailPrice)} €`}
                    </Typography>
                  )}
                  <Chip
                    label={`${formatPrice(salePrice)} €`}
                    color="secondary"
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '1.2rem',
                      padding: '16px 8px',
                    }}
                  />
                </Box>
              )}
            </Box>
          ))}
        </Box>
      </AnimatePresence>

      {images.length > (isMobile ? 1 : 3) && (
        <>
          <IconButton
            onClick={handlePrev}
            sx={{
              position: 'absolute',
              left: '10px',
              top: '50%',
              transform: 'translateY(-50%)',
              color: 'white',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.7)' },
              zIndex: 2,
            }}
          >
            <ArrowBackIos />
          </IconButton>
          <IconButton
            onClick={handleNext}
            sx={{
              position: 'absolute',
              right: '10px',
              top: '50%',
              transform: 'translateY(-50%)',
              color: 'white',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.7)' },
              zIndex: 2,
            }}
          >
            <ArrowForwardIos />
          </IconButton>
        </>
      )}
    </Box>
  );
};

export default ImageGallery;
