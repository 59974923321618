/**
 * Utility functions for detecting URL patterns to determine appropriate chat CTAs
 */

/**
 * Normalizes a URL by converting to lowercase and removing trailing slashes and query parameters
 * @param {string} url - The URL to normalize
 * @returns {string} - The normalized URL
 */
export const normalizeUrl = (url) => {
  if (!url) return '';
  
  try {
    // Create a URL object to handle parsing
    let urlObj;
    try {
      // Try to parse as absolute URL
      urlObj = new URL(url);
    } catch (e) {
      // If that fails, try as relative URL
      urlObj = new URL(url, window.location.origin);
    }
    
    // Get the pathname (this automatically handles parsing)
    let normalized = urlObj.pathname;
    
    // Remove trailing slashes
    normalized = normalized.replace(/\/+$/, '');
    
    // Convert to lowercase for case-insensitive matching
    normalized = normalized.toLowerCase();
    
    console.log('Normalized URL path:', normalized);
    
    return normalized;
  } catch (e) {
    // Fallback to basic string processing if URL parsing fails
    console.warn('URL parsing failed, using basic normalization', e);
    
    // Remove protocol (http://, https://)
    let normalized = url.replace(/^(https?:\/\/)/, '');
    
    // Remove domain if present (for relative URLs this won't change anything)
    normalized = normalized.replace(/^[^/]+/, '');
    
    // Remove query parameters
    normalized = normalized.split('?')[0];
    
    // Remove hash fragments
    normalized = normalized.split('#')[0];
    
    // Remove trailing slashes
    normalized = normalized.replace(/\/+$/, '');
    
    // Convert to lowercase for case-insensitive matching
    normalized = normalized.toLowerCase();
    
    console.log('Fallback normalized URL path:', normalized);
    
    return normalized;
  }
};

/**
 * Returns the path segments from a URL
 * @param {string} url - The URL to extract path segments from
 * @returns {Array} - Array of path segments
 */
export const getPathSegments = (url) => {
  const normalized = normalizeUrl(url);
  return normalized.split('/').filter(segment => segment.length > 0);
};

/**
 * Determines the CTA type based on the current URL
 * @param {string} url - The current URL
 * @returns {string} - The CTA type to display
 */
export const getCTATypeFromUrl = (url) => {
  const normalized = normalizeUrl(url);
  console.log('Checking CTA type for normalized URL:', normalized);
  
  // Array of patterns for each CTA type
  const patterns = {
    CAR_SEARCH: ['ricerca', 'gamma', 'modello', 'auto', 'veicoli'],
    PROMOTIONS: ['promozioni', 'offerte', 'sconti', 'promo'],
    SERVICE: ['servizi-officina', 'assistenza', 'manutenzione', 'officina', 'riparazione', 'servizi'],
    RENTAL: ['noleggio', 'rent', 'affitto-auto', 'affitto']
  };
  
  // Check each pattern group
  for (const [ctaType, patternList] of Object.entries(patterns)) {
    for (const pattern of patternList) {
      if (normalized.includes(pattern)) {
        console.log(`URL matched pattern "${pattern}" for CTA type: ${ctaType}`);
        return ctaType;
      }
    }
  }
  
  // If the path has only a single slash, it's the homepage
  if (normalized === '' || normalized === '/') {
    console.log('URL matched homepage pattern for DEFAULT CTA');
    return 'DEFAULT';
  }
  
  // No patterns matched
  console.log('No patterns matched, using DEFAULT CTA');
  return 'DEFAULT';
};

/**
 * Gets the appropriate CTA text and icon based on the CTA type
 * @param {string} ctaType - The CTA type
 * @returns {Object} - Object containing cta text and icon info
 */
export const getCTAConfig = (ctaType) => {
  switch (ctaType) {
    case 'CAR_SEARCH':
      return {
        text: 'Cerca un\'auto',
        icon: 'search',
        searchComponent: 'MobileCarSearch'
      };
    case 'PROMOTIONS':
      return {
        text: 'Scopri le offerte',
        icon: 'tag',
        searchComponent: 'PromotionsSearch'
      };
    case 'SERVICE':
      return {
        text: 'Servizi e assistenza',
        icon: 'tool',
        searchComponent: 'ServiceSearch'
      };
    case 'RENTAL':
      return {
        text: 'Noleggio auto',
        icon: 'calendar',
        searchComponent: 'RentalSearch'
      };
    case 'DEFAULT':
    default:
      return {
        text: 'Chatta con noi',
        icon: 'message-circle',
        searchComponent: 'DefaultChat'
      };
  }
}; 