import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";

function initReactApp() {
  let chatRoot = document.getElementById("chat-root");

  // If chat-root doesn't exist, create it
  if (!chatRoot) {
    chatRoot = document.createElement("div");
    chatRoot.id = "chat-root";
    
    // Only add these specific styles to fix the white space issue
    chatRoot.style.position = "fixed";
    chatRoot.style.bottom = "0";
    chatRoot.style.right = "0";
    chatRoot.style.zIndex = "9999";
    // Don't set height or maxHeight at all
    
    document.body.appendChild(chatRoot);
  } else {
    // If the element already exists, make sure it has the correct position
    chatRoot.style.position = "fixed";
    chatRoot.style.height = "auto"; // Override any existing height: 100vh
  }

  const root = createRoot(chatRoot);
  root.render(
      <App />
  );
}

// For local development
if (process.env.NODE_ENV === "development") {
  initReactApp();
}

// For production (used by chat-loader.js)
//@ts-ignore
window.initReactApp = initReactApp;

/*
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

..........
import React from 'react';
import App from './App';  
import { createRoot } from 'react-dom/client';

const root = createRoot(document.getElementById('chat-root'));
root.render(<App />);
.........
const renderChatWidget = (elementId) => {
  const targetElement = document.getElementById(elementId);

  if (targetElement) {
    const root = ReactDOM.createRoot(targetElement);
    root.render(<App />);
  } else {
    console.error(`Element with ID ${elementId} not found!`);
  }
};

// Expose the renderChatWidget function to the global window object
window.renderChatWidget = renderChatWidget;
*/
