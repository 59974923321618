import {
  Box,
  Fade,
  Grow,
  Typography,
  useMediaQuery,
  useTheme,
  Tooltip,
  Dialog,
  IconButton,
} from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState, useMemo, useRef } from "react";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchIcon from '@mui/icons-material/Search';
import styled from 'styled-components';
import { useTheme as useMuiTheme } from '@mui/material/styles';

import NewToggleChat from "./NewToggleChat";
import { ChatBody } from "./New Chatbody";
import ConnectionStatus from "./ConnectionStatus";
import { useWebSocketContext } from "../context/Theme";
import CustomScrollbar from './CustomScrollbar';
import SearchPanel from "./SearchPanel";

/**
 * Splits out any messages that appear to be "car-search" related.
 * Only do this when NOT on mobile. Otherwise keep them all in chat.
 *
 * If a message has both text + carouselItems, we split it into:
 *   1) A text-only message for the main chat
 *   2) A carousel-only message for the SearchPanel
 */
function partitionMessages(messages, isMobile) {
  if (isMobile) {
    // No partition for mobile. All messages go to chat.
    return { 
      chatMessages: messages, 
      searchResults: [] 
    };
  }

  const chatMessages = [];
  const searchResults = [];

  messages.forEach((msg, idx) => {
    const isCarSearchTool = Array.isArray(msg.tool)
      ? msg.tool.some((t) => t.includes("car_stock_search"))
      : typeof msg.tool === "string" && msg.tool.includes("car_stock_search");

    const hasCarousel = msg.carouselItems && msg.carouselItems.length > 0;

    // Only consider it a search result if it has actual carousel items
    if (hasCarousel) {
      if (msg.text) {
        // (A) Chat receives a text-only copy
        chatMessages.push({ 
          ...msg, 
          carouselItems: null,
          originalIndex: idx
        });
      }
      
      // (B) Search results get a carousel-only copy
      searchResults.push({ 
        ...msg, 
        text: null,
        originalIndex: idx
      });
    } else {
      // All other messages go to chat
      chatMessages.push(msg);
    }
  });

  return { chatMessages, searchResults };
}

// Update the CollapseHandle styled component
const CollapseHandle = styled(motion.div)`
  position: fixed;
  right: ${props => props.isOpen ? "calc(min(800px, 95vw) + 2px)" : "calc(min(500px, 90vw) + 2px)"};
  bottom: 45vh;
  background: ${props => props.$bgcolor};
  padding: 12px 4px;
  border-radius: 4px;
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 998;
  transition: right 0.3s ease;

  &:hover {
    background: ${props => props.$bghover};
  }
`;

// Update the ChatAndSearchContainer with proper mobile car search styles
const ChatAndSearchContainer = styled(motion.div)`
  display: flex;
  position: fixed;
  bottom: ${props => props.isMobile ? 0 : '2vh'};
  right: ${props => props.isMobile ? 0 : '2vw'};
  height: ${props => {
    if (props.isMobile) {
      // Return 50% height when opened by trigger or in compact mode
      return props.openedByTrigger || props.isCompactMode ? '50%' : '100%';
    }
    return '90vh';
  }};
  top: ${props => {
    if (props.isMobile) {
      // Adjust top position based on trigger or compact mode
      return props.openedByTrigger || props.isCompactMode ? 'auto' : 0;
    }
    return 'auto';
  }};
  width: ${props => props.isMobile ? '100vw' : 'auto'};
  perspective: 2000px;
  z-index: 1000;
  justify-content: ${props => props.isMobile ? 'center' : 'flex-start'};
  flex-direction: row-reverse;
  transition: opacity 0.3s ease; /* Add transition for opacity */
  opacity: 1; /* Default opacity */
  pointer-events: auto; /* Default pointer events */
  
  /* Ensure mobile car search displays correctly on top of everything */
  .car-search-overlay {
    z-index: 2000;
  }
  
  .car-search-container {
    z-index: 2001;
  }
  
  /* Fixed buttons at bottom of screen */
  .car-search-actions {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2002;
    width: 100%;
    background: white;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    padding: 16px;
    display: flex;
    justify-content: space-between;
  }

  /* Styles for when page is scrolling underneath reduced chat */
  &.page-scrolling {
    opacity: 0.4;
    
    /* Keep the footer fully visible and opaque - Enhanced styling */
    .chat-footer.reduced-mode {
      opacity: 1 !important;
      visibility: visible !important;
      pointer-events: auto !important;
      background-color: #ffffff !important;
      box-shadow: 0 -4px 12px rgba(0, 0, 0, 0.25) !important;
      border-top: 2px solid #2e7d32 !important;
      z-index: 2000 !important;
      position: fixed !important;
      bottom: 0 !important;
      left: 0 !important;
      right: 0 !important;
      width: 100% !important;
    }
    
    /* Enhanced CTA styling in transparent mode */
    .message-input-container.cta-active {
      background-color: rgba(76, 175, 80, 0.25) !important;
      border-color: #2e7d32 !important;
      box-shadow: 0 4px 12px rgba(46, 125, 50, 0.4) !important;
    }
  }
`;

// Update the ChatContainer to handle car search properly
const ChatContainer = styled(motion.div)`
  width: ${props => props.isMobile ? '100%' : 'min(500px, 90vw)'};
  height: 100%;
  position: relative;
  z-index: 2;
  flex-shrink: 0;

  ${props => props.isMobile && `
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    margin-top: 0;
  `}

  // Add a subtle background gradient for depth
  background: linear-gradient(to right, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.8));
  
  /* Add styles to handle car search overlay visibility */
  &.car-search-active {
    z-index: 1;
  }
`;

const SearchPanelContainer = styled(motion.div)`
  position: relative;
  height: 100%;
  transform-origin: right center;
  z-index: 1;
  display: ${props => props.isMobile ? 'none' : 'block'};
  flex-shrink: 0;
`;

// Replace the SemicircleButton with an enhanced version
const SemicircleButton = styled(motion.button)`
  position: fixed;
  right: calc(min(500px, 90vw) - 1px);
  top: calc(45vh - 25px);
  width: 40px;
  height: 50px;
  background: #2196f3;
  color: white;
  border: none;
  border-radius: 25px 0 0 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: -3px 0 10px rgba(0, 0, 0, 0.2);
  z-index: 999;
  transition: all 0.3s ease;
  padding-right: 8px;
  overflow: visible;
  animation: pulseButton 2s infinite ease-in-out;

  @keyframes pulseButton {
    0% {
      box-shadow: -3px 0 10px rgba(0, 0, 0, 0.2);
    }
    50% {
      box-shadow: -3px 0 15px rgba(33, 150, 243, 0.6);
    }
    100% {
      box-shadow: -3px 0 10px rgba(0, 0, 0, 0.2);
    }
  }

  &:hover {
    background: #1976d2;
    width: 50px;
    box-shadow: -5px 0 15px rgba(0, 0, 0, 0.3);
    animation: none;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 1px;
    background: rgba(255, 255, 255, 0.3);
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ResultBadge = styled(motion.div)`
  position: absolute;
  top: -8px;
  left: -8px;
  background-color: #ff5722;
  color: white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  border: 2px solid white;
`;

const ChatWrapper = () => {
  const {
    isAuthenticated,
    messages,
    isLoading,
    setIsLoading,
    wishlistItems,
    error,
    isReconnecting,
    reconnectCountdown,
    reconnectAttempts,
    MAX_RECONNECT_ATTEMPTS,
    handleManualReconnect,
    handleSend,
    handleResendLastMessage,
    handleWishlistUpdate,
    isHumanControlled,
    humanControlMessage,
    isConnected,
    accessToken,
    isChatVisible,
    toggleChat,
    notification,
    activeTrigger,
    openedByTrigger,
    setOpenedByTrigger,
  } = useWebSocketContext();

  // If for some reason openedByTrigger is undefined in the context, initialize it locally
  const [localOpenedByTrigger, setLocalOpenedByTrigger] = useState(false);
  
  // Use either the context value or local state as a fallback
  const effectiveOpenedByTrigger = openedByTrigger !== undefined ? openedByTrigger : localOpenedByTrigger;
  const effectiveSetOpenedByTrigger = setOpenedByTrigger || setLocalOpenedByTrigger;

  const [isChatWide, setChatWide] = useState(false);
  const [searchPanelOpen, setSearchPanelOpen] = useState(false);
  const [showReconnectionUI, setShowReconnectionUI] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [isCarSearchActive, setIsCarSearchActive] = useState(false);
  
  // Add state to track compact mode for mobile
  const [isCompactMode, setIsCompactMode] = useState(false);
  
  // Renamed state for clarity
  const [showTransparencyEffect, setShowTransparencyEffect] = useState(false);
  const scrollTimeoutRef = useRef(null); // Ref for scroll timeout

  // We track the "active" search index => defaults to the LAST search result
  const [currentSearchIndex, setCurrentSearchIndex] = useState(0);
  const [lastSearchResultsLength, setLastSearchResultsLength] = useState(0);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // We'll create a ref to ChatBody so we can scroll to a particular message
  const chatBodyRef = useRef(null);

  // Add a ref to track the last known carousel content
  const lastCarouselContentRef = useRef(null);

  // Add ref to track previous message count
  const prevMessageCountRef = useRef(0);

  const [dialogOpen, setDialogOpen] = useState(false);

  const toggleChatWidth = () => setChatWide(!isChatWide);

  const toggleSearchPanel = () => {
    if (!isMobile) {
      setSearchPanelOpen((open) => !open);
    }
  };
  
  // Add callback function to track car search state
  const handleCarSearchStateChange = (isOpen) => {
    setIsCarSearchActive(isOpen);
    if (isOpen) {
      // When car search opens, ensure the chat container doesn't interfere
      document.body.style.overflow = 'hidden';
      const chatContainer = document.querySelector('.chat-container');
      if (chatContainer) {
        chatContainer.classList.add('car-search-active');
      }
    } else {
      // When car search closes, restore normal state
      document.body.style.overflow = '';
      const chatContainer = document.querySelector('.chat-container');
      if (chatContainer) {
        chatContainer.classList.remove('car-search-active');
      }
    }
  };

  // Effect to handle body scroll based on chat visibility and mode
  useEffect(() => {
    const isReducedMobile = isMobile && isChatVisible && (isCompactMode || effectiveOpenedByTrigger);
    const isFullScreenMobile = isMobile && isChatVisible && !isCompactMode && !effectiveOpenedByTrigger;

    if (isFullScreenMobile) {
      document.body.style.overflow = 'hidden';
    } else {
      // Allow scrolling if chat is hidden, on desktop, or reduced on mobile
      document.body.style.overflow = 'auto';
    }

    // Cleanup function
    return () => {
      // Ensure scroll is re-enabled when component unmounts or state changes
      document.body.style.overflow = 'auto';
    };
  }, [isMobile, isChatVisible, isCompactMode, effectiveOpenedByTrigger]);

  // Effect to handle page scroll detection for transparency
  useEffect(() => {
    // Renamed handler for clarity
    const handlePageScroll = () => {
      const isReducedMobile = isMobile && isChatVisible && (isCompactMode || effectiveOpenedByTrigger);
      
      if (isReducedMobile) {
        // Make chat transparent
        setShowTransparencyEffect(true);
        
        // Clear existing timeout - REMOVING auto reset of transparency
        if (scrollTimeoutRef.current) {
          clearTimeout(scrollTimeoutRef.current);
          scrollTimeoutRef.current = null;
        }
        
        // We no longer automatically reset transparency after time
        // The transparency will only be removed when the user explicitly interacts with the chat
      } else {
        // If not in reduced mobile state, ensure transparency is off
        setShowTransparencyEffect(false);
        if (scrollTimeoutRef.current) {
          clearTimeout(scrollTimeoutRef.current);
          scrollTimeoutRef.current = null;
        }
      }
    };

    // Only listen for scroll events now
    window.addEventListener('scroll', handlePageScroll, { passive: true });

    // Cleanup function
    return () => {
      window.removeEventListener('scroll', handlePageScroll);
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
      }
    };
  }, [isMobile, isChatVisible, isCompactMode, effectiveOpenedByTrigger]);

  // New effect to handle clicks outside the chat
  useEffect(() => {
    const handleDocumentClick = (e) => {
      // Only apply effect if we're in the reduced mobile state
      const isReducedMobile = isMobile && isChatVisible && (isCompactMode || effectiveOpenedByTrigger);
      if (!isReducedMobile) return;

      // Check if the click is outside the chat container
      const chatContainer = document.querySelector('.ChatAndSearchContainer');
      if (chatContainer && !chatContainer.contains(e.target)) {
        // Make chat transparent when clicking outside
        setShowTransparencyEffect(true);
        
        // Clear existing timeout - REMOVING auto reset of transparency
        if (scrollTimeoutRef.current) {
          clearTimeout(scrollTimeoutRef.current);
          scrollTimeoutRef.current = null;
        }
        
        // We no longer automatically reset transparency after time
        // The transparency will only be removed when the user explicitly interacts with the chat
      }
    };

    // Add click listener to the document
    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [isMobile, isChatVisible, isCompactMode, effectiveOpenedByTrigger]);

  useEffect(() => {
    let timer;
    if (!isConnected) {
      timer = setTimeout(() => {
        setShowReconnectionUI(true);
      }, 3000);
    } else {
      setShowReconnectionUI(false);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [isConnected]);

  useEffect(() => {
    if (isHumanControlled && isLoading) {
      setIsLoading(false);
    }
  }, [isHumanControlled, isLoading, setIsLoading]);

  useEffect(() => {
    // Close search panel when chat is closed
    if (!isChatVisible) {
      setSearchPanelOpen(false);
    }
  }, [isChatVisible]);

  const handleToggleChat = (forceOpenedByTriggerValue) => {
    if (!isChatVisible) {
      setSearchPanelOpen(false);
      
      // When manually toggling, ensure full screen on mobile (not compact)
      if (isMobile) {
        // Check if we have an explicit value for openedByTrigger
        if (forceOpenedByTriggerValue !== undefined) {
          effectiveSetOpenedByTrigger(forceOpenedByTriggerValue);
          // Set compact mode based on openedByTrigger value
          setIsCompactMode(forceOpenedByTriggerValue);
        } else {
          // Default behavior - full screen when clicked
          setIsCompactMode(false);
          if (effectiveOpenedByTrigger) {
            effectiveSetOpenedByTrigger(false);
          }
        }
      }
    }
    toggleChat();
  };

  /**
   * Partition messages for desktop:
   * "chatMessages" => normal text chat
   * "searchResults" => carousels from `car_stock_search`
   * For mobile, keep them all in the chat.
   */
  const { chatMessages, searchResults } = useMemo(
    () => partitionMessages(messages, isMobile),
    [messages, isMobile]
  );

  /**
   * Watch for changes in searchResults and only open/update panel
   * if there are actually new carousel items
   */
  useEffect(() => {
    if (!isMobile && searchResults.length > 0) {
      // Get the latest search result's carousel items
      const latestCarouselItems = searchResults[searchResults.length - 1]?.carouselItems;
      
      // Create a content signature for comparison
      const currentContent = latestCarouselItems ? JSON.stringify(latestCarouselItems) : null;
      
      // Only update if we have new carousel content
      if (currentContent && currentContent !== lastCarouselContentRef.current) {
        setSearchPanelOpen(true);
        // Only update currentSearchIndex if we have new content
        setCurrentSearchIndex(searchResults.length - 1);
        lastCarouselContentRef.current = currentContent;
      }
    }
  }, [searchResults, isMobile]);

  // Update handleNavigateSearch to be more strict about boundaries
  const handleNavigateSearch = (delta) => {
    setCurrentSearchIndex((prev) => {
      const newIndex = prev + delta;
      // Only allow navigation within valid search results
      if (newIndex < 0 || newIndex >= searchResults.length) {
        return prev; // Keep current index if out of bounds
      }
      return newIndex;
    });
  };

  /**
   * Whenever currentSearchIndex changes,
   * we can scroll the chat to the corresponding original message if we want
   */
  useEffect(() => {
    const target = searchResults[currentSearchIndex];
    if (target && chatBodyRef.current && typeof chatBodyRef.current.scrollToMessage === "function") {
      chatBodyRef.current.scrollToMessage(target.originalIndex);
    }
  }, [currentSearchIndex, searchResults]);

  // Watch for changes in searchResults
  useEffect(() => {
    if (!isMobile && searchResults.length > lastSearchResultsLength) {
      setSearchPanelOpen(true);
      setLastSearchResultsLength(searchResults.length);
    }
  }, [searchResults, isMobile, lastSearchResultsLength]);

  const handleSearchOptionClick = () => {
    if (!isMobile) {
      console.log("Toggling search panel");
      setSearchPanelOpen(true); // Force open the panel
    }
  };

  // Find the most recent predicted questions message
  const getLatestPredictedQuestions = useMemo(() => {
    // Find all messages with type "predicted_questions"
    const questionMessages = messages.filter(m => m.type === "predicted_questions");
    // Return the questions from the most recent one, or an empty array
    return questionMessages.length > 0 
      ? questionMessages[questionMessages.length - 1].questions || []
      : [];
  }, [messages]);

  useEffect(() => {
    if (dialogOpen) {
      // Store the previously focused element
      const lastFocus = document.activeElement;
      
      return () => {
        // Restore focus when dialog closes
        if (lastFocus && typeof lastFocus.focus === 'function') {
          lastFocus.focus();
        }
      };
    }
  }, [dialogOpen]);

  // Handle user interaction with the chat to reset openedByTrigger and compact mode
  const handleUserInteraction = () => {
    if (effectiveOpenedByTrigger) {
      effectiveSetOpenedByTrigger(false);
    }
  };

  // Add handler for toggling compact mode
  const toggleCompactMode = () => {
    setIsCompactMode(prev => !prev);
  };

  // Handler to immediately remove transparency when chat is interacted with
  const handleChatInteraction = () => {
    // Check renamed state
    if (showTransparencyEffect) {
      setShowTransparencyEffect(false);
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
        scrollTimeoutRef.current = null;
      }
    }
  };

  // Update effect to handle openedByTrigger properly on mobile
  useEffect(() => {
    if (isChatVisible && isMobile && effectiveOpenedByTrigger) {
      // When opened by trigger on mobile, always use compact mode
      setIsCompactMode(true);
    }
  }, [isChatVisible, isMobile, effectiveOpenedByTrigger]);

  // Add effect to expand chat to full size when a new message arrives in reduced mode
  useEffect(() => {
    // Only proceed if chat is visible and in reduced mode
    const isReducedMobile = isMobile && isChatVisible && (isCompactMode || effectiveOpenedByTrigger);
    
    if (isReducedMobile && messages.length > 0) {
      // Get the latest message
      const latestMessage = messages[messages.length - 1];
      
      // Check if it's a bot/AI message (not from the user)
      if (latestMessage && !latestMessage.isUser) {
        // If we have a new message from the AI
        if (messages.length > prevMessageCountRef.current) {
          // Expand the chat to full size
          setIsCompactMode(false);
          if (effectiveOpenedByTrigger) {
            effectiveSetOpenedByTrigger(false);
          }
          
          // Give the UI time to update before scrolling to the new message
          setTimeout(() => {
            // Scroll to the latest message
            const chatMessages = document.querySelector('.chat-messages');
            const lastMessage = chatMessages?.querySelector('.message-container:last-child');
            
            if (lastMessage) {
              lastMessage.scrollIntoView({ behavior: 'smooth', block: 'center' });
              
              // Add a brief highlight effect to the new message
              lastMessage.classList.add('new-message-highlight');
              setTimeout(() => {
                lastMessage.classList.remove('new-message-highlight');
              }, 2000);
            }
          }, 300);
        }
      }
    }
    
    // Always update the counter at the end
    prevMessageCountRef.current = messages.length;
  }, [messages, isMobile, isChatVisible, isCompactMode, effectiveOpenedByTrigger, effectiveSetOpenedByTrigger]);

  return (
    <>
      <AnimatePresence>
        {isChatVisible && (
          <ChatAndSearchContainer
            isMobile={isMobile}
            openedByTrigger={effectiveOpenedByTrigger}
            isCompactMode={isCompactMode}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            transition={{ duration: 0.3 }}
            // Use renamed state for class application
            className={`ChatAndSearchContainer ${isCarSearchActive ? 'car-search-active' : ''} ${showTransparencyEffect ? 'page-scrolling' : ''}`}
            // Keep pointer down handler
            onPointerDown={handleChatInteraction}
          >
            <ChatContainer 
              isMobile={isMobile}
              className={isCarSearchActive ? 'car-search-active' : ''}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#fff",
                  borderRadius: isMobile ? 0 : "20px",
                  boxShadow: isMobile ? "none" : "0 8px 24px rgba(0, 0, 0, 0.08)",
                  display: "flex",
                  flexDirection: "column",
                  overflow: "hidden",
                  position: "relative",
                  border: isMobile ? "none" : "1px solid",
                  borderColor: "rgba(0, 0, 0, 0.1)",
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    flex: 1,
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      zIndex: 1001,
                      backgroundColor: theme.palette.v2.chatBackground,
                      userSelect: "none",
                      cursor: "default",
                      ...(isMobile && {
                        position: 'sticky',
                        top: 0,
                      })
                    }}
                  >
                    {/* Remove the ChatHeader component */}
                  </Box>

                  <Box
                    sx={{
                      position: "relative",
                      flexGrow: 1,
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                      overflow: "hidden",
                      paddingTop: "0",
                      marginTop: 0,
                    }}
                  >
                    <CustomScrollbar>
                      <Fade in={isConnected} timeout={500}>
                        <Box
                          style={{
                            display: isConnected ? "block" : "none",
                            ...(isMobile && {
                              touchAction: 'pan-y',
                              WebkitOverflowScrolling: 'touch',
                            }),
                          }}
                        >
                          <ChatBody
                            ref={chatBodyRef}
                            messages={chatMessages.filter(
                              (m) => !m.type || m.type !== "predicted_questions"
                            )}
                            isLoading={isLoading}
                            onSendMessage={(message) => {
                              // When sending message, clear compact mode and openedByTrigger
                              if (isCompactMode) setIsCompactMode(false);
                              if (effectiveOpenedByTrigger) effectiveSetOpenedByTrigger(false);
                              handleSend(message);
                            }}
                            onSendFile={(file, message) => {
                              // When sending file, clear compact mode and openedByTrigger 
                              if (isCompactMode) setIsCompactMode(false);
                              if (effectiveOpenedByTrigger) effectiveSetOpenedByTrigger(false);
                              handleSend(message, file);
                            }}
                            onSendPersonalData={(personaldata) => {
                              // When sending personal data, clear compact mode and openedByTrigger
                              if (isCompactMode) setIsCompactMode(false);
                              if (effectiveOpenedByTrigger) effectiveSetOpenedByTrigger(false);
                              handleSend(
                                "Dati personali salvati",
                                null,
                                null,
                                personaldata
                              );
                            }}
                            isConnected={isConnected}
                            isAuthenticated={isAuthenticated}
                            isHumanControlled={isHumanControlled}
                            humanControlMessage={humanControlMessage}
                            setIsLoading={setIsLoading}
                            accessToken={accessToken}
                            toggleChat={toggleChat}
                            toggleChatWidth={toggleChatWidth}
                            isChatWide={isChatWide}
                            toggleSearchPanel={toggleSearchPanel}
                            predictedQuestions={getLatestPredictedQuestions}
                            onQuestionClick={(question) => {
                              setInputValue(question);
                              // When clicking a question, clear compact mode and openedByTrigger
                              if (isCompactMode) setIsCompactMode(false);
                              if (effectiveOpenedByTrigger) effectiveSetOpenedByTrigger(false);
                              handleSend(question);
                            }}
                            isMobile={isMobile}
                            inputValue={inputValue}
                            setInputValue={setInputValue}
                            onCarSearchStateChange={handleCarSearchStateChange}
                            openedByTrigger={effectiveOpenedByTrigger}
                            setOpenedByTrigger={effectiveSetOpenedByTrigger}
                            isCompactMode={isCompactMode}
                            toggleCompactMode={toggleCompactMode}
                          />
                        </Box>
                      </Fade>

                      <Fade in={!isConnected && showReconnectionUI} timeout={500}>
                        <Box
                          sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "rgba(255, 255, 255, 0.95)",
                            backdropFilter: "blur(5px)",
                          }}
                        >
                          <Grow in={!isConnected && showReconnectionUI} timeout={800}>
                            <Typography
                              variant="h5"
                              sx={{
                                mb: 2,
                                fontWeight: "bold",
                                color: theme.palette.primary.main,
                              }}
                            >
                              {isReconnecting
                                ? "Riconnessione in corso..."
                                : "Connessione persa"}
                            </Typography>
                          </Grow>
                          <ConnectionStatus
                            isConnected={isConnected}
                            isReconnecting={isReconnecting}
                            reconnectCountdown={reconnectCountdown}
                            reconnectAttempts={reconnectAttempts}
                            MAX_RECONNECT_ATTEMPTS={MAX_RECONNECT_ATTEMPTS}
                            onManualReconnect={handleManualReconnect}
                          />
                        </Box>
                      </Fade>
                    </CustomScrollbar>
                  </Box>

                  <Box
                    sx={{
                      position: "sticky",
                      bottom: 0,
                      zIndex: 10,
                      backgroundColor: theme.palette.v2.chatBackground,
                      userSelect: "none",
                      cursor: "default",
                    }}
                  >
                    <Fade in={isConnected} timeout={500}>
                      <div style={{ display: isConnected ? "block" : "none" }}>
                        <div></div>
                      </div>
                    </Fade>
                  </Box>
                </Box>
              </Box>
            </ChatContainer>

            <AnimatePresence>
              {searchPanelOpen && !isMobile && (
                <SearchPanelContainer
                  initial={{ opacity: 0, rotateY: -90, x: 50 }}
                  animate={{ 
                    opacity: 1, 
                    rotateY: 0,
                    x: 0,
                    transition: { 
                      duration: 0.5, 
                      ease: "easeInOut",
                    }
                  }}
                  exit={{ 
                    opacity: 0, 
                    rotateY: -90,
                    x: 50,
                    transition: { 
                      duration: 0.5, 
                      ease: "easeInOut" 
                    }
                  }}
                >
                  <SearchPanel
                    searchMessages={
                      searchResults.length > 0
                        ? [searchResults[currentSearchIndex]]
                        : []
                    }
                    onNavigateSearch={handleNavigateSearch}
                    currentIndex={currentSearchIndex}
                    totalCount={searchResults.length}
                    onClose={() => setSearchPanelOpen(false)}
                    isLoading={isLoading}
                    isHumanControlled={isHumanControlled}
                  />
                </SearchPanelContainer>
              )}
            </AnimatePresence>

            {/* Replace the floating search button with the semicircle button */}
            <AnimatePresence>
              {!isMobile && searchResults.length > 0 && !searchPanelOpen && (
                <Tooltip title="Apri pannello di ricerca" placement="left">
                  <SemicircleButton
                    onClick={() => setSearchPanelOpen(true)}
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: 20 }}
                    whileHover={{ x: -5 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <ResultBadge
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      transition={{ delay: 0.2 }}
                    >
                      {searchResults.length}
                    </ResultBadge>
                    <IconWrapper>
                      <KeyboardDoubleArrowLeftIcon 
                        style={{ 
                          width: 32, 
                          height: 32
                        }} 
                      />
                    </IconWrapper>
                  </SemicircleButton>
                </Tooltip>
              )}
            </AnimatePresence>
          </ChatAndSearchContainer>
        )}
      </AnimatePresence>
      {!isChatVisible && (
        <NewToggleChat 
          isChatVisible={isChatVisible} 
          toggleChat={handleToggleChat}
          onSendMessage={(message) => handleSend(message)}
          notification={notification}
        />
      )}
    </>
  );
};

export default ChatWrapper;