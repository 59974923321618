import { ArrowForwardIos } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { useState, useEffect, useRef } from "react";
import {
  FaCalendarAlt,
  FaEuroSign,
  FaTachometerAlt,
  FaExternalLinkAlt,
  FaInfoCircle,
  FaCheckCircle,
  FaEye,
} from "react-icons/fa";
import { fetchVehicleData } from "../../../api/fetchVehicleData";
import "./CustomCarousel.css";
import NavigationDots from "./NavigationDots";
import VehicleDialog from "./VehicleDialog";
import { trackChatEvent } from '../../../utils/analytics';
import { addUtmToLink } from '../../../utils/linkHandler';
import { useWebSocketContext } from '../../../context/Theme';

// Adjust card dimensions for better mobile experience
const card_height = 400;
const card_width = 320;
const card_gap = 16;
const min_container_padding = window.innerWidth < 600 ? 12 : 48;
const isMobile = window.innerWidth < 600;

const getThemeValue = (theme, path, fallback) => {
  return (
    path.split(".").reduce((acc, part) => acc && acc[part], theme) || fallback
  );
};

const formatPrice = (price) => {
  return parseFloat(price).toLocaleString("it-IT", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

const MotionCard = motion(Card);
const MotionIconButton = motion(IconButton);
const MotionButton = motion(Button);

const getBadgeColor = (badgeText) => {
  // Always return the same green color (#4caf50) to match the pagination indicator
  return '#4caf50';
  
  // Previous implementation with different colors is commented out
};

const CustomCarousel = ({ items, whishlist, onWishlistUpdate, onSendMessage  }) => {
  const containerRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState(0);
  const [open, setOpen] = useState(false);
  const [vehicleData, setVehicleData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [cardsPerView, setCardsPerView] = useState(1);
  const [containerWidth, setContainerWidth] = useState(0);
  const theme = useTheme();
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const minSwipeDistance = 50;
  const { handleSend } = useWebSocketContext() || {};
  const [isDialogLoading, setIsDialogLoading] = useState(false);

  useEffect(() => {
    const updateCardsPerView = () => {
      if (containerRef.current) {
        const parentWidth = containerRef.current.parentElement.offsetWidth;
        const availableWidth = parentWidth - (2 * min_container_padding);
        
        if (availableWidth < card_width) {
          setContainerWidth(card_width);
          setCardsPerView(1);
        } else {
          const maxCards = Math.floor((availableWidth + card_gap) / (card_width + card_gap));
          const actualCards = Math.min(maxCards, items.length);
          setCardsPerView(actualCards);
          setContainerWidth((card_width + card_gap) * actualCards - card_gap);
        }
      }
    };

    const resizeObserver = new ResizeObserver(updateCardsPerView);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current.parentElement);
    }
    
    updateCardsPerView();
    
    return () => {
      resizeObserver.disconnect();
    };
  }, [items.length]);

  if (items.length === 0) return null;

  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    
    if (isLeftSwipe) {
      handleNext();
    } else if (isRightSwipe) {
      handlePrev();
    }
  };

  const handlePrev = () => {
    trackChatEvent.carouselNav('prev');
    setDirection(-1);
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex - cardsPerView;
      return newIndex < 0 ? Math.max(0, items.length - cardsPerView) : newIndex;
    });
  };

  const handleNext = () => {
    trackChatEvent.carouselNav('next');
    setDirection(1);
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex + cardsPerView;
      return newIndex >= items.length ? 0 : newIndex;
    });
  };

  const handleDotClick = (index) => {
    trackChatEvent.carouselNav(`dot-${index}`);
    setDirection(index > currentIndex ? 1 : -1);
    setCurrentIndex(index);
  };

  const handleOpenVehiclePopup = async (index, e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    // Prevent multiple rapid clicks
    if (isDialogLoading) return;
    
    const vehicle = items[index];
    trackChatEvent.viewVehicleDetails(vehicle.vehicleid, vehicle.title);
    setOpen(true);
    
    // Use local dialog loading state instead of global loading state
    setIsDialogLoading(true);
    setVehicleData(null);
    
    try {
      const data = await fetchVehicleData(items[index].vehicleid);
      setVehicleData(data);
    } catch (error) {
      console.error('Error fetching vehicle data:', error);
    } finally {
      setIsDialogLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleBookVisit = (item) => {
    trackChatEvent.bookVisit(item.vehicleid, item.title);
    const message = `Vorrei prenotare una visita per: ${item.title} (${item.description3 || 'Anno N/D'}, ID: ${item.vehicleid})`;
    
    if (handleSend) {
      handleSend(message, null, null, {
        type: 'chat_message',
        sender: 'user'
      });
    } else if (onSendMessage) {
      onSendMessage(message, null, null, {
        type: 'chat_message',
        sender: 'user'
      });
    }
  };

  const handleInfoRequest = (item) => {
    const message = `Vorrei più informazioni su: ${item.title}, veicolo ${item.vehicleid}`;
    trackChatEvent.sendMessage(message.length);
    
    if (handleSend) {
      handleSend(message, null, null, {
        type: 'chat_message',
        sender: 'user'
      });
    } else if (onSendMessage) {
      onSendMessage(message, null, null, {
        type: 'chat_message',
        sender: 'user'
      });
    }
  };

  const handleOpenPage = (item) => {
    trackChatEvent.openVehiclePage(item.vehicleid, item.title);
    
    const baseUrl = item.url;
    const separator = baseUrl.includes('?') ? '&' : '?';
    const utmParams = `utm_source=carousel_mia_${item.vehicleid}&utm_medium=vehicle_card_mia&utm_campaign=vehicle_listing_mia`;
    const finalUrl = `${baseUrl}${separator}${utmParams}`;
    
    window.open(finalUrl, '_blank');
  };

  return (
    <>
      <Box
        ref={containerRef}
        className="carousel-container"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
          overflow: "visible",
          marginBottom: "24px",
          width: "100%",
          minWidth: {
            xs: card_width,
            sm: card_width + (2 * min_container_padding)
          },
          touchAction: "pan-y pinch-zoom",
        }}
      >
        <div
          style={{
            width: containerWidth,
            height: card_height,
            position: "relative",
            margin: "0 auto",
          }}
          onTouchStart={onTouchStart}
          onTouchMove={onTouchMove}
          onTouchEnd={onTouchEnd}
        >
          <AnimatePresence initial={false} custom={direction}>
            <motion.div
              key={currentIndex}
              custom={direction}
              initial="incoming"
              animate="active"
              exit="exit"
              transition={{
                duration: window.innerWidth < 600 ? 0.4 : 0.6,
                ease: [0.4, 0, 0.2, 1],
              }}
              className="carousel"
              variants={{
                incoming: (direction) => ({
                  x: direction > 0 ? containerWidth : -containerWidth,
                  opacity: 0,
                  scale: 0.95,
                }),
                active: { 
                  x: 0, 
                  scale: 1, 
                  opacity: 1,
                  transition: {
                    duration: 0.5,
                    ease: [0.4, 0, 0.2, 1],
                  }
                },
                exit: (direction) => ({
                  x: direction > 0 ? -containerWidth : containerWidth,
                  opacity: 0,
                  scale: 0.95,
                }),
              }}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                display: "flex",
                gap: `${card_gap}px`,
                width: containerWidth,
              }}
            >
              {items.slice(currentIndex, currentIndex + cardsPerView).map((item, index) => {
                const isWishlisted = whishlist?.some(
                  ({ vehicleid }) => vehicleid === item.vehicleid
                );
                
                // Calculate price discount
                const oldPrice = parseFloat(item.description4 || "0");
                const newPrice = parseFloat(item.description2);
                const hasDiscount = oldPrice > newPrice && oldPrice > 0;
                const discountPercentage = hasDiscount ? Math.round((1 - newPrice / oldPrice) * 100) : 0;

                return (
                  <MotionCard
                    key={`${item.vehicleid}-${index}`}
                    className="vehicle-card"
                    whileHover={{ 
                      y: -5,
                      transition: { duration: 0.2 }
                    }}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{
                      duration: 0.3,
                      delay: index * 0.1,
                    }}
                    sx={{
                      width: card_width,
                      height: card_height,
                      flexShrink: 0,
                      margin: 0,
                      boxShadow: 2,
                      borderRadius: "12px",
                      overflow: "hidden",
                      display: "flex",
                      flexDirection: "column",
                      position: "relative",
                      backgroundColor: "#fff",
                    }}
                  >
                    {/* Image Section */}
                    <Box 
                      sx={{ 
                        position: "relative", 
                        height: "160px",
                        overflow: "hidden",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleOpenVehiclePopup(currentIndex + index, e);
                        return false; // Add this to ensure no further propagation
                      }}
                      onMouseDown={(e) => {
                        // Prevent any mouse events from propagating
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <CardMedia
                        component="img"
                        height="160"
                        src={item.image}
                        sx={{ 
                          objectFit: "cover",
                          transition: "transform 0.3s ease-in-out",
                          "&:hover": {
                            transform: "scale(1.05)",
                          },
                        }}
                        onError={(e) => {
                          if (e && e.target) {
                            const fallbackLogo = localStorage.getItem("logo") || `${process.env.REACT_APP_DOMAIN}/media/company_logos/3_ms-icon-144x144.png`;
                            e.target.src = fallbackLogo;
                            e.target.style.objectFit = 'contain';
                            e.target.style.padding = '20px';
                            e.target.style.backgroundColor = '#f5f5f5';
                          }
                        }}
                      />
                      
                      {/* Badge */}
                      {item.badge && (
                        <Box className="vehicle-badge" sx={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          backgroundColor: getBadgeColor(item.badge),
                          color: '#fff',
                          padding: '4px 8px',
                          fontWeight: 600,
                          fontSize: '0.75rem',
                          textTransform: 'uppercase',
                          borderBottomRightRadius: '8px',
                        }}>
                          {item.badge}
                        </Box>
                      )}
                      
                      {/* Discount Badge */}
                      {hasDiscount && (
                        <Box className="discount-badge" sx={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          backgroundColor: '#e53935',
                          color: '#fff',
                          padding: '4px 8px',
                          fontWeight: 700,
                          fontSize: '0.8rem',
                          borderBottomLeftRadius: '8px',
                        }}>
                          -{discountPercentage}%
                        </Box>
                      )}
                      
                      {/* Unavailable Badge */}
                      {!item.available && (
                        <Box className="unavailable-badge" sx={{
                          position: "absolute",
                          bottom: 0,
                          left: 0,
                          right: 0,
                          backgroundColor: 'rgba(0,0,0,0.7)',
                          color: '#fff',
                          padding: '4px',
                          textAlign: 'center',
                          fontWeight: 600,
                          fontSize: '0.8rem',
                        }}>
                          Non disponibile
                        </Box>
                      )}
                    </Box>
                    
                    {/* Content Section */}
                    <CardContent sx={{ 
                      p: 2, 
                      pt: 1.5,
                      flexGrow: 1,
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                      {/* Title */}
                      <Typography
                        variant="h6"
                        component="h3"
                        sx={{
                          fontWeight: 600,
                          fontSize: '0.95rem',
                          lineHeight: 1.2,
                          mb: 1,
                          display: '-webkit-box',
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: 'vertical',
                          overflow: 'hidden',
                          height: '2.4em',
                        }}
                      >
                        {item.title}
                      </Typography>
                      
                      {/* Vehicle Details */}
                      <Box sx={{ 
                        display: 'flex', 
                        flexWrap: 'wrap',
                        gap: 1.5,
                        mb: 1.5,
                      }}>
                        {(() => {
                          const badgeLower = (item.badge || "").toLowerCase();
                          const isMileageAllowed =
                            badgeLower !== "nuovo" &&
                            badgeLower !== "km zero" &&
                            badgeLower !== "km0" &&
                            badgeLower !== "km 0";

                          if (item.description1 && isMileageAllowed) {
                            return (
                              <Box sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 0.5,
                                fontSize: '0.8rem',
                                color: '#555',
                              }}>
                                <FaTachometerAlt size={12} />
                                <span>{formatPrice(item.description1)} km</span>
                              </Box>
                            );
                          }
                          return null;
                        })()}
                        
                        {item.description3 && (
                          <Box sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 0.5,
                            fontSize: '0.8rem',
                            color: '#555',
                          }}>
                            <FaCalendarAlt size={12} />
                            <span>{item.description3}</span>
                          </Box>
                        )}
                      </Box>
                      
                      {/* Price Section */}
                      {item.description2 && (
                        <Box sx={{
                          mt: 'auto',
                          mb: 1.5,
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 0.5,
                        }}>
                          {hasDiscount && (
                            <Typography
                              variant="body2"
                              sx={{
                                textDecoration: "line-through",
                                color: "#777",
                                fontSize: '0.8rem',
                              }}
                            >
                              € {formatPrice(oldPrice)}
                            </Typography>
                          )}
                          
                          <Typography
                            variant="h5"
                            sx={{
                              fontWeight: 700,
                              color: hasDiscount ? '#e53935' : '#333',
                              fontSize: hasDiscount ? '1.3rem' : '1.2rem',
                              lineHeight: 1,
                            }}
                          >
                            € {formatPrice(item.description2)}
                          </Typography>
                          
                          {hasDiscount && (
                            <Typography
                              variant="body2"
                              sx={{
                                color: '#4CAF50',
                                fontSize: '0.75rem',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 0.5,
                              }}
                            >
                              <FaCheckCircle size={12} />
                              Risparmi € {formatPrice(oldPrice - newPrice)}
                            </Typography>
                          )}
                        </Box>
                      )}
                      
                      {/* Action Buttons */}
                      {item.available && (
                        <Box className="action-buttons" sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 1,
                          mt: 'auto',
                        }}>
                          {/* Primary Action */}
                          <MotionButton
                            size="small"
                            onClick={() => handleBookVisit(item)}
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            startIcon={<FaCalendarAlt style={{ fontSize: '1rem' }} />}
                            sx={{
                              fontSize: '0.85rem',
                              borderRadius: '20px',
                              backgroundColor: '#4caf50',
                              color: '#fff',
                              padding: '8px 16px',
                              textTransform: 'none',
                              fontWeight: 600,
                              minWidth: 'auto',
                              boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                              height: '36px', // Better touch target for mobile
                              '&:hover': {
                                backgroundColor: '#43a047',
                                boxShadow: '0 4px 8px rgba(0,0,0,0.15)',
                              },
                              '&.Mui-disabled': {
                                backgroundColor: '#e8f5e9',
                                color: '#81c784',
                              }
                            }}
                          >
                            Prenota visita
                          </MotionButton>
                          
                          {/* Secondary Actions */}
                          <Box sx={{ 
                            display: 'grid', 
                            gridTemplateColumns: 'repeat(2, 1fr)', 
                            gap: 1 
                          }}>
                            <MotionButton
                              size="small"
                              onClick={() => handleInfoRequest(item)}
                              whileHover={{ scale: 1.05 }}
                              whileTap={{ scale: 0.95 }}
                              startIcon={<FaInfoCircle style={{ fontSize: '0.9rem' }} />}
                              sx={{
                                fontSize: '0.8rem',
                                borderRadius: '20px',
                                backgroundColor: '#fff',
                                color: '#444',
                                padding: '6px 12px',
                                textTransform: 'none',
                                fontWeight: 600,
                                minWidth: 'auto',
                                border: '2px solid #e0e0e0',
                                height: '36px', // Better touch target for mobile
                                '&:hover': {
                                  backgroundColor: '#f5f5f5',
                                  borderColor: '#bdbdbd',
                                },
                                '&.Mui-disabled': {
                                  backgroundColor: '#f5f5f5',
                                  color: '#9e9e9e',
                                  border: '2px solid #e0e0e0',
                                }
                              }}
                            >
                              Informazioni
                            </MotionButton>
                            
                            {/* Commenting out the Dettagli button temporarily due to issues */}
                            
                            <MotionButton
                              size="small"
                              onClick={() => handleOpenPage(item)}
                              whileHover={{ scale: 1.05 }}
                              whileTap={{ scale: 0.95 }}
                              startIcon={<FaExternalLinkAlt style={{ fontSize: '0.9rem' }} />}
                              sx={{
                                fontSize: '0.8rem',
                                borderRadius: '20px',
                                backgroundColor: '#f5f5f5',
                                color: '#424242',
                                padding: '6px 12px',
                                textTransform: 'none',
                                fontWeight: 600,
                                minWidth: 'auto',
                                height: '36px', // Better touch target for mobile
                                border: '2px solid #e0e0e0',
                                '&:hover': {
                                  backgroundColor: '#eeeeee',
                                  borderColor: '#bdbdbd',
                                },
                                '&.Mui-disabled': {
                                  backgroundColor: '#f5f5f5',
                                  color: '#9e9e9e',
                                  border: '2px solid #e0e0e0',
                                }
                              }}
                            >
                              Vai alla pagina
                            </MotionButton>
                          </Box>
                        </Box>
                      )}
                    </CardContent>
                  </MotionCard>
                );
              })}
            </motion.div>
          </AnimatePresence>

          {/* Navigation Arrows */}
          {items.length > cardsPerView && (
            <>
              <MotionIconButton
                className="carousel-arrow prev-arrow"
                onClick={handlePrev}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                aria-label="Previous"
                sx={{
                  position: "absolute",
                  left: { xs: 8, sm: -48 },
                  top: "50%",
                  transform: "translateY(-50%)",
                  zIndex: 2,
                  backgroundColor: "rgba(0, 0, 0, 0.5)!important",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.7)!important",
                  },
                  borderRadius: "50%",
                  width: { xs: "32px", sm: "40px" },
                  height: { xs: "32px", sm: "40px" },
                  cursor: "pointer",
                  display: { xs: "none", sm: "flex" },
                }}
              >
                <motion.svg
                  focusable="false"
                  aria-hidden="true"
                  viewBox="-4 0 24 24"
                  width="24"
                  height="24"
                  fill="#fff"
                  style={{ transform: "rotate(180deg)" }}
                >
                  <path d="M6.23 20.23 8 22l10-10L8 2 6.23 3.77 14.46 12z"></path>
                </motion.svg>
              </MotionIconButton>

              <MotionIconButton
                className="carousel-arrow next-arrow"
                onClick={handleNext}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                aria-label="Next"
                sx={{
                  position: "absolute",
                  right: { xs: 8, sm: -48 },
                  top: "50%",
                  transform: "translateY(-50%)",
                  zIndex: 2,
                  backgroundColor: "rgba(0, 0, 0, 0.5)!important",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.7)!important",
                  },
                  borderRadius: "50%",
                  width: { xs: "32px", sm: "40px" },
                  height: { xs: "32px", sm: "40px" },
                  cursor: "pointer",
                  display: { xs: "none", sm: "flex" },
                }}
              >
                <motion.svg
                  focusable="false"
                  aria-hidden="true"
                  viewBox="-4 0 24 24"
                  width="24"
                  height="24"
                  fill="#fff"
                >
                  <path d="M6.23 20.23 8 22l10-10L8 2 6.23 3.77 14.46 12z"></path>
                </motion.svg>
              </MotionIconButton>
            </>
          )}
        </div>

        {/* Navigation Dots */}
        {items.length > 1 && (
          <NavigationDots
            count={Math.ceil(items.length / cardsPerView)}
            activeIndex={Math.floor(currentIndex / cardsPerView)}
            onDotClick={handleDotClick}
          />
        )}
      </Box>

      <VehicleDialog
        open={open}
        handleClose={handleClose}
        vehicleData={vehicleData}
        isLoading={isDialogLoading}
      />
    </>
  );
};

export default CustomCarousel;