import React, { useState, useEffect } from 'react';
import { Box, Typography, Card, CardMedia, CardContent, Button, Chip, CircularProgress, Grid } from '@mui/material';
import { FaTachometerAlt, FaCalendarAlt, FaEuroSign, FaInfoCircle, FaEye, FaCheckCircle, FaExternalLinkAlt } from 'react-icons/fa';
import { motion } from 'framer-motion';
import VehicleDialog from './ChatBody/CustomCarousel/VehicleDialog';
import { fetchVehicleData } from '../api/fetchVehicleData';
import { trackChatEvent } from '../utils/analytics';
import { useWebSocketContext } from '../context/Theme';

const MotionButton = motion(Button);

const formatPrice = (price) => {
  return parseFloat(price).toLocaleString("it-IT", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

// Helper function to determine badge color based on badge text
const getBadgeColor = (badgeText) => {
  // Always return the same green color (#4caf50) to match the pagination indicator
  return '#4caf50';
  
  // Previous implementation with different colors:
  /*
  if (!badgeText) return '#607D8B'; // Default gray
  
  const badgeLower = badgeText.toLowerCase();
  if (badgeLower.includes('nuovo') || badgeLower.includes('nuova')) return '#4CAF50'; // Green
  if (badgeLower.includes('km 0') || badgeLower.includes('km0')) return '#2196F3'; // Blue
  if (badgeLower.includes('usato')) return '#FF9800'; // Orange
  if (badgeLower.includes('pronta consegna')) return '#9C27B0'; // Purple
  if (badgeLower.includes('aziendale')) return '#00BCD4'; // Cyan
  return '#607D8B'; // Default gray for other cases
  */
};

/**
 * A simple horizontally-scrollable car carousel,
 * specialized for the SearchPanel.
 */
const SearchPanelCarousel = ({ items = [] }) => {
  const { handleSend, isLoading, setIsLoading } = useWebSocketContext();
  const [open, setOpen] = useState(false);
  const [vehicleData, setVehicleData] = useState(null);
  const [selectedVehicleId, setSelectedVehicleId] = useState(null);
  const [showFullScreenGallery, setShowFullScreenGallery] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [loadingImages, setLoadingImages] = useState(true);

  useEffect(() => {
    setLoadingImages(true);
    setImagesLoaded(false);

    const imagePromises = items.map((car) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = car.image;
        img.onload = () => resolve();
        img.onerror = () => resolve(); // Also resolve on error to prevent hanging
      });
    });

    Promise.all(imagePromises).then(() => {
      setImagesLoaded(true);
      setLoadingImages(false);
    });
  }, [items]); // Reset loading state when items change

  const handleBookVisit = (car) => {
    const message = `Vorrei prenotare una visita per: ${car.title}, veicolo ${car.vehicleid}`;
    trackChatEvent.bookVisit(car.vehicleid, car.title);
    
    handleSend(message, null, null, {
      type: 'chat_message',
      sender: 'user'
    });
  };

  const handleInfoRequest = (car) => {
    const message = `Vorrei più informazioni su: ${car.title}, veicolo ${car.vehicleid}`;
    trackChatEvent.sendMessage(message.length);
    
    handleSend(message, null, null, {
      type: 'chat_message',
      sender: 'user'
    });
  };

  const handleImageClick = async (vehicle) => {
    trackChatEvent.viewVehicleDetails(vehicle.vehicleid, vehicle.title);
    setOpen(true);
    setVehicleData(null);
    setSelectedVehicleId(vehicle.vehicleid);
    setShowFullScreenGallery(false);
    
    try {
      const data = await fetchVehicleData(vehicle.vehicleid);
      setVehicleData(data);
    } catch (error) {
      console.error('Error fetching vehicle data:', error);
    }
  };

  const handleDetailsClick = (car, e) => {
    // Stop the event from propagating to prevent navigation triggers
    if (e) {
      e.stopPropagation();
      // Don't prevent default as it might interfere with button behavior
    }
    
    // Use setTimeout to break the event chain
    setTimeout(() => {
      trackChatEvent.viewVehicleDetails(car.vehicleid, car.title);
      setOpen(true);
      setIsLoading(true);
      setVehicleData(null);
      setSelectedVehicleId(car.vehicleid);
      setShowFullScreenGallery(false);
      
      fetchVehicleData(car.vehicleid)
        .then(data => {
          setVehicleData(data);
        })
        .catch(error => {
          console.error('Error fetching vehicle data:', error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, 0);
  };

  const handleClose = () => {
    setOpen(false);
    setShowFullScreenGallery(false);
  };

  if (loadingImages || !imagesLoaded) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '200px',
          width: '100%',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Grid container spacing={2}>
        {items.map((car, idx) => (
          <Grid item xs={12} md={6} key={idx}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                borderRadius: '12px',
                overflow: 'hidden',
                boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                '&:hover': {
                  boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
                },
                bgcolor: 'rgba(255, 255, 255, 0.85)',
                backdropFilter: 'blur(8px)',
                position: 'relative',
              }}
            >
              {car.badge && (
                <Box
                  className="vehicle-badge"
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    backgroundColor: getBadgeColor(car.badge),
                    color: '#fff',
                    padding: '4px 8px',
                    fontWeight: 600,
                    fontSize: '0.75rem',
                    textTransform: 'uppercase',
                    borderBottomRightRadius: '8px',
                    zIndex: 2,
                  }}
                >
                  {car.badge}
                </Box>
              )}
              
              {!car.available && (
                <Box
                  className="unavailable-badge"
                  sx={{
                    position: "absolute",
                    top: car.badge ? '28px' : 0,
                    left: 0,
                    backgroundColor: '#f44336',
                    color: "white",
                    padding: '4px 8px',
                    fontWeight: 600,
                    fontSize: '0.75rem',
                    textTransform: 'uppercase',
                    borderBottomRightRadius: car.badge ? 0 : '8px',
                    zIndex: 2,
                  }}
                >
                  Non disponibile
                </Box>
              )}

              <Box
                component={motion.div}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                sx={{
                  width: '100%',
                  height: '180px',
                  cursor: 'pointer',
                  overflow: 'hidden',
                  position: 'relative',
                }}
                onClick={() => handleImageClick(car)}
              >
                {/* Discount Badge */}
                {(() => {
                  const oldPrice = parseFloat(car.description4 || "0");
                  const newPrice = parseFloat(car.description2);
                  const hasDiscount = oldPrice > newPrice && oldPrice > 0;
                  
                  if (hasDiscount) {
                    const discountPercentage = Math.round((1 - newPrice / oldPrice) * 100);
                    return (
                      <Box
                        className="discount-badge"
                        sx={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          backgroundColor: '#e53935',
                          color: '#fff',
                          padding: '4px 8px',
                          fontWeight: 700,
                          fontSize: '0.8rem',
                          borderBottomLeftRadius: '8px',
                          zIndex: 2,
                        }}
                      >
                        -{discountPercentage}%
                      </Box>
                    );
                  }
                  return null;
                })()}
                
                <CardMedia
                  component="img"
                  src={car.image}
                  alt={car.title ?? 'Car image'}
                  sx={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    transition: 'transform 0.3s ease-in-out',
                    '&:hover': {
                      transform: 'scale(1.05)',
                    },
                  }}
                  onError={(e) => {
                    const fallbackLogo = localStorage.getItem("logo") || `${process.env.REACT_APP_DOMAIN}/media/company_logos/3_ms-icon-144x144.png`;
                    e.target.src = fallbackLogo;
                    e.target.style.objectFit = 'contain';
                    e.target.style.padding = '20px';
                    e.target.style.backgroundColor = '#f5f5f5';
                  }}
                />
              </Box>
              <Box sx={{ 
                flex: 1, 
                display: 'flex',
                flexDirection: 'column',
                p: 1.5,
                justifyContent: 'space-between',
                gap: 0.5,
              }}>
                <Box>
                  <Typography 
                    variant="h6" 
                    sx={{ 
                      fontWeight: 700,
                      fontSize: "1.1rem",
                      lineHeight: 1.2,
                      marginBottom: "4px",
                      color: "#000",
                      display: "inline-block",
                      borderRadius: "4px",
                      paddingX: "4px",
                    }}
                  >
                    {car.title}
                  </Typography>

                  <motion.div
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.4 }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "6px",
                        flexWrap: "wrap",
                        backgroundColor: "rgba(224, 247, 250, 0.5)",
                        borderRadius: "6px",
                        padding: "4px 6px",
                        mb: 0.25,
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 700,
                          display: "inline-flex",
                          alignItems: "center",
                          margin: 0,
                          fontSize: "1rem",
                        }}
                      >
                        <FaEuroSign style={{ marginRight: "4px", fontSize: "1rem" }} />
                        {formatPrice(car.description2)}
                      </Typography>

                      {(() => {
                        const oldPrice = parseFloat(car.description4 || "0");
                        const newPrice = parseFloat(car.description2);
                        const hasDiscount = oldPrice > newPrice && oldPrice > 0;
                        if (!hasDiscount) return null;

                        const difference = oldPrice - newPrice;
                        const discountPercentage = Math.round((1 - newPrice / oldPrice) * 100);

                        return (
                          <>
                            <Typography
                              variant="body2"
                              sx={{
                                textDecoration: "line-through",
                                color: "#777",
                                display: "inline-flex",
                                alignItems: "center",
                                gap: "2px",
                                fontSize: "0.75rem",
                              }}
                            >
                              <FaEuroSign style={{ fontSize: "0.7rem" }} />
                              {formatPrice(oldPrice)}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{
                                fontWeight: 600,
                                color: "success.main",
                                display: "inline-flex",
                                alignItems: "center",
                                gap: "4px",
                                fontSize: "0.75rem",
                              }}
                            >
                              <FaCheckCircle style={{ fontSize: "0.8rem" }} />
                              Risparmi {formatPrice(difference)} €
                            </Typography>
                          </>
                        );
                      })()}
                    </Box>

                    <Box sx={{ 
                      display: 'flex', 
                      gap: 2,
                      alignItems: 'center',
                      mb: 0.5,
                    }}>
                      {(() => {
                        const badgeLower = (car.badge || "").toLowerCase();
                        const isMileageAllowed =
                          badgeLower !== "nuovo" &&
                          badgeLower !== "km zero" &&
                          badgeLower !== "km0" &&
                          badgeLower !== "km 0";

                        if (car.description1 && isMileageAllowed) {
                          return (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                              <FaTachometerAlt size={12} color="#666" />
                              <Typography sx={{ fontSize: '0.8rem', color: '#666' }}>
                                {formatPrice(car.description1)} km
                              </Typography>
                            </Box>
                          );
                        }
                        return null;
                      })()}
                      
                      {car.description3 && (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                          <FaCalendarAlt size={12} color="#666" />
                          <Typography sx={{ fontSize: '0.8rem', color: '#666' }}>
                            {car.description3}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </motion.div>
                </Box>

                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    gap: 0.75,
                    width: '100%',
                    marginBottom: 0.75,
                  }}
                >
                  <MotionButton
                    size="small"
                    onClick={() => handleBookVisit(car)}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    startIcon={<FaCalendarAlt style={{ fontSize: '0.9rem' }} />}
                    disabled={isLoading}
                    sx={{
                      fontSize: '0.8rem',
                      borderRadius: '20px',
                      backgroundColor: '#4caf50',
                      color: '#fff',
                      padding: '6px 12px',
                      textTransform: 'none',
                      fontWeight: 600,
                      minWidth: 'auto',
                      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                      '&:hover': {
                        backgroundColor: '#43a047',
                        boxShadow: '0 4px 8px rgba(0,0,0,0.15)',
                      },
                      '&.Mui-disabled': {
                        backgroundColor: '#e8f5e9',
                        color: '#81c784',
                      }
                    }}
                  >
                    Prenota
                  </MotionButton>

                  <MotionButton
                    size="small"
                    onClick={() => handleInfoRequest(car)}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    startIcon={<FaInfoCircle style={{ fontSize: '0.9rem' }} />}
                    disabled={isLoading}
                    sx={{
                      fontSize: '0.8rem',
                      borderRadius: '20px',
                      backgroundColor: '#fff',
                      color: '#444',
                      padding: '6px 12px',
                      textTransform: 'none',
                      fontWeight: 600,
                      minWidth: 'auto',
                      border: '2px solid #e0e0e0',
                      '&:hover': {
                        backgroundColor: '#f5f5f5',
                        borderColor: '#bdbdbd',
                      },
                      '&.Mui-disabled': {
                        backgroundColor: '#f5f5f5',
                        color: '#9e9e9e',
                        border: '2px solid #e0e0e0',
                      }
                    }}
                  >
                    Info
                  </MotionButton>
                </Box>

                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr',
                    gap: 0.75,
                    width: '100%',
                  }}
                >
                  {/* Commenting out the Dettagli button temporarily due to issues */}

                  {car.url && (
                    <MotionButton
                      size="small"
                      component="a"
                      href={`${car.url}${car.url.includes('?') ? '&' : '?'}utm_source=carousel_mia_${car.vehicleid}&utm_medium=vehicle_card_mia&utm_campaign=vehicle_listing_mia`}
                      target="_blank"
                      rel="noopener noreferrer"
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      startIcon={<FaExternalLinkAlt style={{ fontSize: '0.9rem' }} />}
                      sx={{
                        fontSize: '0.8rem',
                        borderRadius: '20px',
                        backgroundColor: '#f5f5f5',
                        color: '#424242',
                        padding: '6px 12px',
                        textTransform: 'none',
                        fontWeight: 600,
                        minWidth: 'auto',
                        border: '2px solid #e0e0e0',
                        '&:hover': {
                          backgroundColor: '#eeeeee',
                          borderColor: '#bdbdbd',
                        },
                        '&.Mui-disabled': {
                          backgroundColor: '#f5f5f5',
                          color: '#9e9e9e',
                          border: '2px solid #e0e0e0',
                        }
                      }}
                      onClick={() => trackChatEvent.openVehiclePage(car.vehicleid, car.title)}
                    >
                      Visita pagina veicolo
                    </MotionButton>
                  )}
                </Box>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>

      <VehicleDialog
        open={open}
        handleClose={handleClose}
        vehicleData={vehicleData}
        vehicleid={selectedVehicleId}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        showFullScreenGallery={showFullScreenGallery}
      />
    </>
  );
};

export default SearchPanelCarousel; 