import React, { useEffect, useState, useRef} from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Box,
  Typography,
  IconButton,
  Tab,
  Tabs,
  CardMedia,
  useTheme,
  ThemeProvider,
  createTheme,
  styled,
  Skeleton,
  Alert,
  CircularProgress,
  useMediaQuery
} from '@mui/material';

import {
  Close as CloseIcon,
  Info,
  Directions,
  ExpandLess,
  ExpandMore,
  DirectionsCar,
  ArrowUpward,
  Star,
  Speed,
  LocalGasStation,
  EventSeat,
  ColorLens,
  ArrowBackIos,
  ArrowForwardIos,
} from '@mui/icons-material';

import CustomScrollbar from '../../CustomScrollbar';
import ImageGallery from './ImageGallery';
import Accessories from './Accessories';
import { StyledComponentsThemWrapper } from '../../StyledComponentsThemWrapper';
import { trackChatEvent } from '../../../utils/analytics';
import ProgressiveImage from './ProgressiveImage';

// Enhanced theme with modern styling
const customTheme = createTheme({
  typography: {
    fontFamily: "'Plus Jakarta Sans', 'Inter', sans-serif",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          background: "linear-gradient(135deg, #2563eb 0%, #3b82f6 100%)",
          color: "#FFFFFF",
          transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
          "&:hover": {
            transform: "translateY(-2px)",
            boxShadow: "0 8px 20px rgba(37, 99, 235, 0.25)",
          }
        },
      },
    },
  },
});

const formatPrice = (price) => {
  return parseFloat(price).toLocaleString("it-IT", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

const defaultImage = "https://www.aitomotivelab.com/images/logo/AitomotiveLab_White.svg";

// Add a fallback image constant
const FALLBACK_IMAGE = `${process.env.REACT_APP_DOMAIN}/media/company_logos/3_ms-icon-144x144.png`;

// Enhanced styled components with glass morphism effects
const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: theme.palette.text.primary,
  padding: theme.spacing(2),
  position: "relative",
  backdropFilter: "blur(10px)",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: "linear-gradient(45deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0.05) 100%)",
    opacity: 0.3,
  }
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  fontWeight: 600,
  fontSize: "0.925rem",
  textTransform: "none",
  letterSpacing: "0.025em",
  transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
  color: theme.palette.v2.textSecondary,
  "&.Mui-selected": {
    color: theme.palette.v2.primary,
    fontWeight: 700,
    "&::after": {
      content: '""',
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      height: "3px",
      background: theme.palette.v2.primary,
      borderRadius: "3px 3px 0 0",
    }
  },
  "&:hover": {
    backgroundColor: "rgba(37, 99, 235, 0.04)",
    transform: "translateY(-1px)",
  }
}));

const InfoCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2.5),
  borderRadius: "16px",
  backgroundColor: "#ffffff",
  position: "relative",
  overflow: "hidden",
  border: "1px solid rgba(0,0,0,0.05)",
  boxShadow: "0 4px 6px rgba(0,0,0,0.02), 0 1px 3px rgba(0,0,0,0.03)",
  transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    height: "4px",
    background: theme.palette.v2.primary,
    opacity: 0,
    transition: "opacity 0.3s ease",
  },
  "&:hover": {
    transform: "translateY(-4px)",
    boxShadow: "0 12px 24px rgba(0,0,0,0.05), 0 4px 8px rgba(0,0,0,0.04)",
    "&::before": {
      opacity: 1,
    }
  }
}));

// Enhanced TabPanel with improved animations
const TabPanel = ({ children, value, index }) => (
  <motion.div
    role="tabpanel"
    initial={{ opacity: 0, x: 20 }}
    animate={{ 
      opacity: value === index ? 1 : 0,
      x: value === index ? 0 : 20,
      display: value === index ? 'flex' : 'none',
    }}
    transition={{ 
      duration: 0.4,
      ease: [0.4, 0, 0.2, 1]
    }}
    style={{ 
      height: '100%',
      position: 'absolute',
      width: '100%',
      top: 0,
      left: 0,
      overflow: 'hidden'
    }}
  >
    {value === index && children}
  </motion.div>
);

const GeneralInfo = ({ vehicleData }) => {
  const theme = useTheme();

  const infoItems = [
    {
      label: "Anno di Immatricolazione",
      value: vehicleData?.registrationYear,
      icon: <EventSeat sx={{ color: theme.palette.v2.primary }} />,
      check: true,
    },
    {
      label: "Colore",
      value: vehicleData?.colour,
      icon: <ColorLens sx={{ color: theme.palette.v2.primary }} />
    },
    {
      label: "Chilometri",
      value: vehicleData?.km,
      icon: <Speed sx={{ color: theme.palette.v2.primary }} />,
      check: true,
    },
    {
      label: "Prezzo di Listino",
      value: vehicleData?.retailPrice || 0,
      icon: <Star sx={{ color: theme.palette.v2.primary }} />,
      check: true,
    },
    {
      label: "Prezzo di Vendita",
      value: vehicleData?.salePrice || 0,
      icon: <Star sx={{ color: theme.palette.v2.primary }} />,
      check: true,
      highlight: true,
    },
    {
      label: "Gruppo Modello",
      value: vehicleData?.modelGroup,
      icon: <DirectionsCar sx={{ color: theme.palette.v2.primary }} />,
    },
    {
      label: "Carburante",
      value: vehicleData?.fuel,
      icon: <LocalGasStation sx={{ color: theme.palette.v2.primary }} />
    },
  ];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Grid container spacing={3}>
        {infoItems
          .filter(item => {
            if (item.check) {
              const value = parseFloat(item.value);
              return !isNaN(value) && value > 0;
            }
            return item.value?.toString().trim() !== "" && item.value != null;
          })
          .map((item, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ 
                  delay: index * 0.1,
                  duration: 0.4,
                  ease: [0.4, 0, 0.2, 1]
                }}
              >
                <InfoCard
                  sx={{
                    background: item.highlight ? 
                      'linear-gradient(135deg, rgba(37,99,235,0.05) 0%, rgba(59,130,246,0.05) 100%)' : 
                      'white'
                  }}
                >
                  <Box sx={{ 
                    display: "flex", 
                    alignItems: "center", 
                    mb: 1,
                    position: "relative",
                    "&::after": {
                      content: '""',
                      position: "absolute",
                      bottom: -8,
                      left: 0,
                      right: 0,
                      height: "1px",
                      background: "linear-gradient(90deg, rgba(37,99,235,0.1) 0%, rgba(59,130,246,0.1) 100%)",
                    }
                  }}>
                    {item.icon}
                    <Typography
                      variant="subtitle2"
                      sx={{ 
                        ml: 1, 
                        color: 'text.secondary',
                        fontWeight: 600,
                        letterSpacing: "0.01em"
                      }}
                    >
                      {item.label}
                    </Typography>
                  </Box>
                  <Typography 
                    variant="body1"
                    sx={{
                      fontWeight: 700,
                      color: item.highlight ? theme.palette.v2.primary : 'text.primary',
                      fontSize: item.highlight ? "1.25rem" : "inherit",
                      mt: 1
                    }}
                  >
                    {item.label.includes("Prezzo") ? (
                      <>
                        {item.label === "Prezzo di Listino" ? (
                          <Typography
                            variant="body2"
                            sx={{ 
                              color: 'text.disabled',
                              textDecoration: 'line-through',
                              fontWeight: 500
                            }}
                          >
                            {`${formatPrice(item.value)} €`}
                          </Typography>
                        ) : (
                          <motion.div
                            initial={{ scale: 0.95 }}
                            animate={{ scale: 1 }}
                            transition={{ 
                              duration: 0.4,
                              ease: [0.4, 0, 0.2, 1]
                            }}
                          >
                            {`${formatPrice(item.value)} €`}
                          </motion.div>
                        )}
                      </>
                    ) : (
                      item.value
                    )}
                  </Typography>
                </InfoCard>
              </motion.div>
            </Grid>
          ))}
      </Grid>
    </motion.div>
  );
};

// Add a loading skeleton
const LoadingSkeleton = () => (
  <div className="vehicle-dialog-skeleton">
    <div className="image-skeleton animate-pulse"></div>
    <div className="content-skeleton">
      <div className="title-skeleton animate-pulse"></div>
      <div className="details-skeleton animate-pulse"></div>
    </div>
  </div>
);

// Modify the image loading logic
const handleImageError = (e) => {
  if (e && e.target) {
    e.target.src = FALLBACK_IMAGE;
    e.target.style.objectFit = 'contain';
    e.target.style.padding = '20px';
    e.target.style.backgroundColor = '#f5f5f5';
  }
};

// Main VehicleDialog component
const VehicleDialog = ({ 
  open, 
  handleClose, 
  vehicleData, 
  vehicleid, 
  isLoading = true,
  setIsLoading = () => {},
  showFullScreenGallery = false
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [images, setImages] = useState([]);
  const [fullScreenImage, setFullScreenImage] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showUnavailable, setShowUnavailable] = useState(false);
  const loadingTimerRef = useRef(null);
  const unavailableTimerRef = useRef(null);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  // Add ref for the dialog
  const dialogRef = useRef(null);

  // Add focus management
  useEffect(() => {
    if (open) {
      // Store the currently focused element
      const previouslyFocused = document.activeElement;
      
      // Focus the dialog after it opens
      if (dialogRef.current) {
        dialogRef.current.focus();
      }

      return () => {
        // Restore focus when dialog closes
        if (previouslyFocused instanceof HTMLElement) {
          previouslyFocused.focus();
        }
      };
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      // Don't set global loading state
      // if (typeof setIsLoading === 'function') {
      //   setIsLoading(true);
      // }
      setShowUnavailable(false);
      
      if (loadingTimerRef.current) clearTimeout(loadingTimerRef.current);
      if (unavailableTimerRef.current) clearTimeout(unavailableTimerRef.current);
      
      if (vehicleData === null) {
        unavailableTimerRef.current = setTimeout(() => {
          setShowUnavailable(true);
        }, 10000);
      }
    }

    return () => {
      if (loadingTimerRef.current) clearTimeout(loadingTimerRef.current);
      if (unavailableTimerRef.current) clearTimeout(unavailableTimerRef.current);
    };
  }, [open, vehicleData]);

  useEffect(() => {
    if (vehicleData?.urlMainImage) {
      const baseUrl = vehicleData.urlMainImage.replace(/\/\d+\/large$/, "");
      setImages([0, 1, 2, 3, 4, 5].map((index) => `${baseUrl}/${index}/large`));
    } else {
      setImages([defaultImage]);
    }
  }, [vehicleData]);

  useEffect(() => {
    if (showFullScreenGallery && vehicleData?.urlMainImage && !fullScreenImage) {
      const baseUrl = vehicleData.urlMainImage.replace(/\/\d+\/large$/, "");
      const firstImage = `${baseUrl}/0/large`;
      setFullScreenImage(firstImage);
    }
  }, [showFullScreenGallery, vehicleData, fullScreenImage]);

  const shouldShowUnavailable = !isLoading && (
    (vehicleData === null && showUnavailable) ||
    vehicleData?.available === false
  );

  const handleCloseFullScreen = () => {
    setFullScreenImage(null);
    if (showFullScreenGallery) {
      handleClose();
    }
  };

  // Completely rewrite the handleDialogClick function
  const handleDialogClick = (e) => {
    // Only stop propagation, don't prevent default
    e.stopPropagation();
  };

  // Add a new function to handle dialog content clicks
  const handleContentClick = (e) => {
    // Do nothing - this is just to capture clicks within the dialog content
    // without any side effects
  };

  if (shouldShowUnavailable) {
    return (
      <ThemeProvider theme={theme}>
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth
          maxWidth="sm"
          sx={{
            '& .MuiDialog-paper': {
              borderRadius: { xs: 0, md: '16px' },
              m: { xs: 0, md: '2vh' },
              overflow: 'hidden'
            },
            zIndex: 9999
          }}
        >
          <StyledDialogTitle>
            <Typography variant="h6" sx={{ fontWeight: 700, color: theme.palette.v2.textPrimary }}>
              Dettagli del Veicolo
            </Typography>
            <IconButton
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'white'
              }}
            >
              <CloseIcon />
            </IconButton>
          </StyledDialogTitle>
          <DialogContent sx={{ p: { xs: 2, md: 4 }, minHeight: '300px' }}>
            <Alert 
              severity="info"
              variant="filled"
              sx={{
                width: '100%',
                height: '100%',
                minHeight: '200px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                background: theme.palette.v2.primary,
                borderRadius: '16px',
                p: 4
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: 700, mb: 2, color: theme.palette.v2.textPrimary }}>
                Auto non più disponibile
              </Typography>
              <Typography variant="body1" sx={{ color: theme.palette.v2.textPrimary }}>
                Ci dispiace, ma questo veicolo non è più disponibile nel nostro catalogo.
              </Typography>
            </Alert>
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    );
  }

  // Desktop layout
  const DesktopLayout = () => (
    <Box sx={{ height: '100%', display: 'flex', position: 'relative', overflow: 'hidden' }}>
      {/* Left side - Image Gallery (40% width) */}
      <Box sx={{ 
        width: '50%', 
        height: '100%',
        backgroundColor: '#f1f5f5',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <ImageGallery
          images={images}
          onError={handleImageError}
          retailPrice={vehicleData?.retailPrice}
          salePrice={vehicleData?.salePrice}
          onFullScreen={setFullScreenImage}
          isDesktop={true}
        />
      </Box>

      {/* Right side - Details (60% width) */}
      <Box sx={{ 
        width: '50%', 
        height: '100%',
        backgroundColor: '#ffffff',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        borderLeft: '1px solid rgba(0, 0, 0, 0.08)'
      }}>
        <Tabs
          value={tabIndex}
          onChange={(_, newValue) => {
            trackChatEvent.viewVehicleTab(vehicleData?.title, newValue === 0 ? 'info' : 'accessories');
            setTabIndex(newValue);
          }}
          centered
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            minHeight: '48px',
            bgcolor: 'background.paper'
          }}
        >
          <StyledTab label="Informazioni Generali" />
          <StyledTab label="Accessori" />
        </Tabs>

        <Box sx={{ flexGrow: 1, position: 'relative', overflow: 'hidden' }}>
          <TabPanel value={tabIndex} index={0}>
            <CustomScrollbar>
              <Box sx={{ p: 3 }}>
                <GeneralInfo vehicleData={vehicleData} />
              </Box>
            </CustomScrollbar>
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            <CustomScrollbar>
              <Box sx={{ p: 3 }}>
                <Accessories
                  standardAccessories={vehicleData?.standardAccessories}
                  optionalAccessories={vehicleData?.optionalAccessories}
                  isDesktop={true}
                />
              </Box>
            </CustomScrollbar>
          </TabPanel>
        </Box>
      </Box>
    </Box>
  );

  // Mobile layout (existing layout)
  const MobileLayout = () => (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', position: 'relative', overflow: 'hidden' }}>
      <motion.div
        initial={false}
        animate={{
          height: isExpanded ? "0%" : "55%",
          opacity: isExpanded ? 0 : 1,
          display: isExpanded ? "none" : "flex"
        }}
        transition={{ duration: 0.4, ease: [0.4, 0, 0.2, 1] }}
        style={{
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#f1f5f9',
          position: 'relative'
        }}
      >
        <ImageGallery
          images={images}
          onError={handleImageError}
          retailPrice={vehicleData?.retailPrice}
          salePrice={vehicleData?.salePrice}
          onFullScreen={setFullScreenImage}
        />
      </motion.div>

      <motion.div
        initial={false}
        animate={{
          height: isExpanded ? "100%" : "45%",
          y: 0
        }}
        transition={{ duration: 0.4, ease: [0.4, 0, 0.2, 1] }}
        style={{
          position: 'relative',
          backgroundColor: '#ffffff',
          borderTopLeftRadius: '24px',
          borderTopRightRadius: '24px',
          boxShadow: '0 -4px 20px rgba(0, 0, 0, 0.1)',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden'
        }}
      >
        <Box
          onClick={() => setIsExpanded(!isExpanded)}
          sx={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            py: 1,
            borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
            transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
            '&:hover': {
              bgcolor: 'rgba(37, 99, 235, 0.04)'
            }
          }}
        >
          <motion.div
            animate={{ rotate: isExpanded ? 180 : 0 }}
            transition={{ duration: 0.3 }}
          >
            {isExpanded ? <ExpandMore /> : <ExpandLess />}
          </motion.div>
        </Box>

        <Tabs
          value={tabIndex}
          onChange={(_, newValue) => {
            trackChatEvent.viewVehicleTab(vehicleData?.title, newValue === 0 ? 'info' : 'accessories');
            setTabIndex(newValue);
          }}
          centered
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            minHeight: '48px',
            bgcolor: 'background.paper'
          }}
        >
          <StyledTab label="Informazioni Generali" />
          <StyledTab label="Accessori" />
        </Tabs>

        <Box sx={{ flexGrow: 1, position: 'relative', overflow: 'hidden' }}>
          <TabPanel value={tabIndex} index={0}>
            <CustomScrollbar>
              <Box sx={{ p: 3 }}>
                <GeneralInfo vehicleData={vehicleData} />
              </Box>
            </CustomScrollbar>
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            <CustomScrollbar>
              <Box sx={{ p: 3 }}>
                <Accessories
                  standardAccessories={vehicleData?.standardAccessories}
                  optionalAccessories={vehicleData?.optionalAccessories}
                />
              </Box>
            </CustomScrollbar>
          </TabPanel>
        </Box>
      </motion.div>
    </Box>
  );

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        ref={dialogRef}
        open={open}
        onClose={handleClose}
        onClick={handleDialogClick}
        fullScreen={window.innerWidth <= 768}
        container={document.getElementById('portal-root')}
        sx={{
          '& .MuiDialog-paper': {
            width: { xs: '100vw', md: 'min(90vw, 1400px)' },
            height: { xs: '100vh', md: 'min(90vh, 900px)' },
            minWidth: { md: '800px' },
            minHeight: { md: '600px' },
            maxWidth: { md: '1400px' },
            maxHeight: window.innerWidth <= 768 ? '100%' : '90vh',
            m: { xs: 0, md: '2vh' },
            overflow: 'hidden',
            borderRadius: { xs: 0, md: '24px' },
            bgcolor: '#f8fafc',
          },
          '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.6)'
          }
        }}
      >
        <StyledDialogTitle>
          <Typography variant="h6" sx={{ fontWeight: 700, color: theme.palette.v2.textPrimary }}>
            Dettagli del Veicolo
          </Typography>
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
              transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
              '&:hover': {
                bgcolor: 'rgba(255, 255, 255, 0.1)',
                transform: 'rotate(90deg)'
              }
            }}
          >
            <CloseIcon />
          </IconButton>
        </StyledDialogTitle>

        <DialogContent 
          sx={{ p: 0 }} 
          onClick={handleContentClick}
        >
          {isLoading ? (
            <LoadingSkeleton />
          ) : (
            isDesktop ? <DesktopLayout /> : <MobileLayout />
          )}
        </DialogContent>
      </Dialog>

      <Dialog
        open={Boolean(fullScreenImage)}
        onClose={handleCloseFullScreen}
        onClick={handleDialogClick}
        fullScreen
        container={document.getElementById('portal-root')}
        sx={{
          '& .MuiDialog-paper': {
            bgcolor: 'black',
          },
          '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.9)'
          }
        }}
      >
        <IconButton
          onClick={() => {
            const currentIndex = parseInt(fullScreenImage.match(/\/(\d+)\/large$/)?.[1] || 0);
            const newIndex = (currentIndex - 1 + 6) % 6;
            const baseUrl = fullScreenImage.replace(/\/\d+\/large$/, "");
            setFullScreenImage(`${baseUrl}/${newIndex}/large`);
          }}
          sx={{
            position: 'absolute',
            left: 16,
            color: 'white',
            zIndex: 1,
          }}
        >
          <ArrowBackIos />
        </IconButton>

        <IconButton
          onClick={() => {
            const currentIndex = parseInt(fullScreenImage.match(/\/(\d+)\/large$/)?.[1] || 0);
            const newIndex = (currentIndex + 1) % 6;
            const baseUrl = fullScreenImage.replace(/\/\d+\/large$/, "");
            setFullScreenImage(`${baseUrl}/${newIndex}/large`);
          }}
          sx={{
            position: 'absolute',
            right: 16,
            color: 'white',
            zIndex: 1,
          }}
        >
          <ArrowForwardIos />
        </IconButton>

        <IconButton
          onClick={handleCloseFullScreen}
          sx={{
            position: 'absolute',
            right: 16,
            top: 16,
            color: 'white',
            zIndex: 1,
            transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
            '&:hover': {
              bgcolor: 'rgba(255, 255, 255, 0.1)',
              transform: 'rotate(90deg)'
            }
          }}
        >
          <CloseIcon />
        </IconButton>

        {fullScreenImage && (
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
            transition={{ duration: 0.4, ease: [0.4, 0, 0.2, 1] }}
            style={{ 
              width: '100%', 
              height: '100%', 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'center',
              padding: '32px'
            }}
          >
            <CardMedia
              component="img"
              image={fullScreenImage}
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                borderRadius: '16px',
                boxShadow: '0 24px 48px rgba(0, 0, 0, 0.2)'
              }}
            />
          </motion.div>
        )}
      </Dialog>
    </ThemeProvider>
  );
};

export default VehicleDialog;