// SearchPanel.jsx
// -------------------------------------------------------------
// 1) We remove the text portion from display in the SearchPanel
//    so that only the carousel items appear here.
// 2) The main text remains displayed in the ChatBody (desktop only).
// -------------------------------------------------------------
import React from "react";
import { Box, IconButton, Typography, Button, Tooltip } from "@mui/material";
import { Close as CloseIcon, KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import SearchPanelCarousel from "./SearchPanelCarousel";
import CustomScrollbar from './CustomScrollbar';
import { styled } from '@mui/material/styles';
import { motion, AnimatePresence } from 'framer-motion';

/**
 * Streamlined search panel that focuses on the carousel visualization
 * with minimal UI elements for better user experience.
 */
const SearchPanelWrapper = styled(motion.div)(({ theme }) => ({
  width: "min(900px, 95vw)",
  height: "calc(100% - 20px)",
  backgroundColor: "rgba(255, 255, 255, 0.85)",
  backdropFilter: "blur(10px)",
  borderRadius: "20px",
  boxShadow: "0 8px 24px rgba(0, 0, 0, 0.08)",
  display: "flex",
  flexDirection: "column",
  overflow: "visible",
  position: "relative",
  border: "1px solid",
  borderColor: "rgba(0, 0, 0, 0.1)",
  marginTop: "20px", // Connect visually with chat component
}));

// Mac-style close button
const CloseButton = styled(motion.button)(({ theme }) => ({
  position: 'absolute',
  top: '15px',
  left: '15px',
  width: '28px', // Increased size
  height: '28px', // Increased size
  borderRadius: '50%',
  backgroundColor: '#ff5f57',
  border: 'none',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1000,
  boxShadow: '0 2px 4px rgba(0,0,0,0.3)',
  '&:hover': {
    backgroundColor: '#ff3b30',
    '&::before, &::after': {
      opacity: 1,
    }
  },
  '&::before, &::after': {
    content: '""',
    position: 'absolute',
    width: '14px', // Increased size
    height: '2px',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    opacity: 0,
    transition: 'opacity 0.2s ease',
  },
  '&::before': {
    transform: 'rotate(45deg)',
  },
  '&::after': {
    transform: 'rotate(-45deg)',
  }
}));

const ResultCounter = styled(motion.div)(({ theme }) => ({
  backgroundColor: '#4caf50', // Bright green color as shown in the image
  color: 'white',
  padding: '8px 20px',
  borderRadius: '20px', // Fully rounded corners as shown in the image
  fontWeight: 600,
  fontSize: '1rem',
  boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
  zIndex: 10,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: '180px',
  textAlign: 'center',
  // Remove position absolute to allow proper centering in the flex container
}));

const NavigationButton = styled(motion.button)(({ theme }) => ({
  minWidth: '40px', // Slightly smaller
  width: '40px', // Slightly smaller
  height: '40px', // Slightly smaller
  borderRadius: '50%',
  padding: 0,
  backgroundColor: '#f5f5f5', // Light gray background as shown in the image
  color: '#757575', // Gray color for the arrows
  border: 'none',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  '&:hover': {
    backgroundColor: '#e0e0e0',
  },
  '&:disabled': {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    color: 'rgba(0, 0, 0, 0.26)',
    cursor: 'not-allowed',
    boxShadow: 'none',
  },
  '& svg': {
    fontSize: '24px',
  },
  transition: 'all 0.2s ease',
  margin: '0 8px', // Add margin to position closer to the counter
}));

const SearchPanel = ({
  onClose,
  searchMessages = [],
  onNavigateSearch,
  currentIndex,
  totalCount,
  isLoading,
}) => {
  const hasMultiple = totalCount > 1;

  // Filter messages to only show those with car carousel items
  const carouselMessages = searchMessages.filter(
    msg => msg.carouselItems && Array.isArray(msg.carouselItems) && msg.carouselItems.length > 0
  );

  // Get the current message being displayed
  const currentMessage = carouselMessages[currentIndex] || null;
  const currentItemCount = currentMessage?.carouselItems?.length || 0;
  
  // Calculate total vehicles across all pages
  const totalVehicles = carouselMessages.reduce((total, msg) => {
    return total + (msg.carouselItems?.length || 0);
  }, 0);

  return (
    <AnimatePresence>
      <SearchPanelWrapper
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 20 }}
        transition={{ 
          type: "spring", 
          stiffness: 300, 
          damping: 30,
          duration: 0.4 
        }}
      >
        {/* Mac-style close button */}
        <Tooltip title="Chiudi pannello di ricerca" placement="right">
          <CloseButton
            onClick={onClose}
            aria-label="close search panel"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          />
        </Tooltip>

        {/* Centered navigation and counter container at the top */}
        <Box 
          sx={{ 
            display: "flex", 
            justifyContent: "center", // Center the content
            alignItems: "center",
            width: "100%",
            mt: 2,
            mb: 2,
            zIndex: 10,
          }}
        >
          {/* Only show navigation buttons if there are multiple results */}
          {hasMultiple && (
            <Tooltip title="Risultato precedente" placement="bottom">
              <Box>
                <NavigationButton
                  onClick={() => onNavigateSearch(-1)}
                  disabled={currentIndex <= 0}
                  aria-label="Previous result"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <KeyboardArrowLeft />
                </NavigationButton>
              </Box>
            </Tooltip>
          )}
          
          {/* Prominent result counter in the center */}
          <ResultCounter
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
          >
            Pagina {currentIndex + 1}/{totalCount} • {totalVehicles} veicoli totali
          </ResultCounter>
          
          {/* Only show navigation buttons if there are multiple results */}
          {hasMultiple && (
            <Tooltip title="Risultato successivo" placement="bottom">
              <Box>
                <NavigationButton
                  onClick={() => onNavigateSearch(1)}
                  disabled={currentIndex >= totalCount - 1}
                  aria-label="Next result"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <KeyboardArrowRight />
                </NavigationButton>
              </Box>
            </Tooltip>
          )}
        </Box>

        {/* Results List - Updated for better visualization */}
        <Box 
          sx={{ 
            flex: 1, 
            position: 'relative', 
            overflow: 'hidden',
            // Add a subtle gradient at the top for better visual connection
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              height: '10px',
              background: 'linear-gradient(to bottom, rgba(0,0,0,0.05), transparent)',
              zIndex: 5,
              pointerEvents: 'none',
            }
          }}
        >
          <CustomScrollbar>
            <Box
              sx={{
                p: { xs: 2, sm: 3 },
                height: '100%',
              }}
              component={motion.div}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.1 }}
            >
              {carouselMessages.length === 0 ? (
                <Typography 
                  variant="body1" 
                  color="textSecondary" 
                  sx={{ 
                    textAlign: 'center', 
                    mt: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 2,
                  }}
                  component={motion.div}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.2 }}
                >
                  <Box 
                    component="img" 
                    src="https://files.aitomotivelab.com/Mia_Logo_Black.png"
                    alt="MIA Logo"
                    sx={{ width: 80, opacity: 0.7, mb: 2 }}
                  />
                  Nessun risultato ancora
                </Typography>
              ) : (
                carouselMessages.map((msg, idx) => (
                  <Box
                    key={idx}
                    sx={{
                      backgroundColor: "rgba(255, 255, 255, 0.7)",
                      mb: 3,
                      p: { xs: 2, sm: 3 },
                      borderRadius: "12px",
                      boxShadow: "0 2px 6px rgba(0,0,0,0.08)",
                      transition: "all 0.3s ease",
                      "&:hover": {
                        boxShadow: "0 4px 12px rgba(0,0,0,0.12)",
                        backgroundColor: "rgba(255, 255, 255, 0.8)",
                      },
                    }}
                    component={motion.div}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: idx * 0.1 }}
                  >
                    <SearchPanelCarousel items={msg.carouselItems} />
                  </Box>
                ))
              )}
            </Box>
          </CustomScrollbar>
        </Box>
      </SearchPanelWrapper>
    </AnimatePresence>
  );
};

export default SearchPanel;