import { EmojiObjects, ExpandLess, ExpandMore, Warning, CheckCircle } from "@mui/icons-material";
import { Box, Button, Grid, Typography, Alert, Paper, Divider, Chip, useTheme, useMediaQuery } from "@mui/material";
import { motion } from "framer-motion";
import React, { useState } from "react";

const AccessorySection = ({ title, accessories = [], expanded, onToggle, isDesktop = false }) => {
  const theme = useTheme();
  
  // Handle null, undefined, or empty accessories
  if (!accessories?.length) return null;

  // Function to split accessories into columns
  const splitIntoColumns = (accessories) => {
    try {
      // For desktop, use 4 columns, for mobile use 3
      const columnCount = isDesktop ? 4 : 3;
      const columnLength = Math.ceil(accessories.length / columnCount);
      
      let columns = [];
      for (let i = 0; i < columnCount; i++) {
        columns.push(accessories.slice(i * columnLength, (i + 1) * columnLength));
      }
      return columns;
    } catch (error) {
      console.error("Error splitting columns:", error);
      return [[], [], []];
    }
  };

  const columns = splitIntoColumns(accessories);
  const displayedColumns = expanded
    ? columns
    : columns.map((col) => col.slice(0, isDesktop ? 4 : 3));

  return (
    <Paper
      elevation={isDesktop ? 1 : 0}
      sx={{
        mb: 4,
        p: isDesktop ? 3 : 0,
        borderRadius: isDesktop ? '16px' : 0,
        backgroundColor: isDesktop ? 'rgba(255, 255, 255, 0.8)' : 'transparent',
        transition: 'all 0.3s ease',
        '&:hover': {
          boxShadow: isDesktop ? '0 4px 20px rgba(0, 0, 0, 0.08)' : 'none',
          backgroundColor: isDesktop ? 'rgba(255, 255, 255, 0.95)' : 'transparent',
        }
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography
          variant={isDesktop ? "h5" : "h6"}
          color="secondary"
          sx={{ 
            display: "flex", 
            alignItems: "center",
            fontWeight: isDesktop ? 700 : 600,
          }}
        >
          <EmojiObjects sx={{ mr: 1, color: theme.palette.secondary.main }} /> {title}
          {isDesktop && (
            <Chip 
              label={accessories.length} 
              size="small" 
              color="secondary" 
              sx={{ ml: 1, fontWeight: 'bold' }} 
            />
          )}
        </Typography>
        <Button
          onClick={onToggle}
          endIcon={expanded ? <ExpandLess /> : <ExpandMore />}
          variant={isDesktop ? "outlined" : "text"}
          color="secondary"
          sx={{ 
            textTransform: "none", 
            borderRadius: "20px",
            fontWeight: 600,
            '&:hover': {
              backgroundColor: isDesktop ? 'rgba(156, 39, 176, 0.08)' : 'rgba(0, 0, 0, 0.04)'
            }
          }}
        >
          {expanded ? "Nascondi" : "Mostra tutto"}
        </Button>
      </Box>
      
      {isDesktop && <Divider sx={{ mb: 3 }} />}
      
      <Grid container spacing={isDesktop ? 3 : 2}>
        {displayedColumns.map((column, colIndex) => (
          <Grid item xs={12} md={isDesktop ? 3 : 4} key={colIndex}>
            {column.map((accessory, index) => (
              <Box
                key={index}
                component={motion.div}
                initial={isDesktop ? { opacity: 0, y: 10 } : {}}
                animate={isDesktop ? { opacity: 1, y: 0 } : {}}
                transition={{ 
                  delay: index * 0.05 + colIndex * 0.1,
                  duration: 0.3
                }}
                sx={{ 
                  py: 1.5, 
                  px: isDesktop ? 1 : 0,
                  borderBottom: "1px solid #e0e0e0",
                  '&:last-child': {
                    borderBottom: 'none'
                  },
                  '&:hover': {
                    backgroundColor: isDesktop ? 'rgba(0, 0, 0, 0.02)' : 'transparent',
                  }
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: isDesktop ? 'text.primary' : 'text.secondary',
                    fontWeight: isDesktop ? 500 : 400,
                    lineHeight: 1.5,
                  }}
                >
                  {accessory}
                </Typography>
              </Box>
            ))}
          </Grid>
        ))}
      </Grid>
      
      {!expanded && accessories.length > (isDesktop ? 16 : 9) && (
        <Box 
          sx={{ 
            mt: 2, 
            textAlign: 'center',
            color: 'text.secondary',
            fontStyle: 'italic',
            fontSize: '0.875rem'
          }}
        >
          + altri {accessories.length - (isDesktop ? 16 : 9)} accessori
        </Box>
      )}
    </Paper>
  );
};

const EmptyState = ({ isDesktop = false }) => (
  <Box sx={{ 
    textAlign: 'center', 
    py: 4,
    px: 2,
    backgroundColor: isDesktop ? 'white' : 'rgba(0, 0, 0, 0.02)',
    borderRadius: isDesktop ? 4 : 2,
    boxShadow: isDesktop ? '0 4px 20px rgba(0, 0, 0, 0.05)' : 'none',
  }}>
    <EmojiObjects sx={{ fontSize: isDesktop ? 64 : 48, color: 'action.disabled', mb: 2 }} />
    <Typography variant={isDesktop ? "h5" : "h6"} color="text.secondary" gutterBottom>
      Nessun accessorio disponibile
    </Typography>
    <Typography variant="body2" color="text.secondary">
      Non sono presenti accessori per questo veicolo.
    </Typography>
  </Box>
);

const ErrorState = ({ error, isDesktop = false }) => (
  <Alert 
    severity="error" 
    sx={{ 
      mb: 2,
      p: isDesktop ? 3 : 2,
      borderRadius: isDesktop ? 2 : 1,
    }}
    icon={<Warning />}
    variant={isDesktop ? "filled" : "standard"}
  >
    Si è verificato un errore nel caricamento degli accessori: {error.message}
  </Alert>
);

const Accessories = ({ standardAccessories = [], optionalAccessories = [], isDesktop }) => {
  const [standardExpanded, setStandardExpanded] = useState(false);
  const [optionalExpanded, setOptionalExpanded] = useState(false);
  const [error, setError] = useState(null);
  const theme = useTheme();
  const isDesktopMedia = useMediaQuery(theme.breakpoints.up('md'));
  
  // Use the prop if provided, otherwise use the media query
  const actualIsDesktop = isDesktop !== undefined ? isDesktop : isDesktopMedia;

  try {
    // Check if both arrays are empty or undefined
    if ((!standardAccessories?.length && !optionalAccessories?.length) || 
        (standardAccessories === undefined && optionalAccessories === undefined)) {
      return (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <EmptyState isDesktop={actualIsDesktop} />
        </motion.div>
      );
    }

    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        {error && <ErrorState error={error} isDesktop={actualIsDesktop} />}
        
        {actualIsDesktop && (
          <Typography 
            variant="h4" 
            sx={{ 
              mb: 3, 
              fontWeight: 700,
              color: theme.palette.text.primary,
              borderBottom: '2px solid',
              borderColor: theme.palette.secondary.main,
              pb: 1,
              display: 'inline-block'
            }}
          >
            Accessori del Veicolo
          </Typography>
        )}
        
        <AccessorySection
          title="Dotazioni di serie"
          accessories={standardAccessories}
          expanded={standardExpanded}
          onToggle={() => setStandardExpanded(!standardExpanded)}
          isDesktop={actualIsDesktop}
        />

        <AccessorySection
          title="Optional inclusi"
          accessories={optionalAccessories}
          expanded={optionalExpanded}
          onToggle={() => setOptionalExpanded(!optionalExpanded)}
          isDesktop={actualIsDesktop}
        />
      </motion.div>
    );
  } catch (err) {
    setError(err);
    return <ErrorState error={err} isDesktop={actualIsDesktop} />;
  }
};

export default Accessories;