import React, { useState, useEffect, useRef, useCallback } from "react";
import { FiSend } from "react-icons/fi";
import { BsLightbulb, BsLightbulbFill } from 'react-icons/bs';
import "./ChatFooter.css";
import { StyledComponentsThemWrapper } from "../StyledComponentsThemWrapper";
import { getCTATypeFromUrl, getCTAConfig } from "../../utils/urlPatterns";

const ChatFooter = ({ 
  onSendMessage, 
  onSendFile,
  onSendAudio,
  isLoading,
  isHumanControlActive,
  inputValue: propInputValue,
  setInputValue: propSetInputValue,
  predictedQuestions = [],
  onQuestionClick,
  isMobile,
  onQuestionsVisibilityChange,
  isCarSearchOpen,
  areQuestionsVisible,
  setAreQuestionsVisible,
  isCompactMode,
  openedByTrigger,
  onCTAClick
}) => {
  // Use local state if props are not provided
  const [localInputValue, setLocalInputValue] = useState('');
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [disableAutoFocus, setDisableAutoFocus] = useState(false);
  const [alternatePlaceholder, setAlternatePlaceholder] = useState(false);
  const [ctaPlaceholder, setCtaPlaceholder] = useState('');
  const [currentUrl, setCurrentUrl] = useState(window.location.href);
  const placeholderTimerRef = useRef(null);
  
  // Determine if we have valid questions to show
  const hasValidQuestions = Array.isArray(predictedQuestions) && predictedQuestions.length > 0;
  
  const messageInputRef = useRef(null);
  const focusIntervalRef = useRef(null);
  
  // Use either prop state or local state
  const inputValue = propInputValue !== undefined ? propInputValue : localInputValue;
  const setInputValue = propSetInputValue || setLocalInputValue;

  // Add a ref to track previous loading state
  const prevLoadingRef = useRef(isLoading);
  const prevHumanControlRef = useRef(isHumanControlActive);
  
  // Add a ref for the send button
  const sendButtonRef = useRef(null);
  
  // Calculate if we are in reduced mobile mode (compact mode or opened by trigger)
  const isReducedMode = isMobile && (isCompactMode || openedByTrigger);

  // Update CTA placeholder when the component mounts or when the URL changes
  useEffect(() => {
    // Get initial CTA placeholder
    const updateCtaPlaceholder = () => {
      const ctaType = getCTATypeFromUrl(window.location.href);
      const { text } = getCTAConfig(ctaType);
      setCtaPlaceholder(text);
    };
    
    // Set initial placeholder
    updateCtaPlaceholder();
    
    // Set up listener for URL changes
    const handleLocationChange = () => {
      const newUrl = window.location.href;
      if (newUrl !== currentUrl) {
        setCurrentUrl(newUrl);
        updateCtaPlaceholder();
      }
    };
    
    // Listen for popstate events (browser back/forward)
    window.addEventListener('popstate', handleLocationChange);
    
    // Set up interval to check for programmatic URL changes
    const urlCheckInterval = setInterval(() => {
      const newUrl = window.location.href;
      if (newUrl !== currentUrl) {
        setCurrentUrl(newUrl);
        updateCtaPlaceholder();
      }
    }, 1000); // Check every second
    
    return () => {
      window.removeEventListener('popstate', handleLocationChange);
      clearInterval(urlCheckInterval);
    };
  }, []);

  // Set up alternating placeholder timer for compact mode
  useEffect(() => {
    // Only activate alternating placeholders in reduced mobile mode AND when input is empty
    if (isReducedMode && !inputValue.trim()) {
      // Clear existing timer if any
      if (placeholderTimerRef.current) {
        clearInterval(placeholderTimerRef.current);
      }
      
      // Set up the timer to toggle placeholder every 5 seconds
      placeholderTimerRef.current = setInterval(() => {
        setAlternatePlaceholder(prev => !prev);
      }, 5000);
      
      // Start with the default placeholder
      setAlternatePlaceholder(false);
    } else {
      // If not in reduced mode or user is typing, clear any existing timer
      if (placeholderTimerRef.current) {
        clearInterval(placeholderTimerRef.current);
        placeholderTimerRef.current = null;
      }
      
      // Reset to default placeholder when input is not empty
      if (inputValue.trim()) {
        setAlternatePlaceholder(false);
      }
    }
    
    // Cleanup
    return () => {
      if (placeholderTimerRef.current) {
        clearInterval(placeholderTimerRef.current);
        placeholderTimerRef.current = null;
      }
    };
  }, [isReducedMode, inputValue]);

  // Add effect to reset CTA mode when user starts typing
  useEffect(() => {
    // If user is typing, immediately disable CTA mode
    if (inputValue.trim() && alternatePlaceholder) {
      setAlternatePlaceholder(false);
    }
  }, [inputValue, alternatePlaceholder]);

  // Choose the appropriate placeholder based on mode and alternation state
  const getPlaceholder = () => {
    if (isHumanControlActive) {
      return "Scrivi un messaggio...";
    }
    
    if (isReducedMode && alternatePlaceholder) {
      return ctaPlaceholder;
    }
    
    return "Scrivi un messaggio...";
  };

  // Function to temporarily disable auto-focus
  const temporarilyDisableAutoFocus = useCallback(() => {
    setDisableAutoFocus(true);
    
    // Re-enable after 10 seconds
    setTimeout(() => {
      setDisableAutoFocus(false);
    }, 10000);
  }, []);

  // Function to scroll to the bottom of the chat
  const scrollToBottom = useCallback(() => {
    if (isMobile) {
      // Find the chat messages container
      const chatMessagesContainer = document.querySelector('.chat-messages');
      if (chatMessagesContainer) {
        // Find the last message or typing indicator
        const lastMessage = chatMessagesContainer.querySelector('.message-container:last-child') || 
                           chatMessagesContainer.querySelector('.loading-message');
        
        // If we found a last message, scroll it into view
        if (lastMessage) {
          lastMessage.scrollIntoView({ behavior: 'smooth', block: 'center' });
        } else {
          // Fallback to normal scrolling if no message is found
          chatMessagesContainer.scrollTo({
            top: chatMessagesContainer.scrollHeight,
            behavior: 'smooth'
          });
        }
      }
    }
  }, [isMobile]);

  // Add event listener for clicks on other inputs
  useEffect(() => {
    const handleDocumentClick = (e) => {
      // If the clicked element is an input or textarea but not our message input
      if (
        (e.target.tagName === 'INPUT' || 
         e.target.tagName === 'TEXTAREA' || 
         e.target.getAttribute('contenteditable') === 'true') &&
        e.target !== messageInputRef.current
      ) {
        temporarilyDisableAutoFocus();
      }
    };
    
    document.addEventListener('click', handleDocumentClick);
    
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [temporarilyDisableAutoFocus]);

  // Effect to handle loading state changes
  useEffect(() => {
    // If loading state changes from true to false, focus on the input
    if (prevLoadingRef.current && !isLoading) {
      // Focus on input after a short delay to ensure DOM is updated
      setTimeout(() => {
        if (messageInputRef.current) {
          messageInputRef.current.focus();
        }
      }, 100);
    }
    
    // If loading state changes from false to true, focus on the typing indicator
    if (!prevLoadingRef.current && isLoading && isMobile) {
      // Find the typing indicator
      const typingIndicator = document.querySelector('.loading-message') || 
                             document.querySelector('.ai-typing-container');
      
      if (typingIndicator) {
        setTimeout(() => {
          typingIndicator.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 100);
      }
    }
    
    // Update previous loading state
    prevLoadingRef.current = isLoading;
  }, [isLoading, isMobile]);

  // Effect to handle human control state changes
  useEffect(() => {
    // If human control state changes, update the UI
    if (prevHumanControlRef.current !== isHumanControlActive) {
      // Focus on input after a short delay to ensure DOM is updated
      setTimeout(() => {
        if (messageInputRef.current) {
          messageInputRef.current.focus();
        }
      }, 100);
    }
    
    // Update previous human control state
    prevHumanControlRef.current = isHumanControlActive;
  }, [isHumanControlActive]);

  // Setup focus interval to periodically focus on the input
  useEffect(() => {
    // Define the setup function
    const setupInterval = () => {
      // Clear any existing interval
      if (focusIntervalRef.current) {
        clearInterval(focusIntervalRef.current);
      }
      
      // Set up a new interval to focus on the input every 5 seconds
      focusIntervalRef.current = setInterval(() => {
        // Only focus if the input is not already focused, the page is visible,
        // and no other element has focus (to prevent stealing focus from other components)
        if (
          messageInputRef.current && 
          !isInputFocused && 
          document.visibilityState === 'visible' &&
          // Check if the active element is not an input, textarea, or contenteditable
          !['INPUT', 'TEXTAREA'].includes(document.activeElement?.tagName) &&
          !document.activeElement?.getAttribute('contenteditable') &&
          // Don't focus if auto-focus is disabled
          !disableAutoFocus
        ) {
          messageInputRef.current.focus();
        }
      }, 5000);
    };
    
    // Make the setup function available globally
    window.setupFocusInterval = setupInterval;
    
    // Set up the interval initially
    setupInterval();
    
    // Clean up the interval when the component unmounts
    return () => {
      if (focusIntervalRef.current) {
        clearInterval(focusIntervalRef.current);
      }
    };
  }, [isInputFocused, disableAutoFocus]);

  // Handle sending a message
  const handleSendMessage = () => {
    if (inputValue.trim() && onSendMessage) {
      onSendMessage(inputValue.trim());
      setInputValue('');
      
      // Reset typing state
      if (window.chatBodyInstance && window.chatBodyInstance.setTyping) {
        window.chatBodyInstance.setTyping(false);
      }
      
      // Focus on the input after sending a message
      setTimeout(() => {
        if (messageInputRef.current) {
          messageInputRef.current.focus();
        }
      }, 100);
    }
  };

  // Handle key press events
  const handleKeyDown = (e) => {
    // If Enter is pressed without Shift, send the message
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      
      // Reset typing state before sending the message
      if (window.chatBodyInstance && window.chatBodyInstance.setTyping) {
        window.chatBodyInstance.setTyping(false);
      }
      
      handleSendMessage();
    }
  };

  // Handle input changes
  const handleInputChange = (e) => {
    // Set the input value without triggering any scrolling
    setInputValue(e.target.value);
    
    // Prevent any auto-scrolling while typing by temporarily disabling it
    if (window.chatBodyInstance && window.chatBodyInstance.setTyping) {
      window.chatBodyInstance.setTyping(true);
    }
  };

  // Add a new function to handle clicks on the chat content
  const handleChatContentClick = useCallback((e) => {
    // Only proceed if we're showing the CTA
    if (isReducedMode && alternatePlaceholder) {
      // Check if the click was NOT on the CTA container or its children
      const ctaContainer = document.querySelector('.message-input-container.cta-active');
      
      // If we clicked outside the CTA container but still in the chat
      if (ctaContainer && !ctaContainer.contains(e.target)) {
        // Reset to normal input mode
        setAlternatePlaceholder(false);
        
        // If we have a message input ref, focus it to allow immediate typing
        if (messageInputRef.current) {
          setTimeout(() => {
            messageInputRef.current.focus();
          }, 100);
        }
      }
    }
  }, [isReducedMode, alternatePlaceholder]);

  // Add an effect to listen for clicks on the chat content
  useEffect(() => {
    // Find the chat container
    const chatContainer = document.querySelector('.ChatAndSearchContainer');
    
    if (chatContainer) {
      chatContainer.addEventListener('click', handleChatContentClick);
      
      // Cleanup
      return () => {
        chatContainer.removeEventListener('click', handleChatContentClick);
      };
    }
  }, [handleChatContentClick]);

  // Modify the main form render to make sure the click handler stops propagation
  const handleFormSubmit = (e) => {
    e.preventDefault();
    
    // Don't send message if in CTA mode
    if (isReducedMode && alternatePlaceholder) {
      // Stop event propagation to prevent triggering the chat content click handler
      e.stopPropagation();
      return false;
    }
    
    handleSendMessage();
  };

  // Handle input focus
  const handleInputFocus = (e) => {
    setIsInputFocused(true);
  };

  // Handle input blur
  const handleInputBlur = () => {
    setIsInputFocused(false);
    
    // Reset typing state when input loses focus
    if (window.chatBodyInstance && window.chatBodyInstance.setTyping) {
      window.chatBodyInstance.setTyping(false);
    }
  };

  // Update the preventZoom function to be less aggressive
  const preventZoom = (e) => {
    // Instead of changing the body style, just prevent default on the event
    // if it's a touch event that might cause zooming
    if (e.touches && e.touches.length > 1) {
      e.preventDefault();
    }
    
    // Set the viewport meta tag temporarily
    const viewportMeta = document.querySelector('meta[name="viewport"]');
    const originalContent = viewportMeta?.getAttribute('content');
    
    if (viewportMeta) {
      viewportMeta.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no');
      
      // Restore original viewport after input blur
      setTimeout(() => {
        if (originalContent) {
          viewportMeta.setAttribute('content', originalContent);
        }
      }, 1000);
    }
  };

  // Handle CTA click
  const handleCTAClick = (e) => {
    // Only trigger if we're showing the CTA placeholder
    if (isReducedMode && alternatePlaceholder && onCTAClick) {
      // Prevent default behavior and stop propagation
      e.preventDefault();
      e.stopPropagation();
      
      // Get current CTA type to pass to the handler
      const ctaType = getCTATypeFromUrl(window.location.href);
      
      // Use setTimeout to break event chain
      setTimeout(() => {
        onCTAClick(ctaType);
      }, 10);
      
      // Prevent any other click handlers from firing
      return false;
    }
  };

  // Handle CTA keyboard events (for accessibility)
  const handleCTAKeyDown = (e) => {
    // Only trigger if we're showing the CTA placeholder
    if (isReducedMode && alternatePlaceholder && onCTAClick && (e.key === 'Enter' || e.key === ' ')) {
      e.preventDefault();
      e.stopPropagation();
      
      // Get current CTA type to pass to the handler
      const ctaType = getCTATypeFromUrl(window.location.href);
      
      // Use setTimeout to break event chain
      setTimeout(() => {
        onCTAClick(ctaType);
      }, 10);
      
      return false;
    }
  };

  return (
    <StyledComponentsThemWrapper>
      <div className={`chat-footer ${isReducedMode ? 'reduced-mode' : ''}`}>
        {/* Input container */}
        <form className="footer-form" onSubmit={handleFormSubmit}>
          <div className="footer-input-container">
            {/* Message input */}
            <div 
              className={`message-input-container ${inputValue ? 'has-content' : ''} ${isLoading ? 'disabled' : ''} ${isReducedMode && alternatePlaceholder ? 'cta-active' : ''}`}
              onClick={isReducedMode && alternatePlaceholder ? handleCTAClick : undefined}
              onTouchStart={isReducedMode && alternatePlaceholder ? handleCTAClick : undefined}
              onKeyDown={isReducedMode && alternatePlaceholder ? handleCTAKeyDown : undefined}
              role={isReducedMode && alternatePlaceholder ? "button" : undefined}
              aria-label={isReducedMode && alternatePlaceholder ? getPlaceholder() : undefined}
              tabIndex={isReducedMode && alternatePlaceholder ? 0 : undefined}
              data-cta={isReducedMode && alternatePlaceholder ? getCTATypeFromUrl(window.location.href) : null}
            >
              <textarea
                className="message-input"
                placeholder={getPlaceholder()}
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                onFocus={(e) => {
                  handleInputFocus(e);
                  preventZoom(e);
                }}
                onBlur={handleInputBlur}
                disabled={isLoading || (isReducedMode && alternatePlaceholder)}
                ref={messageInputRef}
                rows={1}
                style={{ 
                  height: 'auto', 
                  minHeight: '24px', 
                  maxHeight: '120px',
                  fontSize: '16px' // Ensure font size is at least 16px
                }}
              />
            </div>
            
            {/* Send button with more visible icon */}
            <button
              type="submit"
              className="send-button"
              disabled={!inputValue.trim() || isLoading || (isReducedMode && alternatePlaceholder)}
              aria-label="Send message"
              ref={sendButtonRef}
            >
              <div className="send-icon-container">
                <FiSend size={isMobile ? 20 : 24} strokeWidth={3} />
              </div>
            </button>
          </div>
        </form>
      </div>
    </StyledComponentsThemWrapper>
  );
};

export default ChatFooter; 