import React, { useEffect, useState } from 'react';
import './ChatHeader.css';
import MobileCarSearch from './MobileCarSearch';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const ChatHeader = ({
  isMobile,
  toggleChat,
  toggleChatWidth,
  isChatWide,
  isLoading,
  onSendMessage,
  isHumanControlled,
  humanControlMessage,
  isOnline,
  toggleSearchPanel,
  onCarSearchOpen,
  onCarSearchClose,
  isCompactMode,
  toggleCompactMode,
  openedByTrigger,
}) => {
  const [dealerLogo, setDealerLogo] = useState(null);
  const [dealerName, setDealerName] = useState('AI Assistant');
  const [showCarSearch, setShowCarSearch] = useState(false);
  const [showTransition, setShowTransition] = useState(false);

  useEffect(() => {
    // Get dealer logo and name from localStorage
    const logo = localStorage.getItem("logo");
    const name = localStorage.getItem("name_chat");
    
    if (logo) setDealerLogo(logo);
    if (name) setDealerName(name);
  }, []);

  // Add animation effect when human control changes
  useEffect(() => {
    setShowTransition(true);
    const timer = setTimeout(() => {
      setShowTransition(false);
    }, 1000);
    
    return () => clearTimeout(timer);
  }, [isHumanControlled]);

  // Notify parent when car search panel visibility changes
  useEffect(() => {
    if (onCarSearchOpen && showCarSearch) {
      onCarSearchOpen();
    } else if (onCarSearchClose && !showCarSearch) {
      onCarSearchClose();
    }
  }, [showCarSearch, onCarSearchOpen, onCarSearchClose]);

  const handleSearchClick = () => {
    if (toggleSearchPanel) {
      toggleSearchPanel();
    }
  };

  const handleAIAssistClick = () => {
    setShowCarSearch(true);
  };

  const handleCarSearchSubmit = (searchData) => {
    setShowCarSearch(false);
    
    // Format the search data into a message
    const message = `Sto cercando un'auto con queste caratteristiche:
- Budget: ${searchData.budget}€
- Percorrenza annuale: ${searchData.annualKm} km
- Alimentazione preferita: ${searchData.fuelType}
${searchData.additionalInfo ? `- Note aggiuntive: ${searchData.additionalInfo}` : ''}`;
    
    // Send the message
    if (onSendMessage) {
      onSendMessage(message);
    }
  };

  return (
    <>
      <div className={`chat-header 
        ${isHumanControlled ? 'human-operator-active' : ''} 
        ${showTransition ? 'transitioning' : ''}
        ${(isCompactMode || openedByTrigger) && isMobile ? 'compact-mode' : ''}
      `}>
        <div className="chat-header-title">
          <div className="profile-image">
            {dealerLogo ? (
              <img src={dealerLogo} alt="Dealer Logo" />
            ) : (
              <img src="/assets/images/bot-avatar.png" alt="AI Assistant" />
            )}
            <span className={`status-indicator ${isOnline ? (isHumanControlled ? 'human-operator' : 'online') : 'offline'}`}></span>
          </div>
          <div className="title-container">
            <div className="name-and-badge">
              <span className="chat-bot-name">{dealerName || 'Assistente Auto'}</span>
              {isHumanControlled ? (
                <span className="operator-badge">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                  </svg>
                  Operatore umano
                </span>
              ) : (
                <span className="ai-badge">
                  <img 
                    src="https://files.aitomotivelab.com/Mia_Logo_Black.png" 
                    alt="MIA"
                  />
                  Assistente virtuale
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="chat-header-actions">
          {/* Add compact mode toggle for mobile */}
          {isMobile && toggleCompactMode && (
            <button 
              className="header-button compact-mode-button" 
              onClick={toggleCompactMode}
              aria-label={isCompactMode || openedByTrigger ? "Visualizzazione completa" : "Visualizzazione ridotta"}
              title={isCompactMode || openedByTrigger ? "Visualizzazione completa" : "Visualizzazione ridotta"}
            >
              {isCompactMode || openedByTrigger ? (
                <KeyboardArrowUpIcon style={{ width: 24, height: 24 }} />
              ) : (
                <KeyboardArrowDownIcon style={{ width: 24, height: 24 }} />
              )}
            </button>
          )}
        
          <button 
            className="header-button close-button" 
            onClick={toggleChat}
            aria-label="Chiudi"
            title="Chiudi"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </div>
      </div>
      
      {showCarSearch && (
        <MobileCarSearch 
          onClose={() => setShowCarSearch(false)} 
          onSubmit={handleCarSearchSubmit}
        />
      )}
    </>
  );
};

export default ChatHeader; 