import styled from 'styled-components';
import { useTheme } from '@mui/material';
import { StyledComponentsThemWrapper } from "./StyledComponentsThemWrapper";
import { useRef, useCallback, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Box } from '@mui/material';

const ScrollableContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  
  /* Content wrapper to ensure proper spacing */
  & > * {
    flex: 1 1 auto;
    min-height: 0;
  }
  
  /* Hide default scrollbar buttons */
  &::-webkit-scrollbar-button {
    display: none;
  }

  /* Webkit browsers */
  &::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    background: ${props => props.trackColor || 'rgba(0, 0, 0, 0.05)'};
    border-radius: 4px;
    margin: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${props => `${props.primaryColor}99`};
    border-radius: 4px;
    min-height: 40px;
    border: 2px solid transparent;
    background-clip: padding-box;
    
    &:hover {
      background: ${props => props.primaryColor};
      border: 1px solid transparent;
      background-clip: padding-box;
    }
    
    /* Improve grab area for better scrolling */
    &:active {
      background: ${props => props.primaryColor};
      border: 1px solid transparent;
      background-clip: padding-box;
    }
  }

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: ${props => `${props.primaryColor}99`} ${props => props.trackColor || 'rgba(0, 0, 0, 0.05)'};

  /* Show scrollbar when scrolling */
  &:hover::-webkit-scrollbar-thumb {
    background: ${props => `${props.primaryColor}cc`};
  }

  /* Ensure content doesn't overlap scrollbar */
  padding-right: 8px;
  
  /* Smooth scrolling for better UX */
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  
  /* Prevent content from being cut off */
  & > div {
    min-height: 0;
    height: 100%;
  }

  /* Show scroll progress indicator */
  &::after {
    content: '';
    position: fixed;
    top: 0;
    right: 0;
    width: 8px;
    height: ${props => props.scrollProgress}%;
    background: ${props => props.primaryColor};
    opacity: 0;
    transition: opacity 0.3s;
    border-radius: 4px;
    pointer-events: none;
  }

  &:hover::after {
    opacity: 0.2;
  }
`;

const ScrollButton = styled(motion.button)`
  position: absolute;
  width: 40px;
  height: 40px;
  right: 16px;
  border-radius: 50%;
  background: ${props => `${props.primaryColor}99`};
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  opacity: ${props => props.show ? 0.6 : 0};
  pointer-events: ${props => props.show ? 'auto' : 'none'};
  transition: all 0.3s ease;

  /* Hide buttons on mobile devices */
  @media (max-width: 768px) {
    display: none;
  }

  @media (hover: hover) {
    &:hover {
      background: ${props => props.primaryColor};
      opacity: 1;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    }
  }
`;

const ScrollToTopButton = styled(ScrollButton)`
  bottom: 68px; // Positioned above the scroll-to-bottom button
`;

const ScrollToBottomButton = styled(ScrollButton)`
  bottom: 16px;
`;

const CustomScrollbar = ({ children, className, trackColor, ...props }) => {
  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;
  const containerRef = useRef(null);
  const [scrollProgress, setScrollProgress] = useState(0);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [isAtBottom, setIsAtBottom] = useState(true);
  const [hasScrollableContent, setHasScrollableContent] = useState(false);

  const handleScroll = useCallback(() => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      const progress = (scrollTop / (scrollHeight - clientHeight)) * 100;
      setScrollProgress(progress);
      setShowScrollButton(scrollTop > 300);
      setIsAtBottom(Math.ceil(scrollTop + clientHeight) >= scrollHeight);
      setHasScrollableContent(scrollHeight > clientHeight);
    }
  }, []);

  // Update hasScrollableContent on mount and when content changes
  useEffect(() => {
    if (containerRef.current) {
      const { scrollHeight, clientHeight } = containerRef.current;
      setHasScrollableContent(scrollHeight > clientHeight);
    }
  }, [children]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, [handleScroll]);

  return (
    <StyledComponentsThemWrapper>
      <Box sx={{ position: 'relative', height: '100%' }}>
        <ScrollableContainer 
          ref={containerRef}
          className={className} 
          primaryColor={primaryColor}
          trackColor={trackColor}
          scrollProgress={scrollProgress}
          {...props}
        >
          {children}
        </ScrollableContainer>
      </Box>
    </StyledComponentsThemWrapper>
  );
};

export default CustomScrollbar;