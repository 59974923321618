import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { X, MessageCircle } from 'lucide-react';
import './MobileCarSearch.css'; // Reusing the same CSS

// Modal container component
const ModalContainer = ({ children }) => {
  // Add class based on viewport height for better positioning
  const viewportClass = window.innerHeight < 800 ? 'short-viewport' : 'normal-viewport';
  
  return ReactDOM.createPortal(
    <div 
      className={`car-search-overlay ${viewportClass}`}
      aria-modal="true"
      role="dialog"
      aria-labelledby="default-chat-title"
    >
      {children}
    </div>,
    document.body
  );
};

// Close button component
const CloseButton = ({ onClose }) => (
  <button className="car-search-close" onClick={onClose} aria-label="Chiudi">
    <X size={18} />
  </button>
);

// Welcome message component
const WelcomeMessage = () => (
  <div className="car-search-welcome">
    <span className="welcome-icon">💬</span>
    <h2>Come possiamo aiutarti?</h2>
    <p>Seleziona l'argomento di cui vorresti parlare o inizia subito a chattare</p>
  </div>
);

// Header component for better consistency with MobileCarSearch
const Header = () => (
  <div className="car-search-header">
    <h1 className="car-search-main-title">
      Chatta con{' '}
      <img 
        src="https://files.aitomotivelab.com/Mia_Logo_Black.png"
        alt="MIA"
        style={{ height: '18px', verticalAlign: 'middle' }}
      />
    </h1>
  </div>
);

// Utility functions for browsers
const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf('safari') > -1 && ua.indexOf('chrome') === -1;
};

const isIOS = () => {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
};

// Main component
const DefaultChat = ({ onClose, onSubmit, usePortal = true, toggleChat }) => {
  // State variables
  const [topic, setTopic] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [currentStep, setCurrentStep] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [keyboardOpen, setKeyboardOpen] = useState(false);
  const currentInputRef = useRef(null);
  const containerRef = useRef(null);
  const formRef = useRef(null);
  const [isSafariBrowser, setIsSafariBrowser] = useState(false);
  const [isIOSDevice, setIsIOSDevice] = useState(false);
  const [keyboardHeight, setKeyboardHeight] = useState(0);
  
  // Set up event listeners and initialize
  useEffect(() => {
    document.body.classList.add('car-search-open');
    setIsSafariBrowser(isSafari());
    setIsIOSDevice(isIOS());
    
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    window.addEventListener('resize', handleResize);
    
    const setViewportHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    
    setViewportHeight();
    window.addEventListener('resize', setViewportHeight);
    
    return () => {
      document.body.classList.remove('car-search-open');
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('resize', setViewportHeight);
    };
  }, []);
  
  // Handle iOS Safari specific adjustments
  useEffect(() => {
    if (isIOSDevice && isSafariBrowser) {
      document.body.classList.add('ios-safari');
      
      const metaViewport = document.querySelector('meta[name="viewport"]');
      if (metaViewport) {
        metaViewport.setAttribute('content', 
          'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no, viewport-fit=cover');
      }
      
      const visualViewport = window.visualViewport;
      if (visualViewport) {
        const handleViewportChange = () => {
          const windowHeight = window.innerHeight;
          const viewportHeight = visualViewport.height;
          const isKeyboardVisible = viewportHeight < windowHeight * 0.75;
          
          setKeyboardOpen(isKeyboardVisible);
          
          if (isKeyboardVisible) {
            const estimatedKeyboardHeight = windowHeight - viewportHeight;
            setKeyboardHeight(estimatedKeyboardHeight);
            document.documentElement.style.setProperty('--keyboard-height', `${estimatedKeyboardHeight}px`);
            
            setTimeout(() => {
              if (currentInputRef.current) {
                currentInputRef.current.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center'
                });
              }
            }, 300);
          } else {
            setKeyboardHeight(0);
            document.documentElement.style.setProperty('--keyboard-height', '0px');
          }
        };
        
        visualViewport.addEventListener('resize', handleViewportChange);
        visualViewport.addEventListener('scroll', handleViewportChange);
        
        return () => {
          document.body.classList.remove('ios-safari');
          visualViewport.removeEventListener('resize', handleViewportChange);
          visualViewport.removeEventListener('scroll', handleViewportChange);
          
          if (metaViewport) {
            metaViewport.setAttribute('content', 
              'width=device-width, initial-scale=1, viewport-fit=cover');
          }
        };
      }
    }
  }, [isIOSDevice, isSafariBrowser]);
  
  // Input focus handler
  const handleInputFocus = () => {
    // Only set keyboard open if on mobile or iOS Safari
    if (isMobile || (isIOSDevice && isSafariBrowser)) {
      setKeyboardOpen(true);
      
      if (isIOSDevice && isSafariBrowser) {
        setTimeout(() => {
          containerRef.current?.classList.add('ios-keyboard-open');
          
          const actionsElement = containerRef.current?.querySelector('.car-search-actions');
          if (actionsElement) {
            actionsElement.classList.add('keyboard-repositioned');
          }
          
          if (currentInputRef.current) {
            currentInputRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            });
          }
        }, 150);
      } else if (isMobile) {
        setTimeout(() => {
          const actionsElement = containerRef.current?.querySelector('.car-search-actions');
          
          if (actionsElement) {
            actionsElement.classList.add('keyboard-repositioned');
            
            if (currentInputRef.current) {
              currentInputRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
              });
              
              if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
                window.scrollTo(0, window.scrollY + 100);
              }
            }
          }
        }, 300);
      }
    }
  };
  
  // Input blur handler
  const handleInputBlur = () => {
    if (isIOSDevice && isSafariBrowser) {
      setTimeout(() => {
        setKeyboardOpen(false);
        
        containerRef.current?.classList.remove('ios-keyboard-open');
        
        const actionsElement = containerRef.current?.querySelector('.car-search-actions');
        if (actionsElement) {
          actionsElement.classList.remove('keyboard-repositioned');
        }
        
        setKeyboardHeight(0);
        document.documentElement.style.setProperty('--keyboard-height', '0px');
      }, 300);
    } else if (isMobile) {
      setTimeout(() => {
        setKeyboardOpen(false);
        
        const actionsElement = containerRef.current?.querySelector('.car-search-actions');
        if (actionsElement && actionsElement.classList.contains('keyboard-repositioned')) {
          actionsElement.style.position = 'fixed';
          actionsElement.style.bottom = '0';
          actionsElement.style.top = 'auto';
          actionsElement.classList.remove('keyboard-repositioned');
        }
      }, 150);
    }
  };
  
  // Auto-advance when an option is selected
  const handleOptionSelect = (value) => {
    setTopic(value);
    if (value === 'Altro') {
      setCurrentStep(1);
    }
    
    // Make sure the actions stay visible on desktop
    if (!isMobile && !isIOSDevice) {
      const actionsElement = containerRef.current?.querySelector('.car-search-actions');
      if (actionsElement) {
        actionsElement.classList.remove('keyboard-repositioned');
        actionsElement.style.position = '';
        actionsElement.style.bottom = '';
        actionsElement.style.top = '';
      }
    }
  };
  
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && e.ctrlKey) {
      e.preventDefault();
      handleSubmit(topic, additionalInfo);
    } else if (e.key === 'Escape') {
      onClose();
    }
  };
  
  // Start chat with selected topic
  const handleStartChat = () => {
    handleSubmit(topic, additionalInfo);
  };
  
  const handleSubmit = (topicValue, additionalInfoValue) => {
    const finalTopic = topicValue || 'Generale';
    const message = additionalInfoValue 
      ? `Ciao! Vorrei parlare di: ${finalTopic}. ${additionalInfoValue}`
      : `Ciao! Vorrei parlare di: ${finalTopic}`;
    
    // Submit the message data to parent component
    onSubmit({
      topic: finalTopic,
      message
    });
    
    // Simply close the modal without calling toggleChat
    onClose();
  };
  
  // Direct chat handler - only used for "Inizia a chattare" button now
  const handleOpenChat = () => {
    // Just close this modal without submitting any message
    onClose();
    
    setTimeout(() => {
      if (typeof toggleChat === 'function') {
        toggleChat();
      }
    }, 100);
  };
  
  // Stop propagation of clicks to prevent closing the modal
  const handleContainerClick = (e) => {
    e.stopPropagation();
  };
  
  // Prepare content to be rendered
  const content = (
    <div 
      className={`car-search-container ${keyboardOpen ? 'keyboard-open' : ''} ${isMobile ? 'mobile-fixed-position' : ''} ${isSafariBrowser ? 'safari-browser' : ''} ${isIOSDevice ? 'ios-device' : ''}`}
      ref={containerRef}
      onClick={handleContainerClick}
      data-step={currentStep}
      style={keyboardOpen && isIOSDevice ? { paddingBottom: `${keyboardHeight}px` } : {}}
    >
      <CloseButton onClose={onClose} />
      <Header />
      <WelcomeMessage />
      
      <form 
        className="car-search-form" 
        onSubmit={(e) => { 
          e.preventDefault(); 
          handleStartChat();
        }}
        ref={formRef}
      >
        <div className="car-search-content">
          {currentStep === 0 ? (
            <>
              <div className="car-search-input-wrapper">
                <div className="search-input-container">
                  <div className="fuel-type-grid" role="radiogroup" aria-label="Argomento">
                    <label 
                      className={`fuel-type-option ${topic === 'Informazioni veicoli' ? 'selected' : ''}`}
                      onClick={() => handleOptionSelect('Informazioni veicoli')}
                    >
                      <input
                        type="radio"
                        name="topic"
                        value="Informazioni veicoli"
                        checked={topic === 'Informazioni veicoli'}
                        onChange={() => {}}
                        onFocus={() => isMobile || (isIOSDevice && isSafariBrowser) ? handleInputFocus() : null}
                        ref={topic === '' ? currentInputRef : null}
                      />
                      <div className="fuel-type-content">
                        <span className="fuel-type-icon">🚗</span>
                        <span className="fuel-type-label">Informazioni veicoli</span>
                      </div>
                    </label>
                    <label 
                      className={`fuel-type-option ${topic === 'Appuntamento' ? 'selected' : ''}`}
                      onClick={() => handleOptionSelect('Appuntamento')}
                    >
                      <input
                        type="radio"
                        name="topic"
                        value="Appuntamento"
                        checked={topic === 'Appuntamento'}
                        onChange={() => {}}
                        onFocus={() => isMobile || (isIOSDevice && isSafariBrowser) ? handleInputFocus() : null}
                      />
                      <div className="fuel-type-content">
                        <span className="fuel-type-icon">📅</span>
                        <span className="fuel-type-label">Appuntamento</span>
                      </div>
                    </label>
                    <label 
                      className={`fuel-type-option ${topic === 'Preventivo' ? 'selected' : ''}`}
                      onClick={() => handleOptionSelect('Preventivo')}
                    >
                      <input
                        type="radio"
                        name="topic"
                        value="Preventivo"
                        checked={topic === 'Preventivo'}
                        onChange={() => {}}
                        onFocus={() => isMobile || (isIOSDevice && isSafariBrowser) ? handleInputFocus() : null}
                      />
                      <div className="fuel-type-content">
                        <span className="fuel-type-icon">💰</span>
                        <span className="fuel-type-label">Preventivo</span>
                      </div>
                    </label>
                    <label 
                      className={`fuel-type-option ${topic === 'Assistenza' ? 'selected' : ''}`}
                      onClick={() => handleOptionSelect('Assistenza')}
                    >
                      <input
                        type="radio"
                        name="topic"
                        value="Assistenza"
                        checked={topic === 'Assistenza'}
                        onChange={() => {}}
                        onFocus={() => isMobile || (isIOSDevice && isSafariBrowser) ? handleInputFocus() : null}
                      />
                      <div className="fuel-type-content">
                        <span className="fuel-type-icon">🔧</span>
                        <span className="fuel-type-label">Assistenza</span>
                      </div>
                    </label>
                    <label 
                      className={`fuel-type-option ${topic === 'Altro' ? 'selected' : ''}`}
                      onClick={() => handleOptionSelect('Altro')}
                    >
                      <input
                        type="radio"
                        name="topic"
                        value="Altro"
                        checked={topic === 'Altro'}
                        onChange={() => {}}
                        onFocus={() => isMobile || (isIOSDevice && isSafariBrowser) ? handleInputFocus() : null}
                      />
                      <div className="fuel-type-content">
                        <span className="fuel-type-icon">💬</span>
                        <span className="fuel-type-label">Altro</span>
                      </div>
                    </label>
                  </div>
                </div>
                
                <div className={`car-search-actions ${isMobile || isIOSDevice ? 'mobile-actions' : 'desktop-actions'}`}>
                  <button
                    type="button"
                    className="car-search-next"
                    onClick={() => topic ? handleStartChat() : handleOpenChat()}
                    style={{ width: '100%' }}
                  >
                    Inizia a chattare
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="car-search-input-wrapper">
                <div className="textarea-container">
                  <textarea
                    value={additionalInfo}
                    onChange={(e) => setAdditionalInfo(e.target.value)}
                    onKeyDown={handleKeyDown}
                    onFocus={() => isMobile || (isIOSDevice && isSafariBrowser) ? handleInputFocus() : null}
                    onBlur={() => isMobile || (isIOSDevice && isSafariBrowser) ? handleInputBlur() : null}
                    placeholder="Es. Vorrei avere informazioni sugli orari di apertura, sulle auto disponibili, o per prendere un appuntamento..."
                    className="search-textarea"
                    maxLength={500}
                    ref={currentInputRef}
                    aria-label="Dettagli aggiuntivi"
                  />
                  <div className="textarea-hint">Premi Ctrl+Enter per inviare</div>
                </div>
                
                <div className={`car-search-actions ${isMobile || isIOSDevice ? 'mobile-actions' : 'desktop-actions'}`}>
                  <button
                    type="button"
                    className="car-search-next"
                    onClick={handleStartChat}
                    style={{ width: '100%' }}
                  >
                    Inizia a chattare
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </form>
    </div>
  );

  // Render either in a portal or directly
  return usePortal ? (
    <ModalContainer>
      {content}
    </ModalContainer>
  ) : (
    <div 
      className={`car-search-overlay ${isSafariBrowser ? 'safari-browser' : ''} ${isIOSDevice ? 'ios-device' : ''}`} 
      onClick={onClose}
      aria-modal="true"
      role="dialog"
      aria-labelledby="default-chat-title"
    >
      {content}
    </div>
  );
};

export default DefaultChat; 