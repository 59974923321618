import React, { useState, useEffect, useRef } from 'react';
import { MessageCircle, Search, ChevronRight, X, Sparkles, Tag, Wrench, Calendar } from "lucide-react";
import { AnimatePresence, motion } from "framer-motion";
import { trackChatEvent } from '../utils/analytics';
import { getCTATypeFromUrl, getCTAConfig } from '../utils/urlPatterns';
import MobileCarSearch from './New Chatbody/MobileCarSearch';
import PromotionsSearch from './New Chatbody/PromotionsSearch';
import ServiceSearch from './New Chatbody/ServiceSearch';
import RentalSearch from './New Chatbody/RentalSearch';
import DefaultChat from './New Chatbody/DefaultChat';
import './NewToggleChat.css';

const NewToggleChat = ({ isChatVisible, toggleChat, onSendMessage, notification }) => {
  // State variables
  const [floatingMessages, setFloatingMessages] = useState([]);
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [showMessage, setShowMessage] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showSearchTool, setShowSearchTool] = useState(false);
  const [lastSeenNotificationId, setLastSeenNotificationId] = useState(null);
  const [pageVisitCount, setPageVisitCount] = useState(0);
  const [previousUrl, setPreviousUrl] = useState('');
  const [nonRicercaAutoVisits, setNonRicercaAutoVisits] = useState(0);
  const [isSearchToolActive, setIsSearchToolActive] = useState(false);
  const [chatOpenedFromSearch, setChatOpenedFromSearch] = useState(false);
  const [searchTimer, setSearchTimer] = useState(null);
  const [currentCtaType, setCurrentCtaType] = useState('DEFAULT');
  const [currentUrl, setCurrentUrl] = useState(window.location.href);
  const [isSimulatingUrl, setIsSimulatingUrl] = useState(false);
  const [isCtaExpanded, setIsCtaExpanded] = useState(true);
  const [isHoveringCta, setIsHoveringCta] = useState(false);
  const [lastTouchTime, setLastTouchTime] = useState(0);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const ctaTimeoutRef = useRef(null);
  const initialPulseTimeoutRef = useRef(null);

  // Test function to simulate different URLs for local testing
  const simulateUrl = (urlPath) => {
    const baseUrl = window.location.origin;
    // Ensure urlPath starts with a slash
    const normalizedPath = urlPath.startsWith('/') ? urlPath : `/${urlPath}`;
    const simulatedUrl = `${baseUrl}${normalizedPath}`;
    
    // Set simulation flag to prevent URL tracking effect from interfering
    setIsSimulatingUrl(true);
    
    try {
      // Use history API to update the URL without navigating
      const currentState = window.history.state;
      window.history.pushState(
        { ...currentState, simulated: true },
        '',
        normalizedPath
      );
      
      // Force CTA update with the simulated URL
      const ctaType = getCTATypeFromUrl(simulatedUrl);
      setCurrentCtaType(ctaType);
      setCurrentUrl(simulatedUrl);
      
      console.log(`Simulating URL: ${simulatedUrl}`);
      console.log(`CTA Type: ${ctaType}`);
      console.log('CTA text will change to:', getCTAConfig(ctaType).text);
      
      // Add a visual notification
      if (document.querySelector('#simulation-notice')) {
        document.querySelector('#simulation-notice').remove();
      }
      
      const notice = document.createElement('div');
      notice.id = 'simulation-notice';
      notice.style.position = 'fixed';
      notice.style.bottom = '80px';
      notice.style.right = '20px';
      notice.style.background = 'rgba(255, 193, 7, 0.9)';
      notice.style.color = 'black';
      notice.style.padding = '8px 12px';
      notice.style.borderRadius = '4px';
      notice.style.zIndex = '9999';
      notice.style.boxShadow = '0 2px 10px rgba(0,0,0,0.2)';
      notice.innerHTML = `<strong>URL Simulated:</strong> ${normalizedPath} <br>CTA: "${getCTAConfig(ctaType).text}"`;
      document.body.appendChild(notice);
      
      // Remove simulation flag after a delay
      setTimeout(() => {
        setIsSimulatingUrl(false);
      }, 500);
      
      // Keep notice visible for 5 seconds
      setTimeout(() => {
        if (document.querySelector('#simulation-notice')) {
          document.querySelector('#simulation-notice').remove();
        }
      }, 5000);
      
      return ctaType;
    } catch (error) {
      console.error('Error simulating URL:', error);
      setIsSimulatingUrl(false);
      return null;
    }
  };

  // Make the simulate function available in development environment
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      window.simulateUrl = simulateUrl;
      console.log('🧪 Testing mode enabled! Use window.simulateUrl("/path") to test different CTAs');
      console.log('For example:');
      console.log('window.simulateUrl("ricerca-auto") - Car search');
      console.log('window.simulateUrl("gamma/modello") - Car search');
      console.log('window.simulateUrl("promozioni") - Promotions');
      console.log('window.simulateUrl("servizi-officina") - Services');
      console.log('window.simulateUrl("noleggio") - Rental');
      console.log('window.simulateUrl("/") - Default chat');
    }
  }, []);

  const isMobile = windowWidth <= 640;

  // Track current URL and update CTA type
  useEffect(() => {
    // Skip this effect if we're in simulation mode
    if (isSimulatingUrl) {
      return;
    }
    
    // Initial update
    updateCTATypeFromUrl(window.location.href);
    
    // Set up listeners for URL changes
    const handleLocationChange = () => {
      // Skip if we're in simulation mode
      if (isSimulatingUrl) {
        console.log("Skipping location change handler during simulation");
        return;
      }
      
      // Check if this is a simulated URL from history state
      const state = window.history.state || {};
      if (state.simulated) {
        console.log("Detected simulated URL, preserving CTA state");
        return;
      }
      
      const newUrl = window.location.href;
      if (newUrl !== currentUrl) {
        console.log("Real URL change detected:", newUrl);
        setCurrentUrl(newUrl);
        updateCTATypeFromUrl(newUrl);
      }
    };
    
    // Listen for navigation events
    window.addEventListener('popstate', handleLocationChange);
    
    // Monitor for programmatic navigation
    const originalPushState = window.history.pushState;
    const originalReplaceState = window.history.replaceState;
    
    window.history.pushState = function() {
      // If this is a simulated URL call, mark it as such
      if (arguments[0] && arguments[0].simulated) {
        console.log("Processing simulated pushState");
      }
      
      // Always apply the original method first
      originalPushState.apply(this, arguments);
      
      // Skip location change if we're simulating
      if (!isSimulatingUrl && (!arguments[0] || !arguments[0].simulated)) {
        handleLocationChange();
      }
    };
    
    window.history.replaceState = function() {
      // If this is a simulated URL call, mark it as such
      if (arguments[0] && arguments[0].simulated) {
        console.log("Processing simulated replaceState");
      }
      
      // Always apply the original method first  
      originalReplaceState.apply(this, arguments);
      
      // Skip location change if we're simulating
      if (!isSimulatingUrl && (!arguments[0] || !arguments[0].simulated)) {
        handleLocationChange();
      }
    };
    
    return () => {
      window.removeEventListener('popstate', handleLocationChange);
      window.history.pushState = originalPushState;
      window.history.replaceState = originalReplaceState;
    };
  }, [currentUrl, isSimulatingUrl]);
  
  // Function to update CTA type based on URL
  const updateCTATypeFromUrl = (url) => {
    const newCtaType = getCTATypeFromUrl(url);
    console.log('Updating CTA type from:', currentCtaType, 'to:', newCtaType, 'for URL:', url);
    
    if (newCtaType !== currentCtaType) {
      console.log('CTA type changed to:', newCtaType);
      setCurrentCtaType(newCtaType);
    } else {
      console.log('CTA type unchanged');
    }
  };
  
  // Get icon component based on icon name
  const getIconComponent = (iconName, size = 18) => {
    switch (iconName) {
      case 'search':
        return <Search size={size} strokeWidth={2.5} className="search-icon-cta" />;
      case 'tag':
        return <Tag size={size} strokeWidth={2.5} className="search-icon-cta" />;
      case 'tool':
        return <Wrench size={size} strokeWidth={2.5} className="search-icon-cta" />;
      case 'calendar':
        return <Calendar size={size} strokeWidth={2.5} className="search-icon-cta" />;
      case 'message-circle':
      default:
        return <MessageCircle size={size} strokeWidth={2.5} className="search-icon-cta" />;
    }
  };
  
  // Get search component based on current CTA type
  const getCurrentSearchComponent = () => {
    const config = getCTAConfig(currentCtaType);
    
    switch (config.searchComponent) {
      case 'MobileCarSearch':
        return (
          <MobileCarSearch 
            onClose={handleCloseSearchTool}
            onSubmit={handleCarSearchSubmit}
            toggleChat={toggleChat}
          />
        );
      case 'PromotionsSearch':
        return (
          <PromotionsSearch 
            onClose={handleCloseSearchTool}
            onSubmit={handlePromotionsSearchSubmit}
            toggleChat={toggleChat}
          />
        );
      case 'ServiceSearch':
        return (
          <ServiceSearch 
            onClose={handleCloseSearchTool}
            onSubmit={handleServiceSearchSubmit}
            toggleChat={toggleChat}
          />
        );
      case 'RentalSearch':
        return (
          <RentalSearch 
            onClose={handleCloseSearchTool}
            onSubmit={handleRentalSearchSubmit}
            toggleChat={toggleChat}
          />
        );
      case 'DefaultChat':
      default:
        return (
          <DefaultChat 
            onClose={handleCloseSearchTool}
            onSubmit={handleDefaultChatSubmit}
            toggleChat={toggleChat}
          />
        );
    }
  };

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Set initial expansion of CTA and collapse after 5 seconds
  useEffect(() => {
    setIsCtaExpanded(true);
    
    const timer = setTimeout(() => {
      if (!isHoveringCta) {
        setIsCtaExpanded(false);
      }
    }, 5000);
    
    return () => clearTimeout(timer);
  }, [isHoveringCta]);

  // Handle notifications
  useEffect(() => {
    if (notification && !showSearchTool && !isSearchToolActive) {
      const notificationId = notification.id || 
                            notification.timestamp || 
                            `${notification.message_to_send || notification.custom_message}_${Date.now()}`;
      
      if (notificationId !== lastSeenNotificationId) {
        const notificationMessage = {
          id: notificationId,
          text: notification.message_to_send || notification.custom_message,
          message: notification.visible_message || notification.message,
          type: notification.type,
          duration: notification.duration
        };
        
        setFloatingMessages([notificationMessage]);
        setCurrentMessageIndex(0);
        setShowMessage(true);
        
        // Hide first-time tooltip when a message appears
        setIsFirstLoad(false);
        
        if (notification.duration) {
          const timer = setTimeout(() => {
            setFloatingMessages([]);
            setShowMessage(false);
          }, notification.duration);
          
          return () => {
            clearTimeout(timer);
          };
        }
      }
    }
  }, [notification, showSearchTool, isSearchToolActive, lastSeenNotificationId]);

  // Handle chat visibility changes
  useEffect(() => {
    if (isChatVisible) {
      setShowMessage(false);
      
      if (floatingMessages.length > 0 && floatingMessages[currentMessageIndex]) {
        setLastSeenNotificationId(floatingMessages[currentMessageIndex].id || 
                               `${floatingMessages[currentMessageIndex].text}_${Date.now()}`);
      }
    }
  }, [isChatVisible, floatingMessages, currentMessageIndex]);

  // Track page visits
  useEffect(() => {
    const trackPageVisit = () => {
      const currentUrl = window.location.href;
      
      if (currentUrl !== previousUrl) {
        setPreviousUrl(currentUrl);
        setNonRicercaAutoVisits(prev => prev + 1);
        
        if (nonRicercaAutoVisits > 0 && nonRicercaAutoVisits % 7 === 0) {
          showSearchToolHandler();
        }
        
        setPageVisitCount(prev => prev + 1);
      }
    };

    trackPageVisit();
    
    const handleLocationChange = () => {
      trackPageVisit();
    };
    
    window.addEventListener('popstate', handleLocationChange);
    
    const originalPushState = window.history.pushState;
    const originalReplaceState = window.history.replaceState;
    
    window.history.pushState = function() {
      // If this is a simulated URL call, mark it as such
      if (arguments[0] && arguments[0].simulated) {
        console.log("Processing simulated pushState");
      }
      
      // Always apply the original method first
      originalPushState.apply(this, arguments);
      
      // Skip location change if we're simulating
      if (!isSimulatingUrl && (!arguments[0] || !arguments[0].simulated)) {
        handleLocationChange();
      }
    };
    
    window.history.replaceState = function() {
      // If this is a simulated URL call, mark it as such
      if (arguments[0] && arguments[0].simulated) {
        console.log("Processing simulated replaceState");
      }
      
      // Always apply the original method first  
      originalReplaceState.apply(this, arguments);
      
      // Skip location change if we're simulating
      if (!isSimulatingUrl && (!arguments[0] || !arguments[0].simulated)) {
        handleLocationChange();
      }
    };
    
    return () => {
      window.removeEventListener('popstate', handleLocationChange);
      window.history.pushState = originalPushState;
      window.history.replaceState = originalReplaceState;
    };
  }, [pageVisitCount, previousUrl, windowWidth, nonRicercaAutoVisits]);

  // Show search tool
  const showSearchToolHandler = () => {
    if (!isChatVisible && windowWidth > 640) {
      setShowMessage(false);
      setShowSearchTool(true);
      setIsSearchToolActive(true);
      
      if (searchTimer) {
        clearTimeout(searchTimer);
        setSearchTimer(null);
      }
      
      const timer = setTimeout(() => {
        if (isSearchToolActive) {
          setShowSearchTool(false);
          setIsSearchToolActive(false);
        }
      }, 10000);
      
      setSearchTimer(timer);
    }
  };

  // Handle closing search tool
  const handleCloseSearchTool = () => {
    setShowSearchTool(false);
    setIsSearchToolActive(false);
    
    if (searchTimer) {
      clearTimeout(searchTimer);
      setSearchTimer(null);
    }
  };

  // Handle toggle click
  const handleToggleClick = () => {
    if (showMessage) {
      // If message is showing but chat is not open yet
      setShowMessage(false);
      
      if (floatingMessages.length > 0 && floatingMessages[currentMessageIndex]) {
        setLastSeenNotificationId(floatingMessages[currentMessageIndex].id || 
                               `${floatingMessages[currentMessageIndex].text}_${Date.now()}`);
      }
    }
    
    trackChatEvent.toggleChat(true);
    
    // Always ensure the chat opens in full screen when clicked
    // We're manually toggling, so openedByTrigger should be false
    toggleChat(false);
  };

  // Handle car search submission
  const handleCarSearchSubmit = (data) => {
    setShowSearchTool(false);
    setChatOpenedFromSearch(true);
    
    if (searchTimer) {
      clearTimeout(searchTimer);
      setSearchTimer(null);
    }
    
    const message = `Ciao! Sto cercando un'auto tra quelle disponibili. 
Ho un budget massimo di ${data.budget}€.
${getFuelTypePreference(data.fuelType)}
${data.additionalInfo ? `\nInoltre, ho queste preferenze specifiche: ${data.additionalInfo}` : ''}

Avete a disposizione delle auto che potrebbero fare al caso mio? Spiegami perché le suggerisci.`;
    
    toggleChat(false);
    trackChatEvent.toggleChat(true);
    setTimeout(() => {
      onSendMessage(message);
    }, 300);
  };
  
  // Handle promotions search submission
  const handlePromotionsSearchSubmit = (data) => {
    setShowSearchTool(false);
    setChatOpenedFromSearch(true);
    
    if (searchTimer) {
      clearTimeout(searchTimer);
      setSearchTimer(null);
    }
    
    let promotionTypeText = data.promotionType || 'qualsiasi';
    // If it's the special "Tutte" case (from skipping the submenu), adjust the message
    if (promotionTypeText === 'Tutte') {
      promotionTypeText = 'tutte le offerte disponibili';
    }
    
    const message = `Ciao! Sto cercando informazioni sulle vostre promozioni.
Sono interessato a: ${data.vehicleType || 'veicoli in generale'}.
Sono particolarmente interessato a promozioni del tipo: ${promotionTypeText}.
${data.additionalInfo ? `\nUlteriori dettagli: ${data.additionalInfo}` : ''}

Potreste indicarmi le promozioni attualmente disponibili che potrebbero fare al caso mio?`;
    
    toggleChat(false);
    trackChatEvent.toggleChat(true);
    setTimeout(() => {
      onSendMessage(message);
    }, 300);
  };
  
  // Handle service search submission
  const handleServiceSearchSubmit = (data) => {
    setShowSearchTool(false);
    setChatOpenedFromSearch(true);
    
    if (searchTimer) {
      clearTimeout(searchTimer);
      setSearchTimer(null);
    }
    
    // Build vehicle description with model and license plate when available
    let vehicleDescription = '';
    if (data.vehicleModel && data.vehicleModel.trim() !== '') {
      vehicleDescription = `Modello del veicolo: ${data.vehicleModel}`;
      
      if (data.licensePlate && data.licensePlate.trim() !== '') {
        vehicleDescription += `\nTarga: ${data.licensePlate}`;
      }
    }
    
    const message = `Ciao! Sono interessato ai vostri servizi di assistenza.
Ho bisogno di: ${data.serviceType || 'assistenza generale'}.
${vehicleDescription ? `\n${vehicleDescription}` : ''}
${data.additionalInfo ? `\nUlteriori dettagli: ${data.additionalInfo}` : ''}

Vorrei sapere come procedere e, se possibile, fissare un appuntamento.`;
    
    toggleChat(false);
    trackChatEvent.toggleChat(true);
    setTimeout(() => {
      onSendMessage(message);
    }, 300);
  };
  
  // Handle rental search submission
  const handleRentalSearchSubmit = (data) => {
    setShowSearchTool(false);
    setChatOpenedFromSearch(true);
    
    if (searchTimer) {
      clearTimeout(searchTimer);
      setSearchTimer(null);
    }
    
    const message = `Ciao! Sono interessato a noleggiare un veicolo.
Sono un: ${data.clientType || 'cliente privato'}.
Tipo di noleggio: ${data.rentalType || 'non specificato'}.
${data.additionalInfo ? `\nUlteriori dettagli: ${data.additionalInfo}` : ''}

Potreste fornirmi informazioni sulle opzioni disponibili e sui costi?`;
    
    toggleChat(false);
    trackChatEvent.toggleChat(true);
    setTimeout(() => {
      onSendMessage(message);
    }, 300);
  };
  
  // Handle default chat submission
  const handleDefaultChatSubmit = (data) => {
    setShowSearchTool(false);
    setChatOpenedFromSearch(true);
    
    if (searchTimer) {
      clearTimeout(searchTimer);
      setSearchTimer(null);
    }
    
    toggleChat(false);
    trackChatEvent.toggleChat(true);
    setTimeout(() => {
      onSendMessage(data.message || 'Ciao! Vorrei parlare con un vostro consulente.');
    }, 300);
  };

  // Handle message click
  const handleMessageClick = (message) => {
    if (message.onClick) {
      message.onClick();
    } else if (message.message) {
      trackChatEvent.toggleChat(true);
      toggleChat();
      
      setTimeout(() => {
        onSendMessage(message.message || message.text);
      }, 300);
      
      if (floatingMessages.length > 0 && floatingMessages[currentMessageIndex]) {
        setLastSeenNotificationId(floatingMessages[currentMessageIndex].id || 
                              `${floatingMessages[currentMessageIndex].text}_${Date.now()}`);
      }
      
      setShowMessage(false);
    }
  };

  // Handle closing message
  const handleCloseMessage = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowMessage(false);
    setFloatingMessages([]);
  };

  // Get display message
  const getDisplayMessage = () => {
    const currentMessage = floatingMessages[currentMessageIndex];
    return currentMessage?.text || currentMessage?.message || "";
  };

  // Clean up timers on unmount
  useEffect(() => {
    return () => {
      if (searchTimer) {
        clearTimeout(searchTimer);
        setSearchTimer(null);
      }
      
      if (ctaTimeoutRef.current) {
        clearTimeout(ctaTimeoutRef.current);
      }
    };
  }, [searchTimer]);

  // Chat is opened from search effect
  useEffect(() => {
    if (!isChatVisible && chatOpenedFromSearch) {
      setShowSearchTool(false);
      setIsSearchToolActive(false);
      setChatOpenedFromSearch(false);
      
      if (searchTimer) {
        clearTimeout(searchTimer);
        setSearchTimer(null);
      }
    }
  }, [isChatVisible, chatOpenedFromSearch, searchTimer]);

  // Handle initial load pulsing effect
  useEffect(() => {
    if (isFirstLoad && !showMessage) {
      // Set timeout to remove the pulse animation after a few seconds
      initialPulseTimeoutRef.current = setTimeout(() => {
        setIsFirstLoad(false);
      }, 4000); // Show pulsing for 4 seconds
    } else if (showMessage) {
      // If a message is shown, immediately hide the first-time tooltip
      setIsFirstLoad(false);
      
      // Clear the timeout if it exists
      if (initialPulseTimeoutRef.current) {
        clearTimeout(initialPulseTimeoutRef.current);
        initialPulseTimeoutRef.current = null;
      }
    }

    // Cleanup function
    return () => {
      if (initialPulseTimeoutRef.current) {
        clearTimeout(initialPulseTimeoutRef.current);
      }
    };
  }, [isFirstLoad, showMessage]);

  // Get current CTA config
  const ctaConfig = getCTAConfig(currentCtaType);

  // Add test notification listener for development
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      // Register the callback for testing
      window.testNotificationCallback = (mockNotification) => {
        // Directly update component state to simulate a notification
        setFloatingMessages([{
          id: mockNotification.id,
          text: mockNotification.message_to_send,
          message: mockNotification.visible_message || mockNotification.message_to_send,
          type: mockNotification.type,
          duration: mockNotification.duration
        }]);
        setCurrentMessageIndex(0);
        setShowMessage(true);
        
        console.log('Floating message simulated:', mockNotification.message_to_send);
      };
      
      return () => {
        // Clean up on unmount
        window.testNotificationCallback = null;
      };
    }
  }, []);

  // Handle custom CTA event from ChatBody component
  useEffect(() => {
    const handleCtaClick = (event) => {
      const { ctaType } = event.detail;
      console.log("CTA click event received:", ctaType);
      
      // Update current CTA type if different
      if (ctaType !== currentCtaType) {
        setCurrentCtaType(ctaType);
      }
      
      // Show search tool
      setShowMessage(false);
      setShowSearchTool(true);
      setIsSearchToolActive(true);
      
      // Clear any existing timer
      if (searchTimer) {
        clearTimeout(searchTimer);
        setSearchTimer(null);
      }
    };
    
    // Add event listener
    window.addEventListener('ctaButtonClicked', handleCtaClick);
    
    // Cleanup
    return () => {
      window.removeEventListener('ctaButtonClicked', handleCtaClick);
    };
  }, [currentCtaType, searchTimer]);

  return (
    <div className="toggle-chat-container">
      <AnimatePresence>
        {isChatVisible && (
          <motion.div
            initial={{ opacity: 0, y: 20, scale: 0.95 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, y: 20, scale: 0.95 }}
            transition={{ duration: 0.4, ease: "easeOut" }}
            className="chat-body"
          >
            {/* ChatBody content would go here */}
          </motion.div>
        )}

        {/* Floating message */}
        {!isChatVisible && showMessage && !showSearchTool && !isSearchToolActive && (
          <motion.div
            initial={{ opacity: 0, y: 20, scale: 0.9, x: 15 }}
            animate={{ opacity: 1, y: 0, scale: 1, x: 0 }}
            exit={{ opacity: 0, y: 10, scale: 0.95, x: 10 }}
            transition={{ 
              duration: 0.4, 
              ease: [0.2, 0.9, 0.4, 1],
              opacity: { duration: 0.25 }
            }}
            key={currentMessageIndex}
            onClick={() => handleMessageClick(floatingMessages[currentMessageIndex])}
            className="floating-message"
          >
            <button 
              className="close-message-btn"
              onClick={handleCloseMessage}
              onTouchEnd={(e) => {
                e.preventDefault();
                handleCloseMessage(e);
              }}
              aria-label="Close message"
            >
              <X size={16} />
            </button>
            
            <div className="message-content">
              <div className="message-text">
                {getDisplayMessage()}
              </div>
            </div>
          </motion.div>
        )}

        {/* Chat button */}
        {!isChatVisible && !showSearchTool && !isSearchToolActive && (
          <div 
            className={`chat-button-container ${isMobile ? 'mobile' : 'desktop'}`}
            onMouseEnter={() => {
              if (!isMobile) {
                setIsHoveringCta(true);
                setIsCtaExpanded(true);
              }
            }}
            onMouseLeave={() => {
              if (!isMobile) {
                setIsHoveringCta(false);
                
                // Add delay before collapse to make interaction feel smoother
                ctaTimeoutRef.current = setTimeout(() => {
                  if (!isHoveringCta) {
                    setIsCtaExpanded(false);
                  }
                }, 300);
              }
            }}
          >
            {/* Debug info - only visible in development */}
            {process.env.NODE_ENV === 'development' && (
              <div style={{ 
                position: 'fixed', 
                bottom: '5px', 
                right: '100px',
                background: 'rgba(0,0,0,0.7)',
                color: 'white',
                fontSize: '12px',
                padding: '4px 8px',
                borderRadius: '4px',
                zIndex: 9999
              }}>
                CTA: {currentCtaType} | Text: {ctaConfig.text}
                {isSimulatingUrl && <span style={{color: '#ff6'}}>SIMULATION ACTIVE</span>}
              </div>
            )}
            
            {/* First-time tooltip */}
            {isFirstLoad && !showMessage && (
              <div className="chat-button-tooltip">
                <div className="chat-button-tooltip-text">
                  Assistente AI disponibile
                </div>
                <div className="chat-button-tooltip-arrow"></div>
              </div>
            )}
            
            <AnimatePresence>
              <motion.button
                key="dynamic-cta"
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ 
                  opacity: 1, 
                  scale: 1,
                  width: isCtaExpanded ? 'auto' : '50px',
                  paddingLeft: isCtaExpanded ? '24px' : '12px',
                  paddingRight: isCtaExpanded ? '24px' : '12px'
                }}
                exit={{ opacity: 0, scale: 0.95 }}
                transition={{ type: "spring", stiffness: 300, damping: 25 }}
                className={`search-cta always-bottom ${isMobile ? 'mobile' : 'desktop'} ${!isCtaExpanded ? 'minimized' : ''}`}
                onClick={(e) => {
                  // For mobile, we should open the search immediately
                  if (isMobile) {
                    e.preventDefault();
                    // Always expand the button
                    setIsCtaExpanded(true);
                    setIsHoveringCta(true);
                    
                    // Open the search tool immediately
                    setShowSearchTool(true);
                    return;
                  }
                  
                  // Normal case - show search tool
                  setShowSearchTool(true);
                }}
                title={ctaConfig.text}
              >
                {isCtaExpanded ? (
                  <>
                    {getIconComponent(ctaConfig.icon)}
                    <span className="cta-text">{ctaConfig.text}</span>
                    <div className="ai-label">
                      <Sparkles size={14} className="sparkles-icon" />
                      <span>AI assistita</span>
                    </div>
                  </>
                ) : (
                  getIconComponent(ctaConfig.icon, 22)
                )}
              </motion.button>
            </AnimatePresence>

            <button
              className={`chat-button ${isMobile ? 'mobile-chat-button' : 'desktop-chat-button'} ${isFirstLoad ? 'pulse-animation' : ''}`}
              onClick={handleToggleClick}
            >
              <img 
                src="https://files.aitomotivelab.com/icona_aitomotivelab.svg"
                alt="AItomotive Lab"
              />
            </button>
          </div>
        )}

        {/* Show search tool when needed */}
        {showSearchTool && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 99998,
              padding: 0,
            }}
          >
            {getCurrentSearchComponent()}
          </div>
        )}
      </AnimatePresence>
    </div>
  );
};

// Helper function to generate fuel type preference text
const getFuelTypePreference = (fuelType) => {
  switch (fuelType) {
    case 'Benzina':
      return "Preferirei un'auto a benzina.";
    case 'Diesel':
      return "Sarei orientato su un'auto diesel.";
    case 'Ibrida':
      return "Mi interessa la tecnologia ibrida per risparmiare sui consumi.";
    case 'Elettrica':
      return "Vorrei passare alla mobilità elettrica.";
    case 'Non ho preferenze':
      return "Non ho preferenze particolari sul tipo di alimentazione, sono aperto a tutte le opzioni.";
    default:
      return "";
  }
};

// In development, expose a test method on window
if (process.env.NODE_ENV === 'development') {
  window.simulateFloatingMessage = (message = 'Test floating message') => {
    const mockNotification = {
      id: `test_${Date.now()}`,
      message_to_send: message,
      visible_message: message,
      type: 'info',
      duration: 10000
    };
    
    // Use the already registered callback
    if (window.testNotificationCallback) {
      console.log('Simulating floating message:', message);
      window.testNotificationCallback(mockNotification);
    } else {
      console.error('Test notification callback not registered - make sure component is mounted');
    }
  };
}

export default NewToggleChat;   