import React, { useState, useEffect, forwardRef, useCallback, useMemo, useImperativeHandle, useRef } from 'react';
import MessageBubble from './MessageBubble';
import ChatHeader from './ChatHeader';
import ChatFooter from './ChatFooter';
import { getToolDisplay, getToolIcon } from '../../utils/toolDisplayMapping';
import './ChatBody.css';
import PersonalDataForm from '../ChatBody/PersonalData';
import { CircularProgress } from "@mui/material";
import MobileCarSearch from './MobileCarSearch';
import { useWebSocketContext } from "../../context/Theme";

// Create a memoized message component to prevent unnecessary re-renders
const MemoizedMessage = React.memo(({ message, isHumanControlled, className }) => {
  return (
    <div className={`message-container ${message.sender === 'user' ? 'user' : 'bot'}`}>
      <MessageBubble 
        message={message} 
        isHumanControlled={isHumanControlled}
        className={className}
      />
    </div>
  );
});

const ChatBody = forwardRef(({ 
  messages: propMessages, 
  isLoading, 
  onSendMessage, 
  onSendFile,
  onSendAudio,
  onSendPersonalData,
  isHumanControlled,
  humanControlMessage,
  isConnected = true,
  toggleChat,
  toggleChatWidth,
  isChatWide,
  toggleSearchPanel,
  predictedQuestions: propPredictedQuestions,
  onQuestionClick,
  isMobile,
  inputValue,
  setInputValue,
  openedByTrigger,
  setOpenedByTrigger,
  isCompactMode,
  toggleCompactMode,
  ...otherProps 
}, ref) => {
  // Use provided messages if available, otherwise use default messages
  const [localMessages, setLocalMessages] = useState([
    { id: 1, text: "Hello! How can I help you today?", sender: "bot" },
    // Example messages for design purposes
  ]);
  
  // Use either prop messages or local messages
  const messages = propMessages || localMessages;
  
  // Create refs for scrolling
  const messagesContainerRef = useRef(null);
  const lastMessageRef = useRef(null);
  const loadingIndicatorRef = useRef(null);
  const isAutoScrollEnabled = useRef(true);
  const lastMessagesLength = useRef(messages.length);
  const lastLoadingState = useRef(isLoading);
  const lastQuestionsVisibility = useRef(false);
  
  // Add state to track if car search panel is open
  const [isCarSearchOpen, setIsCarSearchOpen] = useState(false);
  
  // Add these refs at the top of your component, with the other useRef declarations
  const inputRef = useRef(null);
  const sendButtonRef = useRef(null);
  
  // Add state for handling loading state for the PersonalDataForm
  const [formLoading, setFormLoading] = useState(false);
  
  // Add a debounced scroll handler to prevent multiple scroll events from interfering with each other
  const scrollTimeoutRef = useRef(null);
  
  // Add state to track when the user is typing
  const [isTyping, setIsTyping] = useState(false);
  const typingTimeoutRef = useRef(null);
  
  // Add state to track if questions are visible
  const [areQuestionsVisible, setAreQuestionsVisible] = useState(true);
  
  // Add this at the appropriate position in the component (where other hooks are defined)
  const [toolProgress, setToolProgress] = useState(0);
  
  // Add state for car search panel
  const [showCarSearch, setShowCarSearch] = useState(false);
  
  // Add these new state variables
  const [showSearchTip, setShowSearchTip] = useState(true);
  const [userHasClickedAvatar, setUserHasClickedAvatar] = useState(false);
  
  // Add these state variables with the other state variables
  const [showPromotionsSearch, setShowPromotionsSearch] = useState(false);
  const [showServiceSearch, setShowServiceSearch] = useState(false);
  const [showRentalSearch, setShowRentalSearch] = useState(false);
  const [showDefaultChat, setShowDefaultChat] = useState(false);
  
  // Expose scrollToMessage and other methods via ref
  useImperativeHandle(ref, () => ({
    scrollToMessage: (index) => {
      // Implementation for scrolling to a specific message
      console.log("Scroll to message index:", index);
      const messageElements = messagesContainerRef.current?.querySelectorAll('.message-bubble');
      if (messageElements && messageElements[index]) {
        messageElements[index].scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    },
    scrollToBottom: (behavior = 'smooth') => {
      scrollToBottom(behavior);
    },
    focusOnLatestElement: () => {
      focusOnLatestElement();
    }
  }));
  
  // Add a helper function to safely focus and scroll to an element
  const safelyFocusElement = useCallback((element) => {
    if (!element) return false;
    
    try {
      // First scroll to ensure the element is in view
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      
      // Then focus on it, but prevent further scrolling
      setTimeout(() => {
        element.focus({ preventScroll: true });
      }, 50);
      
      return true;
    } catch (error) {
      console.error("Error focusing element:", error);
      return false;
    }
  }, []);
  
  // Function to focus on the latest element (message, typing indicator, or questions)
  const focusOnLatestElement = useCallback(() => {
    // Priority: 1. Loading indicator, 2. Last message
    if (isLoading && loadingIndicatorRef.current) {
      safelyFocusElement(loadingIndicatorRef.current);
      console.log("Focusing on typing indicator");
    } else if (lastMessageRef.current) {
      // On desktop, use a different approach to prevent jumping to older messages
      if (!isMobile) {
        // Just scroll the last message into view without changing focus
        lastMessageRef.current.scrollIntoView({ 
          behavior: 'smooth', 
          block: 'center' 
        });
        console.log("Scrolling last message into view (desktop)");
      } else {
        // On mobile, use the full focus approach
        safelyFocusElement(lastMessageRef.current);
        console.log("Focusing on last message (mobile)");
      }
    } else {
      // If we can't find a specific element, try to find the last message or typing indicator in the DOM
      if (messagesContainerRef.current) {
        const lastMessage = messagesContainerRef.current.querySelector('.message-container:last-child') || 
                           messagesContainerRef.current.querySelector('.loading-message');
        
        if (lastMessage) {
          if (!isMobile) {
            // On desktop, just scroll into view
            lastMessage.scrollIntoView({ 
              behavior: 'smooth', 
              block: 'center' 
            });
            console.log("Scrolling found last message into view (desktop)");
          } else {
            // On mobile, use the full focus approach
            safelyFocusElement(lastMessage);
            console.log("Focusing on found last message element (mobile)");
          }
        } else {
          // If all else fails, just scroll to a reasonable position
          const scrollHeight = messagesContainerRef.current.scrollHeight;
          const height = messagesContainerRef.current.clientHeight;
          const maxScrollTop = scrollHeight - height;
          
          messagesContainerRef.current.scrollTo({
            top: maxScrollTop - (isMobile ? 100 : 50), // Less space on desktop
            behavior: 'smooth'
          });
          console.log("No specific element found, scrolling to a reasonable position");
        }
      }
    }
  }, [isLoading, safelyFocusElement, isMobile]);
  
  // Function to scroll to the bottom of the messages container
  const scrollToBottom = useCallback((behavior = 'smooth') => {
    if (messagesContainerRef.current) {
      const scrollHeight = messagesContainerRef.current.scrollHeight;
      const height = messagesContainerRef.current.clientHeight;
      const maxScrollTop = scrollHeight - height;
      
      // Use requestAnimationFrame to ensure scrolling happens after DOM updates
      requestAnimationFrame(() => {
        messagesContainerRef.current.scrollTo({
          top: maxScrollTop,
          behavior
        });
        
        // After scrolling, focus on the latest element
        setTimeout(() => {
          focusOnLatestElement();
        }, 150); // Increased timeout to ensure DOM has updated
      });
      
      console.log("Scrolling to bottom:", { scrollHeight, height, maxScrollTop });
    }
  }, [focusOnLatestElement]);
  
  // Add a debounced scroll handler to prevent multiple scroll events from interfering with each other
  const debouncedScrollToBottom = useCallback((behavior = 'smooth') => {
    // Clear any existing timeout
    if (scrollTimeoutRef.current) {
      clearTimeout(scrollTimeoutRef.current);
    }
    
    // Set a new timeout
    scrollTimeoutRef.current = setTimeout(() => {
      scrollToBottom(behavior);
    }, 50);
  }, [scrollToBottom]);
  
  // Clean up the timeout when the component unmounts
  useEffect(() => {
    return () => {
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
      }
    };
  }, []);
  
  // Handle scroll events to detect when user manually scrolls
  const handleScroll = useCallback(() => {
    if (messagesContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = messagesContainerRef.current;
      const isNearBottom = scrollHeight - scrollTop - clientHeight < 100;
      
      // Enable auto-scroll only when user scrolls near the bottom
      isAutoScrollEnabled.current = isNearBottom;
      
      if (isNearBottom) {
        console.log("User scrolled near bottom, enabling auto-scroll");
      } else {
        console.log("User scrolled away from bottom, disabling auto-scroll");
      }
    }
  }, []);
  
  // Scroll to bottom when new messages arrive or loading state changes
  useEffect(() => {
    const messagesChanged = messages.length !== lastMessagesLength.current;
    const loadingChanged = isLoading !== lastLoadingState.current;
    const questionsVisibilityChanged = false !== lastQuestionsVisibility.current;
    
    if (messagesChanged || loadingChanged || questionsVisibilityChanged) {
      console.log("Messages, loading state, or questions visibility changed:", { 
        messagesChanged, 
        loadingChanged,
        questionsVisibilityChanged,
        messagesLength: messages.length,
        prevMessagesLength: lastMessagesLength.current,
        isLoading,
        wasLoading: lastLoadingState.current,
        areQuestionsVisible: false,
        wereQuestionsVisible: lastQuestionsVisibility.current
      });
      
      // Update refs for next comparison
      lastMessagesLength.current = messages.length;
      lastLoadingState.current = isLoading;
      lastQuestionsVisibility.current = false;
      
      // Always scroll to bottom when:
      // 1. Loading state changes (typing indicator appears/disappears)
      // 2. A new message arrives and auto-scroll is enabled
      // 3. Questions visibility changes
      // But don't scroll if the user is currently typing
      if ((loadingChanged || 
          (messagesChanged && isAutoScrollEnabled.current) || 
          questionsVisibilityChanged) && 
          !isTyping) {
        
        // Use a short delay to ensure DOM has updated
        setTimeout(() => {
          // For desktop, ensure we're focusing on the last message
          if (!isMobile) {
            // Find the last message element
            const lastMessage = messagesContainerRef.current?.querySelector('.message-container:last-child');
            if (lastMessage) {
              lastMessage.scrollIntoView({ behavior: 'smooth', block: 'center' });
            } else {
              debouncedScrollToBottom();
            }
          } else {
            debouncedScrollToBottom();
          }
        }, 100);
      }
    }
  }, [messages, isLoading, isTyping]);
  
  // Add scroll event listener to detect when user manually scrolls
  useEffect(() => {
    const messagesContainer = messagesContainerRef.current;
    if (messagesContainer) {
      messagesContainer.addEventListener('scroll', handleScroll);
      
      return () => {
        messagesContainer.removeEventListener('scroll', handleScroll);
      };
    }
  }, [handleScroll]);
  
  // Add a specific effect to handle message changes on desktop
  useEffect(() => {
    // Only run this effect on desktop
    if (!isMobile && messages.length > 0) {
      // Get the last message
      const lastMessage = messages[messages.length - 1];
      
      // If the last message is from the bot and not loading, and not typing
      if (lastMessage.sender === 'bot' && !isLoading && !isTyping) {
        // Use a delay to ensure the DOM has updated
        setTimeout(() => {
          // Find the last message element
          const lastMessageElement = messagesContainerRef.current?.querySelector('.message-container:last-child');
          if (lastMessageElement) {
            // Use requestAnimationFrame to ensure smooth scrolling
            requestAnimationFrame(() => {
              lastMessageElement.scrollIntoView({ 
                behavior: 'smooth', 
                block: 'center' 
              });
            });
          } else {
            // Fallback to debounced scroll
            debouncedScrollToBottom();
          }
        }, 200);
      }
    }
  }, [messages, isLoading, isMobile, debouncedScrollToBottom, isTyping]);
  
  // Process predicted questions to ensure they're in the right format
  const processedPredictedQuestions = useMemo(() => {
    console.log("Processing predicted questions in ChatBody:", propPredictedQuestions);
    
    if (!propPredictedQuestions) return [];
    
    // If it's already an array of strings or objects with text property, use it directly
    if (Array.isArray(propPredictedQuestions)) {
      if (propPredictedQuestions.length === 0) return [];
      
      // Check if array contains objects with text property or strings
      if (typeof propPredictedQuestions[0] === 'string') {
        console.log("Predicted questions are already in string array format:", propPredictedQuestions);
        return propPredictedQuestions;
      } else if (typeof propPredictedQuestions[0] === 'object' && propPredictedQuestions[0].text) {
        console.log("Predicted questions are in object array format with text property:", propPredictedQuestions);
        return propPredictedQuestions;
      }
    }
    
    // If it's an object with next_question_X properties
    if (typeof propPredictedQuestions === 'object' && !Array.isArray(propPredictedQuestions)) {
      const extractedQuestions = [];
      
      // Extract questions from next_question_X properties
      if (propPredictedQuestions.next_question_1) 
        extractedQuestions.push(propPredictedQuestions.next_question_1);
      if (propPredictedQuestions.next_question_2) 
        extractedQuestions.push(propPredictedQuestions.next_question_2);
      if (propPredictedQuestions.next_question_3) 
        extractedQuestions.push(propPredictedQuestions.next_question_3);
      
      if (extractedQuestions.length > 0) {
        console.log("Extracted questions from next_question_X format:", extractedQuestions);
        return extractedQuestions;
      }
      
      // If it's a different object format, extract values
      const objQuestions = Object.values(propPredictedQuestions)
        .filter(q => q && (typeof q === 'string' || (typeof q === 'object' && q.text)));
      
      if (objQuestions.length > 0) {
        console.log("Extracted questions from object values:", objQuestions);
        return objQuestions;
      }
    }
    
    console.log("Could not process predicted questions, returning empty array");
    return [];
  }, [propPredictedQuestions]);
  
  // Track when predicted questions change
  const [lastProcessedQuestions, setLastProcessedQuestions] = useState([]);
  
  // Update lastProcessedQuestions when processedPredictedQuestions changes
  useEffect(() => {
    if (processedPredictedQuestions.length > 0) {
      // Convert questions to comparable format
      const getQuestionText = q => typeof q === 'string' ? q : q.text;
      const currentQuestions = processedPredictedQuestions.map(getQuestionText);
      const lastQuestions = lastProcessedQuestions.map(getQuestionText);
      
      // Check if questions have actually changed
      const currentStr = JSON.stringify(currentQuestions.sort());
      const lastStr = JSON.stringify(lastQuestions.sort());
      
      if (currentStr !== lastStr) {
        console.log("Predicted questions changed in ChatBody:", {
          current: processedPredictedQuestions,
          last: lastProcessedQuestions,
          currentStr,
          lastStr
        });
        setLastProcessedQuestions(processedPredictedQuestions);
      }
    }
  }, [processedPredictedQuestions]);
  
  // Add handler for user interactions to exit trigger mode
  const handleUserInteraction = () => {
    if (openedByTrigger && setOpenedByTrigger) {
      setOpenedByTrigger(false);
    }
  };

  // Update the handle send message to clear opened by trigger state
  const handleSendMessage = (text) => {
    // Clear openedByTrigger state when user sends a message
    if (openedByTrigger && setOpenedByTrigger) {
      setOpenedByTrigger(false);
    }

    // Call the original onSendMessage handler
    onSendMessage && onSendMessage(text);
  };

  // Handle question click
  const handleQuestionClick = (question) => {
    const questionText = typeof question === 'string' ? question : question.text;
    
    // Just set the input value without sending
    setInputValue(questionText);
  };

  // Handle questions visibility change
  const handleQuestionsVisibilityChange = useCallback((isVisible) => {
    console.log("Questions visibility changed:", isVisible);
    
    // Update questions visibility state
    setAreQuestionsVisible(isVisible);
    
    // Check if visibility actually changed
    if (isVisible !== lastQuestionsVisibility.current) {
      lastQuestionsVisibility.current = isVisible;
      
      // Force scroll adjustment when questions panel visibility changes
      setTimeout(() => {
        if (isAutoScrollEnabled.current) {
          scrollToBottom();
          
          // If questions are now visible, don't try to focus on messages
          // The ChatFooter component will handle focusing on the questions
          if (!isVisible) {
            // If questions are hidden, focus on the latest message or typing indicator
            focusOnLatestElement();
          }
        }
      }, 300); // Give time for the panel animation to start
    }
  }, [scrollToBottom, focusOnLatestElement]);

  // Add Material Icons font to the document head
  useEffect(() => {
    // Check if the Material Icons font is already loaded
    const linkExists = document.querySelector('link[href*="material-icons"]');
    if (!linkExists) {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = 'https://fonts.googleapis.com/icon?family=Material+Icons';
      document.head.appendChild(link);
    }
  }, []);

  // Use state to track the current hour for greeting
  const [currentHour, setCurrentHour] = useState(new Date().getHours());
  
  // Update the hour every minute to ensure greeting changes at the right time
  useEffect(() => {
    const intervalId = setInterval(() => {
      const newHour = new Date().getHours();
      if (newHour !== currentHour) {
        setCurrentHour(newHour);
      }
    }, 60000); // Check every minute
    
    return () => clearInterval(intervalId);
  }, [currentHour]);

  // Function to get time-based greeting
  const getTimeBasedGreeting = useCallback(() => {
    if (currentHour >= 5 && currentHour < 12) {
      return "Buongiorno, come posso aiutarla?";
    } else if (currentHour >= 12 && currentHour < 18) {
      return "Buon pomeriggio, come posso aiutarla?";
    } else {
      return "Buona sera, come posso aiutarla?";
    }
  }, [currentHour]);

  // Add this useEffect to check if the user has used the search feature before
  useEffect(() => {
    const hasUsedSearch = localStorage.getItem('hasUsedCarSearch') === 'true';
    setUserHasClickedAvatar(hasUsedSearch);
    
    // If they've never used it, show the tip and set a timer to remove it
    if (!hasUsedSearch) {
      setShowSearchTip(true);
      const timer = setTimeout(() => {
        setShowSearchTip(false);
      }, 10000); // Show tip for 10 seconds
      return () => clearTimeout(timer);
    } else {
      setShowSearchTip(false);
    }
  }, []);

  // Function to close all search components
  const closeAllSearchComponents = () => {
    setShowCarSearch(false);
    setIsCarSearchOpen(false);
  };

  // Handle car search close
  const handleCarSearchClose = () => {
    closeAllSearchComponents();
    handleCarSearchStateChange(false);
  };

  // Open car search
  const handleCarSearchOpen = () => {
    // Close any other open search components first
    closeAllSearchComponents();
    
    // Now open the car search
    setShowCarSearch(true);
    handleCarSearchStateChange(true);
  };

  // Handle car search submission
  const handleCarSearchSubmit = useCallback((searchData) => {
    setShowCarSearch(false);
    setIsCarSearchOpen(false);
    
    // Format the search data into a message that triggers the search tool
    const message = `Ciao! Sto cercando un'auto tra quelle disponibili. 
Ho un budget massimo di ${searchData.budget}€.
${getFuelTypePreference(searchData.fuelType)}
${searchData.additionalInfo ? `\nInoltre, ho queste preferenze specifiche: ${searchData.additionalInfo}` : ''}

Avete a disposizione delle auto che potrebbero fare al caso mio? Spiegami perché le suggerisci.`;

    if (onSendMessage) {
      onSendMessage(message);
    }
  }, [onSendMessage]);

  // Helper function to generate a more natural fuel type preference statement
  const getFuelTypePreference = (fuelType) => {
    switch (fuelType) {
      case 'Benzina':
        return "Preferirei un'auto a benzina.";
      case 'Diesel':
        return "Sarei orientato su un'auto diesel.";
      case 'Ibrida':
        return "Mi interessa la tecnologia ibrida per risparmiare sui consumi.";
      case 'Elettrica':
        return "Vorrei passare alla mobilità elettrica.";
      case 'Non ho preferenze':
        return "Non ho preferenze particolari sul tipo di alimentazione, sono aperto a tutte le opzioni.";
      default:
        return "";
    }
  };

  // Memoize the messages to prevent unnecessary re-renders
  const memoizedMessages = useMemo(() => {
    // Filter out empty bot messages when typing indicator is active
    const filteredMessages = (isLoading || isHumanControlled) 
      ? messages.filter(message => {
          // Filter out empty bot/server messages or messages that are still loading
          const isEmptyBotMessage = (
            (message.sender === 'bot' || message.sender === 'server') && 
            (!message.text || message.text.trim() === '')
          );
          const isLoadingMessage = message.loading === true;
          
          // When human operator is online, we only want to show messages with actual content
          if (isHumanControlled) {
            // For human operator, keep user messages and non-empty operator messages
            return message.sender === 'user' || 
                  (message.text && message.text.trim() !== '');
          }
          
          // For AI typing, filter out empty or loading messages
          return !(isEmptyBotMessage || isLoadingMessage);
        })
      : messages;
    
    // Add a welcome message if there are no messages
    const messagesToRender = filteredMessages.length === 0 
      ? [{ 
          id: 'welcome', 
          text: getTimeBasedGreeting(), 
          sender: 'bot',
          type: 'system',
          categories: [] // Empty categories array to remove the buttons
        }] 
      : filteredMessages;
    
    // Debug log for welcome message
    if (messagesToRender.length === 1 && messagesToRender[0].id === 'welcome') {
      console.log('Created welcome message:', messagesToRender[0]);
    }
    
    // Helper function to get formatted timestamp
    const getFormattedTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();
      return `${hours}H${minutes < 10 ? '0' + minutes : minutes}`;
    };
    
    // Map messages to rendered components
    return messagesToRender.map((message, index) => {
      // Check if message has carousel items to apply special class
      const hasCarousel = message.carouselItems && message.carouselItems.length > 0;
      const isLastMessage = index === messagesToRender.length - 1;
      const isBot = message.sender === 'bot' || message.sender === 'server' || message.type === 'system';
      
      // Check if we should show the personal data form
      const shouldShowPersonalDataForm = 
        isLastMessage && 
        !message.loading && 
        !localStorage.getItem("aitomotivelab_personalData_phone") && 
        messagesToRender.length >= 7 || 
        message.tool === "customer_registration" ||
        message.form;
      
      // Create a memoized message component with proper ref for last message
      return (
        <div 
          key={message.id || message._id || `msg-${index}`}
          className={`message-container ${isBot ? 'bot' : 'user'}`}
          ref={isLastMessage ? lastMessageRef : null}
        >
          {isBot ? (
            <>
              <div className="bot-message-row">
                <div 
                  className="bot-avatar"
                  style={{ backgroundImage: `url(https://files.aitomotivelab.com/icona_aitomotivelab.svg)` }}
                >
                </div>
                <MessageBubble 
                  message={{...message, sender: 'bot'}} 
                  isHumanControlled={isHumanControlled}
                  className={hasCarousel ? 'has-carousel' : ''}
                />
              </div>
              {shouldShowPersonalDataForm && (
                <div className="personal-data-form-container">
                  <PersonalDataForm
                    onSubmit={onSendPersonalData}
                    onSendMessage={onSendMessage || handleSendMessage}
                    setIsLoading={setFormLoading}
                    activeTrigger={message.form}
                  />
                </div>
              )}
            </>
          ) : (
            <MessageBubble 
              message={message} 
              isHumanControlled={isHumanControlled}
              className={hasCarousel ? 'has-carousel' : ''}
            />
          )}
        </div>
      );
    });
  }, [messages, isLoading, isHumanControlled, lastMessageRef, getTimeBasedGreeting, onSendPersonalData, onSendMessage, showSearchTip, userHasClickedAvatar]);
  
  // Render quick suggestions
  const renderQuickSuggestions = () => {
    // Only render if we have questions and not loading and not human controlled
    if (
      Array.isArray(processedPredictedQuestions) && 
      processedPredictedQuestions.length > 0 && 
      !isLoading && 
      !isHumanControlled
    ) {
      // Check if the last message has a carousel
      const lastMessage = messages.length > 0 ? messages[messages.length - 1] : null;
      const hasCarousel = lastMessage && lastMessage.carouselItems && lastMessage.carouselItems.length > 0;
      
      return (
        <div className={`quick-suggestions-container ${hasCarousel ? 'after-carousel' : ''}`}>
          <div className="quick-suggestions">
            {processedPredictedQuestions.map((question, index) => {
              const questionText = typeof question === 'string' ? question : question.text;
              return (
                <button
                  key={index}
                  className="suggestion-chip"
                  onClick={() => handleQuestionClick(question)}
                >
                  {questionText}
                </button>
              );
            })}
          </div>
        </div>
      );
    }
    return null;
  };

  // Add event listener for custom sendMessage events from carousels
  useEffect(() => {
    const handleCarouselSendMessage = (event) => {
      if (event.detail && event.detail.message) {
        handleSendMessage(event.detail.message);
      }
    };
    
    document.addEventListener('sendMessage', handleCarouselSendMessage);
    
    return () => {
      document.removeEventListener('sendMessage', handleCarouselSendMessage);
    };
  }, []);

  // Add this effect to handle scrolling when categories, subcategories, or forms appear
  useEffect(() => {
    const messagesContainer = messagesContainerRef.current;
    if (messagesContainer) {
      // Get the last message element
      const lastMessage = messagesContainer.querySelector('.message-bubble:last-child');
      if (lastMessage) {
        // Check if the last message contains categories, subcategories, or form
        const hasCategories = lastMessage.querySelector('.message-categories');
        const hasSubcategories = lastMessage.querySelector('.subcategories-container');
        const hasForm = lastMessage.querySelector('.appointment-form');
        
        if (hasCategories || hasSubcategories || hasForm) {
          // Scroll to the appropriate element
          const targetElement = hasForm || hasSubcategories || hasCategories;
          targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    }
  }, [messages, isLoading]);

  // Add a specific effect for mobile scrolling when questions appear or AI is typing
  useEffect(() => {
    // Only run this effect on mobile devices
    if (isMobile) {
      // Check if we have questions or if AI is typing
      const hasQuestions = Array.isArray(processedPredictedQuestions) && processedPredictedQuestions.length > 0;
      
      // Only scroll if not currently typing in the input
      if ((hasQuestions || isLoading) && !isTyping) {
        // Use a delay to ensure the DOM has updated
        setTimeout(() => {
          // Focus on the last message or typing indicator instead of scrolling too far
          focusOnLatestElement();
        }, 300);
      }
    }
  }, [processedPredictedQuestions, isLoading, isMobile, focusOnLatestElement, isTyping]);

  // Modify the MutationObserver to prevent scrolling during typing
  useEffect(() => {
    const messagesContainer = messagesContainerRef.current;
    if (!messagesContainer) return;
    
    // Create a MutationObserver to detect when new content is added
    const observer = new MutationObserver((mutations) => {
      // Check if any of the mutations added nodes
      const hasAddedNodes = mutations.some(mutation => 
        mutation.addedNodes.length > 0
      );
      
      // If nodes were added and auto-scroll is enabled, and not typing, scroll to bottom
      if (hasAddedNodes && isAutoScrollEnabled.current && !isTyping) {
        // Use requestAnimationFrame to ensure smooth scrolling
        requestAnimationFrame(() => {
          // Find the last message element
          const lastMessage = messagesContainer.querySelector('.message-container:last-child');
          if (lastMessage) {
            lastMessage.scrollIntoView({ 
              behavior: 'smooth', 
              block: 'center' 
            });
          } else {
            debouncedScrollToBottom();
          }
        });
      }
    });
    
    // Start observing the container
    observer.observe(messagesContainer, { 
      childList: true, 
      subtree: true 
    });
    
    // Clean up the observer when the component unmounts
    return () => {
      observer.disconnect();
    };
  }, [debouncedScrollToBottom, isTyping]);

  // Make sure this effect is properly set up to show questions after new messages
  useEffect(() => {
    // Always show questions after new bot messages
    if (
      messages.length > 0 && 
      messages[messages.length - 1].sender === 'bot' && 
      !isLoading
    ) {
      // Set questions to be visible (although they should be visible by default)
      setAreQuestionsVisible(true);
    }
  }, [messages, isLoading]);

  // Add this useEffect after other useEffects in the component
  useEffect(() => {
    let progressInterval = null;
    
    // Reset and start tool progress when loading begins with a tool
    if (isLoading && messages.length > 0 && messages[messages.length - 1]?.tool) {
      setToolProgress(0);
      progressInterval = setInterval(() => {
        setToolProgress(prev => {
          const newProgress = prev + 2; // Complete in about 3 seconds
          return newProgress >= 100 ? 100 : newProgress;
        });
      }, 60);
    } else {
      setToolProgress(0);
    }
    
    return () => {
      if (progressInterval) clearInterval(progressInterval);
    };
  }, [isLoading, messages]);

  // Add callback function to track car search state
  const handleCarSearchStateChange = (isOpen) => {
    setIsCarSearchOpen(isOpen);
    if (isOpen) {
      // When car search opens, ensure the chat container doesn't interfere
      // Only block scrolling if not in compact mode
      if (!isCompactMode && !openedByTrigger) {
        document.body.style.overflow = 'hidden';
      }
      const chatContainer = document.querySelector('.chat-container');
      if (chatContainer) {
        chatContainer.classList.add('car-search-active');
      }
    } else {
      // When car search closes, restore normal state based on compact mode
      if (isCompactMode || openedByTrigger) {
        // Keep scroll enabled in compact mode
        document.body.style.overflow = 'auto';
      } else {
        // In full screen mode, respect the main chat container's overflow setting
        document.body.style.overflow = '';
      }
      const chatContainer = document.querySelector('.chat-container');
      if (chatContainer) {
        chatContainer.classList.remove('car-search-active');
      }
    }
  };

  // Handle CTA click in the footer
  const handleCTAClick = (ctaType) => {
    console.log("CTA clicked:", ctaType);
    
    // Close the chat first - this will make the NewToggleChat component visible
    if (typeof toggleChat === 'function') {
      toggleChat();
      
      // After the chat is closed, we need to trigger the search tool in NewToggleChat
      // The most reliable way is to trigger a custom event that NewToggleChat can listen for
      setTimeout(() => {
        // Create and dispatch a custom event
        const ctaEvent = new CustomEvent('ctaButtonClicked', {
          detail: { ctaType }
        });
        window.dispatchEvent(ctaEvent);
      }, 100);
    }
  };

  return (
    <div 
      className={`chat-container ${isChatWide ? 'wide' : ''} ${isCarSearchOpen ? 'search-panel-open' : ''}`}
      onClick={handleUserInteraction}
    >
      <ChatHeader 
        isMobile={isMobile}
        toggleChat={toggleChat}
        toggleChatWidth={toggleChatWidth}
        isChatWide={isChatWide}
        isLoading={isLoading}
        onSendMessage={onSendMessage}
        isHumanControlled={isHumanControlled}
        humanControlMessage={humanControlMessage}
        isOnline={isConnected}
        toggleSearchPanel={toggleSearchPanel}
        onCarSearchOpen={handleCarSearchOpen}
        onCarSearchClose={handleCarSearchClose}
        openedByTrigger={openedByTrigger}
        isCompactMode={isCompactMode}
        toggleCompactMode={toggleCompactMode}
      />
      
      <div 
        className={`chat-messages ${areQuestionsVisible ? 'questions-visible' : ''}`} 
        ref={messagesContainerRef}
      >
        {memoizedMessages}
        {isLoading && (
          <div 
            className={`message bot-message loading-message ${isHumanControlled ? 'human-operator-typing' : 'ai-typing'}`}
            ref={loadingIndicatorRef}
            tabIndex={0}
            aria-label={isHumanControlled ? "Operator is typing" : "Bot is typing"}
          >
            {isHumanControlled ? (
              <div className="operator-typing">
                <span>Operatore sta scrivendo</span>
                <span className="loading-dot"></span>
                <span className="loading-dot"></span>
                <span className="loading-dot"></span>
              </div>
            ) : (
              <div className="ai-typing-container" data-tool={messages[messages.length - 1]?.tool?.[0] || ''}>
                <div className="ai-typing-animation whatsapp-style">
                  <span className="loading-dot"></span>
                  <span className="loading-dot"></span>
                  <span className="loading-dot"></span>
                </div>
                
                {messages[messages.length - 1]?.tool && (
                  <div className="tool-indicator">
                    <CircularProgress 
                      className="tool-indicator-progress"
                      variant="determinate" 
                      value={toolProgress} 
                      size={32} 
                      thickness={3}
                      style={{ color: 'var(--primary-color, #1976d2)' }}
                    />
                    <div className="tool-indicator-icon">
                      {getToolIcon(messages[messages.length - 1].tool)}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        
        {renderQuickSuggestions()}
      </div>
      
      <ChatFooter 
        onSendMessage={onSendMessage || handleSendMessage}
        onSendFile={onSendFile}
        onSendAudio={onSendAudio}
        isLoading={isLoading}
        isHumanControlActive={isHumanControlled}
        inputValue={inputValue}
        setInputValue={setInputValue}
        predictedQuestions={processedPredictedQuestions}
        onQuestionClick={handleQuestionClick}
        isMobile={isMobile}
        onQuestionsVisibilityChange={handleQuestionsVisibilityChange}
        isCarSearchOpen={isCarSearchOpen}
        areQuestionsVisible={areQuestionsVisible}
        setAreQuestionsVisible={setAreQuestionsVisible}
        isCompactMode={isCompactMode}
        openedByTrigger={openedByTrigger}
        ref={inputRef}
        onCTAClick={handleCTAClick}
      />
      
      {/* Add MobileCarSearch component here */}
      {showCarSearch && (
        <MobileCarSearch 
          onClose={handleCarSearchClose}
          onSubmit={handleCarSearchSubmit}
          toggleChat={toggleChat}
        />
      )}
    </div>
  );
});

export default ChatBody; 