import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import VerifiedIcon from "@mui/icons-material/Verified";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SettingsIcon from "@mui/icons-material/Settings";

export const toolDisplayMapping = {
  'car_stock_search': 'Sto cercando le auto disponibili...',
  'vehicle_details': 'Raccogliendo i dettagli del veicolo...',
  'validate': 'Verificando i dati...',
  'knowledge_search_engine': 'Consultando la base di conoscenza...',
  'booking_': 'Verificando le date disponibili...',
  'book_': 'Controllando l\'agenda...',
  "consultant_intervention": "Contattando un consulente...",
  'customer_registration': 'Preparando la registrazione...',
  'get_service_locations': "Cercando le sedi più vicine..."
};

// Enhanced icon mapping with more distinct icons for each tool
export const toolIconMapping = {
  'car_stock_search': <SearchIcon fontSize="inherit" />,
  'vehicle_details': <DirectionsCarIcon fontSize="inherit" />,
  'validate': <VerifiedIcon fontSize="inherit" />,
  'knowledge_search_engine': <MenuBookIcon fontSize="inherit" />,
  'booking_': <CalendarMonthIcon fontSize="inherit" />,
  'book_': <CalendarMonthIcon fontSize="inherit" />,
  "consultant_intervention": <SupportAgentIcon fontSize="inherit" />,
  'customer_registration': <PersonAddIcon fontSize="inherit" />,
  'get_service_locations': <LocationOnIcon fontSize="inherit" />,
  'default': <SettingsIcon fontSize="inherit" />
};

export const getToolDisplay = (tool) => {
  // First try exact match
  if (toolDisplayMapping[tool]) {
    return toolDisplayMapping[tool];
  }

  // If no exact match, try prefix match
  const matchingKey = Object.keys(toolDisplayMapping).find(key => 
    tool.startsWith(key)
  );

  return matchingKey ? toolDisplayMapping[matchingKey] : "Elaborando la richiesta...";
};

export const getToolIcon = (tool) => {
  let toolName = '';
  
  if (Array.isArray(tool)) {
    toolName = tool[0];
  } else {
    toolName = tool;
  }
  
  // First try exact match
  if (toolIconMapping[toolName]) {
    return toolIconMapping[toolName];
  }

  // If no exact match, try prefix match
  const matchingKey = Object.keys(toolIconMapping).find(key => 
    toolName.startsWith(key)
  );

  return matchingKey ? toolIconMapping[matchingKey] : toolIconMapping['default'];
};