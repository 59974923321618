import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './MobileCarSearch.css';

// Move Header component outside of MobileCarSearch
const Header = ({ currentStep, totalSteps }) => (
  <div className="car-search-header">
    <h1 className="car-search-main-title">
      Ricerca l'auto adatta a te con{' '}
      <img 
        src="https://files.aitomotivelab.com/Mia_Logo_Black.png"
        alt="MIA"
        style={{ height: '18px', verticalAlign: 'middle' }}
      />
    </h1>
    <div className="car-search-progress">
      {[...Array(totalSteps)].map((_, index) => (
        <div 
          key={index} 
          className={`progress-dot ${index === currentStep ? 'active' : index < currentStep ? 'completed' : ''}`}
        />
      ))}
    </div>
  </div>
);

// Close button component
const CloseButton = ({ onClose }) => (
  <button className="car-search-close" onClick={onClose} aria-label="Chiudi ricerca">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <line x1="18" y1="6" x2="6" y2="18"></line>
      <line x1="6" y1="6" x2="18" y2="18"></line>
    </svg>
  </button>
);

// Create a modal container component to render at the root of the DOM
const ModalContainer = ({ children }) => {
  // Add class based on viewport height for better positioning
  const viewportClass = window.innerHeight < 800 ? 'short-viewport' : 'normal-viewport';
  
  return ReactDOM.createPortal(
    <div 
      className={`car-search-overlay ${viewportClass}`}
      aria-modal="true"
      role="dialog"
      aria-labelledby="car-search-title"
    >
      {children}
    </div>,
    document.body
  );
};

// Add these utility functions at the top
const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf('safari') > -1 && ua.indexOf('chrome') === -1;
};

const isIOS = () => {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
};

// Main component
const MobileCarSearch = ({ onClose, onSubmit, usePortal = true, toggleChat }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [budget, setBudget] = useState('');
  const [fuelType, setFuelType] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  const [keyboardOpen, setKeyboardOpen] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const currentInputRef = useRef(null);
  const containerRef = useRef(null);
  const formRef = useRef(null);
  const [isFirstUse, setIsFirstUse] = useState(false);
  
  // Add new state for Safari-specific handling
  const [isSafariBrowser, setIsSafariBrowser] = useState(false);
  const [isIOSDevice, setIsIOSDevice] = useState(false);
  const [keyboardHeight, setKeyboardHeight] = useState(0);
  
  // Update body class and add Safari detection
  useEffect(() => {
    document.body.classList.add('car-search-open');
    
    // Detect Safari and iOS
    setIsSafariBrowser(isSafari());
    setIsIOSDevice(isIOS());
    
    // Set a CSS variable to handle viewport height properly
    const setViewportHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    
    // Initial call
    setViewportHeight();
    
    // Update on resize
    window.addEventListener('resize', setViewportHeight);
    
    return () => {
      document.body.classList.remove('car-search-open');
      window.removeEventListener('resize', setViewportHeight);
    };
  }, []);
  
  // Add specific iOS Safari handling for viewport and keyboard
  useEffect(() => {
    if (isIOSDevice && isSafariBrowser) {
      // Add iOS Safari-specific class
      document.body.classList.add('ios-safari');
      
      // Set viewport meta tag for iOS
      const metaViewport = document.querySelector('meta[name="viewport"]');
      if (metaViewport) {
        metaViewport.setAttribute('content', 
          'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no, viewport-fit=cover');
      }
      
      // Add better keyboard detection for iOS Safari
      const visualViewport = window.visualViewport;
      if (visualViewport) {
        const handleViewportChange = () => {
          // Detect keyboard by checking if viewport height is significantly less than window height
          const windowHeight = window.innerHeight;
          const viewportHeight = visualViewport.height;
          const isKeyboardVisible = viewportHeight < windowHeight * 0.75;
          
          // Update keyboard state
          setKeyboardOpen(isKeyboardVisible);
          
          // Calculate approximate keyboard height
          if (isKeyboardVisible) {
            const estimatedKeyboardHeight = windowHeight - viewportHeight;
            setKeyboardHeight(estimatedKeyboardHeight);
            document.documentElement.style.setProperty('--keyboard-height', `${estimatedKeyboardHeight}px`);
          } else {
            setKeyboardHeight(0);
            document.documentElement.style.setProperty('--keyboard-height', '0px');
          }
          
          // When keyboard opens, ensure input is visible
          if (isKeyboardVisible && currentInputRef.current) {
            setTimeout(() => {
              // Scroll to the input
              currentInputRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
              });
            }, 300);
          }
        };
        
        visualViewport.addEventListener('resize', handleViewportChange);
        visualViewport.addEventListener('scroll', handleViewportChange);
        
        return () => {
          document.body.classList.remove('ios-safari');
          visualViewport.removeEventListener('resize', handleViewportChange);
          visualViewport.removeEventListener('scroll', handleViewportChange);
          
          // Reset viewport meta
          if (metaViewport) {
            metaViewport.setAttribute('content', 
              'width=device-width, initial-scale=1, viewport-fit=cover');
          }
        };
      }
    }
  }, [isIOSDevice, isSafariBrowser]);
  
  // Enhance handleInputFocus for Safari support
  const handleInputFocus = () => {
    setKeyboardOpen(true);
    
    if (isIOSDevice && isSafariBrowser) {
      // For iOS Safari, ensure the input is visible and keep buttons in view
      setTimeout(() => {
        // Add special class for iOS
        containerRef.current?.classList.add('ios-keyboard-open');
        
        // Get current button actions
        const actionsElement = containerRef.current?.querySelector('.car-search-actions');
        if (actionsElement) {
          // Use sticky positioning for iOS Safari when keyboard is open
          actionsElement.classList.add('keyboard-repositioned');
        }
        
        // Scroll input into view after keyboard appears
        if (currentInputRef.current) {
          currentInputRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        }
      }, 150);
    } else if (isMobile) {
      // Original implementation for other browsers
      // Mark keyboard as open immediately to apply styles
      
      // For Safari, give time for the keyboard to appear before positioning
      setTimeout(() => {
        const actionsElement = containerRef.current?.querySelector('.car-search-actions');
        
        if (actionsElement) {
          // Use CSS class-based approach instead of inline styles
          actionsElement.classList.add('keyboard-repositioned');
          
          // Ensure the input is visible
          if (currentInputRef.current) {
            currentInputRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            });
            
            // Add extra scroll for Safari which may need it
            if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
              window.scrollTo(0, window.scrollY + 100);
            }
          }
        }
      }, 300); // Give time for keyboard to appear
    }
  };
  
  // Improve handleInputBlur for iOS Safari
  const handleInputBlur = () => {
    if (isIOSDevice && isSafariBrowser) {
      // For iOS, use a longer delay
      setTimeout(() => {
        setKeyboardOpen(false);
        
        // Remove iOS-specific classes
        containerRef.current?.classList.remove('ios-keyboard-open');
        
        const actionsElement = containerRef.current?.querySelector('.car-search-actions');
        if (actionsElement) {
          actionsElement.classList.remove('keyboard-repositioned');
        }
        
        // Reset keyboard height
        setKeyboardHeight(0);
        document.documentElement.style.setProperty('--keyboard-height', '0px');
      }, 300);
    } else if (isMobile) {
      // Original implementation for other browsers
      // Short delay to ensure we don't reset too early
      setTimeout(() => {
        setKeyboardOpen(false);
        
        const actionsElement = containerRef.current?.querySelector('.car-search-actions');
        if (actionsElement && actionsElement.classList.contains('keyboard-repositioned')) {
          // Reset to fixed bottom position
          actionsElement.style.position = 'fixed';
          actionsElement.style.bottom = '0';
          actionsElement.style.top = 'auto';
          actionsElement.classList.remove('keyboard-repositioned');
        }
      }, 150);
    }
  };
  
  // Enhance the Enter key handling for better keyboard experience
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      
      if (isCurrentStepValid()) {
        // On iOS Safari with keyboard open, ensure smooth transition
        if (isIOSDevice && isSafariBrowser && keyboardOpen) {
          currentInputRef.current?.blur();
          // Use a longer delay for iOS Safari
          setTimeout(() => handleNext(), 300);
        } 
        // On mobile with keyboard open, blur the input first to hide keyboard
        else if (isMobile && keyboardOpen && currentInputRef.current) {
          currentInputRef.current.blur();
          // Allow keyboard to close before proceeding
          setTimeout(() => handleNext(), 100);
        } else {
          handleNext();
        }
      }
    } else if (e.key === 'Escape') {
      // Allow escaping the modal with ESC key
      onClose();
    }
  };
  
  // Format budget with thousands separator as user types
  const handleBudgetInput = (e) => {
    const value = e.target.value;
    
    // Remove all non-digit characters
    const numericValue = value.replace(/\D/g, '');
    
    if (numericValue === '') {
      setBudget('');
    } else {
      // Format with dot as thousands separator (Italian format)
      setBudget(Number(numericValue).toLocaleString('it-IT').replace(/\./g, '.'));
    }
  };

  // Get numeric value from formatted budget
  const getBudgetNumericValue = () => {
    return budget ? parseInt(budget.replace(/\D/g, '')) : 0;
  };

  const handleNext = () => {
    // Validate current step before proceeding
    if (!isCurrentStepValid()) {
      return;
    }
    
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      handleSubmit();
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    } else {
      onClose();
    }
  };

  const handleSubmit = () => {
    // Save numeric budget value
    onSubmit({
      budget: getBudgetNumericValue(),
      fuelType,
      additionalInfo
    });
    
    // Record that the user has used the car search
    localStorage.setItem('hasUsedCarSearch', 'true');
    
    // Close the modal after submission
    onClose();
  };

  const isCurrentStepValid = () => {
    switch (currentStep) {
      case 0:
        return budget.trim() !== '';
      case 1:
        return fuelType.trim() !== '';
      case 2:
        return true;
      default:
        return false;
    }
  };

  // Auto-advance when fuel type is selected
  const handleFuelTypeChange = (value) => {
    setFuelType(value);
    // Auto-advance after a short delay for better UX
    setTimeout(() => {
      handleNext();
    }, 300);
  };
  
  // Handle textarea special case for Ctrl+Enter
  const handleTextareaKeyDown = (e) => {
    // If Ctrl+Enter is pressed, submit the form
    if (e.key === 'Enter' && e.ctrlKey) {
      e.preventDefault();
      handleNext();
    } else if (e.key === 'Enter') {
      // Allow normal Enter keypress for newlines
      e.stopPropagation();
    }
  };

  // Add a new effect to detect keyboard state changes
  useEffect(() => {
    if (isMobile) {
      const handleKeyboardVisibilityChange = () => {
        const isKeyboardVisible = window.visualViewport.height < window.innerHeight * 0.75;
        
        if (!isKeyboardVisible && keyboardOpen) {
          // Keyboard just closed
          const actionsElement = containerRef.current?.querySelector('.car-search-actions');
          if (actionsElement && actionsElement.classList.contains('keyboard-repositioned')) {
            // Reset to fixed bottom position
            actionsElement.style.position = 'fixed';
            actionsElement.style.bottom = '0';
            actionsElement.style.top = 'auto';
            actionsElement.classList.remove('keyboard-repositioned');
            setKeyboardOpen(false);
          }
        }
      };
      
      // Use visualViewport API to detect keyboard visibility changes
      const visualViewport = window.visualViewport;
      if (visualViewport) {
        visualViewport.addEventListener('resize', handleKeyboardVisibilityChange);
        return () => {
          visualViewport.removeEventListener('resize', handleKeyboardVisibilityChange);
        };
      }
    }
  }, [isMobile, keyboardOpen]);

  // Define steps
  const steps = [
    {
      title: "Qual è il tuo budget?",
      description: "Inserisci il budget massimo che vorresti spendere per la tua nuova auto.",
      input: (
        <div className="search-input-container">
          <input
            type="text"
            value={budget}
            onChange={handleBudgetInput}
            onKeyDown={handleKeyDown}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            placeholder="Es. 20000"
            className="search-input"
            ref={currentInputRef}
            inputMode="numeric"
            aria-label="Budget massimo"
            // Add accessibility attributes
            autoComplete="off"
            aria-required="true"
          />
          <span className="search-input-suffix">€</span>
          <div className="input-hint">Premi Enter per continuare</div>
        </div>
      )
    },
    {
      title: "Che alimentazione preferisci?",
      description: "Scegli il tipo di alimentazione che preferisci per la tua nuova auto.",
      input: (
        <div className="search-input-container">
          <div className="fuel-type-grid" role="radiogroup" aria-label="Tipo di alimentazione">
            <label 
              className={`fuel-type-option ${fuelType === 'Benzina' ? 'selected' : ''}`}
              onClick={() => handleFuelTypeChange('Benzina')}
            >
              <input
                type="radio"
                name="fuelType"
                value="Benzina"
                checked={fuelType === 'Benzina'}
                onChange={() => {}}
                onFocus={handleInputFocus}
                ref={fuelType === '' ? currentInputRef : null}
              />
              <div className="fuel-type-content">
                <span className="fuel-type-icon">⛽️</span>
                <span className="fuel-type-label">Benzina</span>
              </div>
            </label>
            <label 
              className={`fuel-type-option ${fuelType === 'Diesel' ? 'selected' : ''}`}
              onClick={() => handleFuelTypeChange('Diesel')}
            >
              <input
                type="radio"
                name="fuelType"
                value="Diesel"
                checked={fuelType === 'Diesel'}
                onChange={() => {}}
                onFocus={handleInputFocus}
              />
              <div className="fuel-type-content">
                <span className="fuel-type-icon">🛢️</span>
                <span className="fuel-type-label">Diesel</span>
              </div>
            </label>
            <label 
              className={`fuel-type-option ${fuelType === 'Ibrida' ? 'selected' : ''}`}
              onClick={() => handleFuelTypeChange('Ibrida')}
            >
              <input
                type="radio"
                name="fuelType"
                value="Ibrida"
                checked={fuelType === 'Ibrida'}
                onChange={() => {}}
                onFocus={handleInputFocus}
              />
              <div className="fuel-type-content">
                <span className="fuel-type-icon">🔋</span>
                <span className="fuel-type-label">Ibrida</span>
              </div>
            </label>
            <label 
              className={`fuel-type-option ${fuelType === 'Elettrica' ? 'selected' : ''}`}
              onClick={() => handleFuelTypeChange('Elettrica')}
            >
              <input
                type="radio"
                name="fuelType"
                value="Elettrica"
                checked={fuelType === 'Elettrica'}
                onChange={() => {}}
                onFocus={handleInputFocus}
              />
              <div className="fuel-type-content">
                <span className="fuel-type-icon">⚡️</span>
                <span className="fuel-type-label">Elettrica</span>
              </div>
            </label>
            <label 
              className={`fuel-type-option ${fuelType === 'Non ho preferenze' ? 'selected' : ''}`}
              onClick={() => handleFuelTypeChange('Non ho preferenze')}
            >
              <input
                type="radio"
                name="fuelType"
                value="Non ho preferenze"
                checked={fuelType === 'Non ho preferenze'}
                onChange={() => {}}
                onFocus={handleInputFocus}
              />
              <div className="fuel-type-content">
                <span className="fuel-type-icon">🚗</span>
                <span className="fuel-type-label">Non ho preferenze</span>
              </div>
            </label>
          </div>
        </div>
      )
    },
    {
      title: "Altre informazioni (opzionale)",
      description: "Aggiungi qualsiasi altra informazione che ritieni utile per la tua ricerca.",
      input: (
        <div className="textarea-container">
          <textarea
            value={additionalInfo}
            onChange={(e) => setAdditionalInfo(e.target.value)}
            onKeyDown={handleTextareaKeyDown}
            onFocus={handleInputFocus}
            placeholder="Es. Cerco un'auto con cambio automatico, 5 porte, ecc."
            className="search-textarea"
            maxLength={500}
            ref={currentInputRef}
            aria-label="Informazioni aggiuntive"
          />
          <div className="textarea-hint">Premi Ctrl+Enter per inviare</div>
        </div>
      )
    }
  ];

  const currentStepData = steps[currentStep];

  // Welcome message for first-time users
  const WelcomeMessage = () => isFirstUse && (
    <div className="car-search-welcome">
      <span className="welcome-icon">🚗</span>
      <h2>Benvenuto nella ricerca auto!</h2>
      <p>Rispondi a poche domande per trovare l'auto perfetta per te</p>
    </div>
  );

  // Stop propagation of clicks to prevent closing the modal
  const handleContainerClick = (e) => {
    e.stopPropagation();
  };

  // Update the handleOpenDirectChat function to use the toggleChat prop
  const handleOpenDirectChat = (e) => {
    e.preventDefault();
    // First close the car search modal
    onClose();
    
    // Give a small delay to ensure the car search closes properly before opening the chat
    setTimeout(() => {
      // Use the toggleChat function passed as prop
      if (typeof toggleChat === 'function') {
        toggleChat();
      }
    }, 100);
  };

  // Prepare content to be rendered either directly or in a portal
  const content = (
    <div 
      className={`car-search-container ${keyboardOpen ? 'keyboard-open' : ''} ${isTransitioning ? 'transitioning' : ''} ${isMobile ? 'mobile-fixed-position' : ''} ${isSafariBrowser ? 'safari-browser' : ''} ${isIOSDevice ? 'ios-device' : ''}`}
      ref={containerRef}
      onClick={handleContainerClick}
      data-step={currentStep}
      style={keyboardOpen && isIOSDevice ? { paddingBottom: `${keyboardHeight}px` } : {}}
    >
      <CloseButton onClose={onClose} />
      {isFirstUse && <WelcomeMessage />}
      <Header currentStep={currentStep} totalSteps={steps.length} />
      
      <form 
        className="car-search-form" 
        onSubmit={(e) => { 
          e.preventDefault(); 
          if ((isMobile || isIOSDevice) && keyboardOpen && currentInputRef.current) {
            currentInputRef.current.blur();
            setTimeout(() => handleNext(), isIOSDevice ? 300 : 100);
          } else {
            handleNext();
          }
        }}
        ref={formRef}
      >
        <div className="car-search-content">
          <h2 className="car-search-title" id="car-search-title">{currentStepData.title}</h2>
          <p className="car-search-description">{currentStepData.description}</p>
          <div className="car-search-input-wrapper">
            {currentStepData.input}
            
            <div className={`car-search-actions ${isMobile || isIOSDevice ? 'mobile-actions' : 'desktop-actions'}`}>
              <button type="button" className="car-search-back" onClick={currentStep === 0 ? onClose : handleBack}>
                <span>{currentStep === 0 ? "Annulla" : "Indietro"}</span>
              </button>
              {currentStep !== 1 && (
                <button
                  type="button"
                  className="car-search-next"
                  disabled={!isCurrentStepValid()}
                  onClick={handleNext}
                >
                  {currentStep < steps.length - 1 ? "Avanti" : "Trova auto"}
                </button>
              )}
            </div>
            
            {/* Add direct chat option only on the first step */}
            {currentStep === 0 && (
              <div className="car-search-direct-chat">
                <button type="button" onClick={handleOpenDirectChat} className="direct-chat-button">
                  <span className="direct-chat-button-title">Hai bisogno di aiuto?</span>
                  <span className="direct-chat-button-subtitle">
                    Chiedi informazioni, prenota una visita o scopri i nostri servizi e promozioni
                  </span>
                </button>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );

  // Render either in a portal or directly
  return usePortal ? (
    <ModalContainer>
      {content}
    </ModalContainer>
  ) : (
    <div 
      className={`car-search-overlay ${isSafariBrowser ? 'safari-browser' : ''} ${isIOSDevice ? 'ios-device' : ''}`} 
      onClick={onClose}
      aria-modal="true"
      role="dialog"
      aria-labelledby="car-search-title"
    >
      {content}
    </div>
  );
};

export default MobileCarSearch; 